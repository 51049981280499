import React, { useEffect, useState } from "react";
import SlidingTabBar from "../../../components/core/SlidingTabBar";
import { Tab } from "../../../interface/Tabs";
import ArtRoom from "../../portfolio/art-room/ArtRoom";
import ProfileCover from "../../../assets/images/profile_cover.png";
import Dropdown from "../../../components/core/form-components/Dropdown";
import { IoEllipsisVertical } from "react-icons/io5";
import Paragraph from "../../../components/core/typography/Paragraph";
import { ImLocation } from "react-icons/im";
import SwitchToggle from "../../../components/core/form-components/SwitchToggle";
import { FaEnvelope } from "react-icons/fa";
import Event from "../event/Event";
import Repost from "../repost/Repost";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import {
  blockUser,
  followUsers,
  getFollowers,
  getUsersDetails,
  reportUser,
  unfollowUsers,
} from "../../../services/website/peopleService";
import { UserProfileInterface } from "../../../interface/UserProfile";
import ImageWithDefault from "../../../components/web-component/DefaultImage";
import ProfileFeed from "../my-profile/feed/ProfileFeed";
import { tokenSelector, userSelector } from "../../../redux/slices/userSlice";
import { pathRoute } from "../../../routes/pathRoute";
import ProfileArtRoom from "../my-profile/artroom/ProfileArtRoom";
import Modal from "../../../components/core/Modal";
import ReportProjectContent from "../../../components/core/ReportProjectContent";
import Button from "../../../components/core/form-components/Button";
import { HiUserAdd } from "react-icons/hi";
import IconReport from "../../../assets/images/icon-info.svg";
import { MdBlock } from "react-icons/md";

function UserProfile() {
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState<
    UserProfileInterface | undefined
  >(undefined);
  const token = useSelector(tokenSelector);
  const [followersList, setFollowersList] = useState([]);
  const [folloByMe, setFollowByMe] = useState(false);
  const navigate = useNavigate();
  const webProfileData = useSelector(userSelector);
  const [reportPopup, setReportPopup] = useState(false);
  const [reportId, setReportId] = useState<number | null>(null);

  const profileDropDownItems = [
    { Link: "", LinkName: "Delete", handleAction: true },
  ];

  const userDropDownItems = [
    {
      icon: (
        <img src={IconReport} alt="icon-user-delete" width={12} height={12} />
      ),
      Link: "",
      LinkName: "Report",
      handleAction: true,
    },
    {
      icon: <MdBlock />,
      Link: "",
      LinkName: "Block",
      handleAction: true,
    },
  ];

  const tabs: Tab[] = [
    { id: "art_room", name: "Art Room", content: <ProfileArtRoom /> },
    { id: "feed", name: "Feed", content: <ProfileFeed /> },
    { id: "event", name: "Event", content: <Event /> },
    { id: "repost", name: "Repost", content: <Repost /> },
  ];
  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };

  const fetchAllData = async (id: any) => {
    dispatch(showLoader());
    try {
      let queryString = `?type=0&filter=0&page=1&limit=10`;

      const [userProfileResponse, followersResponse] = await Promise.all([
        getUsersDetails(id),
        getFollowers(id, queryString),
      ]);
      // Handle user [profile] data
      if (userProfileResponse?.data?.code === 200) {
        setUserDetails(userProfileResponse?.data);
        setFollowByMe(userProfileResponse?.data?.isFollowedByMe);
      }

      // Handle followers data
      if (followersResponse?.data?.code === 200) {
        setFollowersList(followersResponse?.data?.followers);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (id) {
      fetchAllData(id);
    }
  }, [id]);

  const handleFollwedByMe = async (values: any) => {
    dispatch(showLoader());
    try {
      if (token) {
        if (values) {
          const response = await followUsers(id, {});
          if (response?.data?.code === 200) {
            setFollowByMe(values);
          }
        } else {
          const response = await unfollowUsers(id);
          console.log("fsfsfsfsdfs", response);
          if (response?.data?.code === 200) {
            setFollowByMe(values);
          }
        }
      } else {
        navigate(pathRoute.auth.login);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleReportId = (id: number) => {
    setReportId(id);
  };

  const handleBlock = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await blockUser(item?.user?.id);
      if (response?.data?.code === 201) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const closeReportPopup = () => {
    setReportPopup(false);
  };

  const handleReportApi = async () => {
    dispatch(showLoader());
    try {
      let data = {
        flagId: reportId,
      };
      const response = await reportUser(data, userDetails?.user?.id);
      if (response?.data?.code === 201) {
        setReportPopup(false);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleActionsInweb = (type: string, item: any) => {
    switch (type) {
      case "Report":
        setReportPopup(!reportPopup);
        break;
      case "Block":
        handleBlock(item);
        break;

      default:
        console.log("Unknown action type:", type);
        break;
    }
  };

  console.log("userDetails", userDetails);
  return (
    <>
      <div className="py-6 sm:pl-4 pl-3 xl:pr-10 lg:pr-4 pr-3">
        <div className="grid grid-cols-12 xl:gap-[48px] gap-6">
          <div className="xl:col-span-5 lg:col-span-10 col-span-12">
            <div className="border border-medium-grey rounded-2xl overflow-hidden">
              <div className="relative">
                {userDetails?.user?.coverImage ? (
                  <img
                    src={userDetails?.user?.coverImage}
                    alt="profile-cover"
                    width={438}
                    height={115}
                    className="w-full h-[115px] object-cover"
                  />
                ) : (
                  <img
                    src={ProfileCover}
                    alt="profile-cover"
                    width={438}
                    height={115}
                    className="w-full h-[115px] object-cover"
                  />
                )}

                <div className="flex justify-between items-center sm:px-4 px-3 sm:-mt-[48px] -mt-[36px] mb-3">
                  {userDetails?.user?.avatar && (
                    <ImageWithDefault
                      src={userDetails?.user?.avatar}
                      alt="profile-picture"
                      width={100}
                      height={100}
                      className="rounded-full object-cover sm:w-[100px] sm:h-[100px] w-[80px] h-[80px]"
                    />
                  )}

                  <Dropdown
                    className="mt-11"
                    onClick={() => toggleDropdown("userProfile")}
                    dropdownItems={
                      userDetails?.user?.id == webProfileData?.id
                        ? profileDropDownItems
                        : userDropDownItems
                    }
                    dropdownWidth="w-[174px]"
                    handleActionsInweb={
                      handleActionsInweb
                        ? (e: any) => handleActionsInweb(e, userDetails)
                        : undefined
                    }
                  >
                    <IoEllipsisVertical color="#7F7F80" size={20} />
                  </Dropdown>
                </div>
                <div className="flex items-center sm:flex-nowrap flex-wrap gap-2 justify-between sm:px-4 px-3 pb-3">
                  <div className="">
                    <Paragraph
                      text18
                      className="font-medium mb-[2px] !leading-4"
                    >
                      @{userDetails?.user?.nickname}
                    </Paragraph>
                    <Paragraph
                      text16
                      className="!text-[#4D4B4B] !font-normal !leading-[14px] flex items-center gap-1"
                    >
                      <ImLocation />
                      {userDetails?.user?.country?.name}
                    </Paragraph>
                  </div>
                  <div className="inline-flex items-center gap-3">
                    <SwitchToggle
                      withInnerText
                      onChange={(value) => handleFollwedByMe(value)}
                      isChecked={folloByMe}
                    />

                    <a
                      href={`mailto:${userDetails?.user?.mail}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="bg-[#F3F3F3] px-3 py-1.5 rounded-[50px] inline-flex items-center gap-1 text-sm leading-4"
                    >
                      <FaEnvelope />
                      Email
                    </a>
                  </div>
                </div>
                <div className="border-b border-medium-grey sm:px-4 px-3 pb-4">
                  <div className="flex items-center gap-2 mb-3">
                    <Paragraph text16 className="leading-[15px] !font-normal">
                      {userDetails?.followers} Followers
                    </Paragraph>
                    <span className="w-[3px] h-[3px] rounded-full bg-site-grey"></span>
                    <Paragraph text16 className="leading-[15px] !font-normal">
                      13 Post
                    </Paragraph>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="flex">
                      {followersList
                        ?.slice(0, 3)
                        .map((item: any, index: number) => (
                          <ImageWithDefault
                            key={index}
                            src={item?.avatar}
                            alt="follow-profile"
                            width={30}
                            height={30}
                            className="w-[30px] h-[30px] rounded-full"
                          />
                        ))}
                    </div>
                    <Paragraph text14 className="leading-3 !font-normal">
                      <span className="text-dark-grey">Followed by </span>
                      {followersList
                        ?.slice(0, 3)
                        .map((item: any) => item?.nickname)
                        .join(", ")}
                    </Paragraph>
                  </div>
                </div>
                <div className="py-4 sm:px-4 px-3">
                  {userDetails?.user?.interestsWeb &&
                    userDetails?.user?.interestsWeb?.length > 0 && (
                      <>
                        <Paragraph text16 className="!leading-4 mb-3">
                          Interest
                        </Paragraph>
                        <div className="flex items-center gap-2 flex-wrap mb-4">
                          {userDetails?.user?.interestsWeb?.map((item: any) => {
                            return (
                              <span className="border border-medium-grey rounded-2xl text-sm leading-4 font-medium px-3 py-1">
                                {item?.interest?.name}
                              </span>
                            );
                          })}
                        </div>
                      </>
                    )}

                  {userDetails?.user?.description && (
                    <>
                      <Paragraph text16 className="!leading-4 mb-2.5">
                        About
                      </Paragraph>
                      <Paragraph
                        text14
                        className="leading-[18px] !text-[#4D4B4B] !font-normal"
                      >
                        {userDetails?.user?.description}
                      </Paragraph>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="xl:col-span-7 lg:col-span-10 col-span-12">
            <div className="border border-medium-grey rounded-2xl overflow-hidden pb-30">
              <SlidingTabBar tabs={tabs} borderedTab />
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={reportPopup}
        header
        onClose={closeReportPopup}
        width="sm:w-[448px] w-[300px]"
        modalPadding="p-6"
      >
        <ReportProjectContent onSelectionChange={handleReportId} />
        <div className="flex items-center justify-center md:gap-4 gap-3">
          <Button
            borderButton
            className="w-full"
            onClick={() => setReportPopup(false)}
          >
            Cancel
          </Button>
          <Button primary onClick={handleReportApi} className="w-full">
            Send
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default UserProfile;
