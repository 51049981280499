import React, { useEffect, useState } from "react";
import { IoMdPlay } from "react-icons/io";
import { Link } from "react-router-dom";
import Paragraph from "../../../components/core/typography/Paragraph";
import { GoClockFill } from "react-icons/go";
import { pathRoute } from "../../../routes/pathRoute";
import { useDispatch } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { getMasterClassList } from "../../../services/website/masterClassService";
import { MasterClassInterface } from "../../../interface/MasterClass";
import { IoLockClosed } from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../../../assets/images/empty_repost.svg";
import SearchInput from "../../../components/core/form-components/SearchInput";

function MasterClass() {
  const [masterClassList, setMasterClassList] = useState<
    MasterClassInterface[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Track total available items
  const [searchItem, setSearchItem] = useState("");

  const dispatch = useDispatch();

  const fecthMasterClassList = async (page: number) => {
    let queryString = `?page=${page}&limit=10&search=${searchItem}&orderBy=id&order=desc`;
    dispatch(showLoader());

    try {
      const response = await getMasterClassList(queryString);
      const count = response?.data?.total || 0;
      if (response?.data?.success) {
        if (searchItem) {
          setMasterClassList(response.data.masterClass_list);
        } else {
          setMasterClassList((prevData) => [
            ...prevData,
            ...response.data.masterClass_list,
          ]);
        }

        setTotalItems(count); // Update the total number of items
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fecthMasterClassList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      // Reset the list and fetch the first page when search changes
      setMasterClassList([]); // Clear existing data
      setCurrentPage(1); // Reset to the first page
      fecthMasterClassList(1); // Fetch the first page
    }, 1000);

    return () => {
      clearTimeout(debounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItem]);

  const formatDuration = (duration: any) => {
    const durationInSeconds = duration;

    const minutes = Math.floor(durationInSeconds / 60);
    const remainingSeconds = durationInSeconds % 60;

    return durationInSeconds > 60
      ? `${minutes} min ${remainingSeconds.toFixed(0)} sec`
      : durationInSeconds + "sec";
  };
  return (
    <>
      {/* <div className="flex items-center gap-3 text-site-black ">
        <SearchInput
          setSearchItem={setSearchItem} // React.Dispatch<React.SetStateAction<string>>
          searchItem={searchItem} // string
          placeholder="Search" // string
          className="sm:w-auto w-full" // string
          iconRight={true} // boolean
          sm={true} // boolean
        />
      </div> */}
      <div
        id="scrollableDiv"
        style={{ overflowY: "auto", maxHeight: "80vh", height: "100%" }}
      >
        {masterClassList?.length > 0 ? (
          <InfiniteScroll
            dataLength={masterClassList?.length ?? 0}
            next={() => setCurrentPage((prevPage) => prevPage + 1)}
            hasMore={masterClassList?.length < totalItems}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            <div className="grid xxl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-3 grid-cols-2 md:gap-[18px] gap-4 xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3">
              {masterClassList?.map(
                (item: MasterClassInterface, index: number) => {
                  return (
                    <div className="col-span-1" key={index}>
                      <Link
                        to={`${pathRoute.website.masterClassDetail}/${item?.id}`}
                        title="Comprendre ses besoins Comprendre ses besoins"
                      >
                        <div className="overflow-hidden rounded-xl relative before:absolute before:w-full before:h-full before:bg-site-black/30 mb-4">
                          <img
                            src={item?.thumbnail}
                            alt={`image-${item.id}`}
                            width={263}
                            height={394}
                            className="aspect-[2/3]"
                          />

                          <span className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                            <IoLockClosed color="#fff" size={22} />
                          </span>
                        </div>
                        <Paragraph
                          text20
                          className="!font-medium line-clamp-1 mb-3"
                        >
                          {item?.title}
                        </Paragraph>
                        <span className="flex items-center gap-1 text-base leading-[15px] text-dark-grey">
                          <GoClockFill color="#4D4B4B" size={18} />
                          Class: {formatDuration(item?.videoDuration)}
                        </span>
                      </Link>
                    </div>
                  );
                }
              )}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="break-inside-avoid text-center">
            <img
              src={NoDataFound}
              alt="no-data-found"
              className="mb-6 inline-block "
              width={"250px"}
              height={"142px"}
            />
            <Paragraph text18 className="!font-medium">
              No result found
            </Paragraph>
          </div>
        )}
      </div>
    </>
  );
}

export default MasterClass;
