import { API } from "../../apiEndPoints/website/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../axios/axiosMiddleware";

export const getPeopleList = (queryString: string) => {
  return axiosGet(`${API?.OTHER?.PEOPLE}/${queryString}`);
};

export const getUsersDetails = (id: any) => {
  return axiosGet(`${API?.OTHER?.USERS_PROFILE}/${id}`);
};

export const followUsers = (id: any, data: any) => {
  return axiosPost(`${API?.OTHER?.FOLLOW_USERS}/${id}`, data);
};

export const unfollowUsers = (id: any) => {
  return axiosPost(`${API?.OTHER?.UNFOLLOW_USERS}/${id}`, {});
};

export const getFollowers = (id: any, queryString: any) => {
  return axiosGet(
    `${API?.OTHER?.GET_ARTROOM_INFO}/${id}/publications/${queryString}`
  );
};

export const blockUser = (id: any) => {
  return axiosPost(`${API?.OTHER?.GET_ARTROOM_INFO}/${id}/block`, {});
};

export const reportUser = (data: any, id: any) => {
  return axiosPost(`${API?.OTHER?.REPORT_USER}/${id}`, data);
};

export const hideUser = (id: any) => {
  return axiosPost(`${API?.OTHER?.FEED_RESPONSES}/${id}/hidden`, {});
};
