import { Profile } from "../pages/portfolio";
import ArtRoomDetail from "../pages/portfolio/art-room-detail/ArtRoomDetail";
import Checkout from "../pages/portfolio/checkout/Checkout";
import FeedCommentDetail from "../pages/portfolio/feed-comment/FeedComment";
import ForgotPassword from "../pages/website/authentication/ForgotPassword";
import Login from "../pages/website/authentication/Login";
import OTP from "../pages/website/authentication/OTP";
import ProfileSetup from "../pages/website/authentication/profile-setup/ProfileSetup";
import Register from "../pages/website/authentication/Register";
import ResetPassword from "../pages/website/authentication/ResetPassword";
import CreatePost from "../pages/website/createPost/CreatePost";
import MasterClass from "../pages/website/masterclass/MasterClass";
import MasterClassDetail from "../pages/website/masterclass/MasterClassDetail";
import MasterClassPlans from "../pages/website/masterclass/MasterClassPlans";
import MyAccount from "../pages/website/my-account/MyAccount";
import MyMasterclass from "../pages/website/my-account/orders/MyMasterclass";
import MyPurchase from "../pages/website/my-account/orders/MyPurchase";
import MySales from "../pages/website/my-account/orders/MySales";
import MyTransactions from "../pages/website/my-account/orders/MyTransactions";
import PurchaseDetail from "../pages/website/my-account/orders/PurchaseDetail";
import SaleDetail from "../pages/website/my-account/orders/SaleDetail";
import AddCard from "../pages/website/my-account/web-settings/AddCard";
import DeleteAccount from "../pages/website/my-account/web-settings/DeleteAccount";
import PaymentMethods from "../pages/website/my-account/web-settings/PaymentMethods";
import Settings from "../pages/website/my-account/web-settings/settings/Settings";
import StripeConnect from "../pages/website/my-account/web-settings/StripeConnect";
import StripeWelcome from "../pages/website/my-account/web-settings/StripeWelcome";
import WrapAddCard from "../pages/website/my-account/web-settings/WrapAddCard";
import ArtCheckout from "../pages/website/my-profile/artroom/checkout/ArtCheckout";
import CreateEvent from "../pages/website/my-profile/CreateEvent";
import EditProfile from "../pages/website/my-profile/EditProfile";
import EventDetail from "../pages/website/my-profile/EventDetail";
import MyProfile from "../pages/website/my-profile/MyProfile";
import Notifications from "../pages/website/notifications/Notifications";
import Opportunities from "../pages/website/opportunities/Opportunities";
import OpportunityDetail from "../pages/website/opportunities/OpportunityDetail";
import People from "../pages/website/people/People";
import Piece from "../pages/website/piece/Piece";
import SearchList from "../pages/website/search/SearchList";
import Social from "../pages/website/social/Social";
import UserProfile from "../pages/website/user-profile/UserProfile";
import { pathRoute } from "./pathRoute";

export const RoutesPath = [
  //   {
  //     path: "/:utm_term?",
  //     // path: "",
  //     name: "Home",
  //     element: Home,
  //   },
  {
    path: pathRoute.web.profile,
    name: "Profile",
    element: Profile,
    isback: false,
  },
  {
    path: `${pathRoute.web.artDetail}/:id`,
    name: "Art Detail",
    element: ArtRoomDetail,
    isback: false,
    isPortfolio: true,
  },
  {
    path: `${pathRoute.web.commentDetail}/:id`,
    name: "Comment Detail",
    element: FeedCommentDetail,
    isback: false,
  },
  {
    path: `${pathRoute.web.checkout}/:id`,
    name: "Checkout",
    element: Checkout,
    isback: false,
  },
];

export const AuthRoutes = [
  {
    id: 1,
    path: pathRoute.auth.login,
    name: "Login",
    element: Login,
    isback: false,
  },
  {
    id: 2,
    path: pathRoute.auth.register,
    name: "Register",
    element: Register,
    isback: false,
  },
  {
    id: 3,
    path: pathRoute.auth.profileSetup,
    name: "Profile Setup",
    element: ProfileSetup,
    isback: false,
  },
  {
    id: 4,
    path: pathRoute.auth.otp,
    name: "OTP",
    element: OTP,
    isback: false,
  },
  {
    id: 5,
    path: pathRoute.auth.forgotPassword,
    name: "Forgot Password",
    element: ForgotPassword,
    isback: false,
  },
  {
    id: 6,
    path: pathRoute.auth.resetPassword,
    name: "Reset Password",
    element: ResetPassword,
    isback: false,
  },
];
export const WebsiteRoutes = [
  {
    id: 1,
    path: pathRoute.auth.social,
    name: "Social",
    element: Social,
    isback: false,
  },

  {
    id: 2,
    path: pathRoute.website.opportunities,
    name: "Opportunities",
    element: Opportunities,
    isback: false,
  },
  {
    id: 3,
    path: `${pathRoute.website.opportunityDetail}/:id`,
    name: "Opportunity Detail",
    element: OpportunityDetail,
    isback: false,
  },

  {
    id: 4,
    path: `${pathRoute.website.userProfile}/:id`,
    name: "User Profile",
    element: UserProfile,
    isback: false,
  },

  {
    id: 5,
    path: pathRoute.website.settings,
    name: "Settings",
    element: Settings,
    isback: false,
  },

  {
    id: 6,
    path: pathRoute.website.searchList,
    name: "Search Result",
    element: SearchList,
    isback: false,
  },
  {
    id: 7,
    path: pathRoute.website.piece,
    name: "Piece",
    element: Piece,
    isback: false,
  },
  {
    id: 8,
    path: pathRoute.website.people,
    name: "People",
    element: People,
    isback: false,
  },

  {
    id: 9,
    path: `${pathRoute.website.artDetail}/:id`,
    name: "Art Detail",
    element: ArtRoomDetail,
    isback: false,
    isPortfolio: false,
  },
];

export const privateRoutes = [
  {
    id: 1,
    path: pathRoute.website.masterClass,
    name: "Master Class",
    element: MasterClass,
    isback: false,
  },
  {
    id: 2,
    path: pathRoute.website.myPurchase,
    name: "My Purchase",
    element: MyPurchase,
    isback: false,
  },
  {
    id: 3,
    path: pathRoute.website.mySales,
    name: "My Sales",
    element: MySales,
    isback: false,
  },
  {
    id: 4,
    path: pathRoute.website.myMasterclass,
    name: "My Masterclass",
    element: MyMasterclass,
    isback: false,
  },
  {
    id: 5,
    path: pathRoute.website.myTransactions,
    name: "My Transactions",
    element: MyTransactions,
    isback: false,
  },
  {
    id: 6,
    path: pathRoute.website.paymentMethods,
    name: "Payment Methods",
    element: PaymentMethods,
    isback: false,
  },
  {
    id: 7,
    path: pathRoute.website.stripe,
    name: "Stripe",
    element: StripeWelcome,
    isback: false,
  },
  {
    id: 8,
    path: pathRoute.website.editProfile,
    name: "Edit Profile",
    element: EditProfile,
  },
  {
    id: 9,
    path: `${pathRoute.website.checkout}/:id`,
    name: "Checkout",
    element: ArtCheckout,
    isback: false,
  },
  {
    id: 10,
    path: pathRoute.website.createPost,
    name: "Create Post",
    element: CreatePost,
    isback: false,
  },

  {
    id: 11,
    path: `${pathRoute.website.masterClassDetail}/:id`,
    name: "Master Class Detail",
    element: MasterClassDetail,
    isback: false,
  },
  {
    id: 12,
    path: pathRoute.website.myProfile,
    name: "My Profile",
    element: MyProfile,
    isback: false,
  },
  {
    id: 13,
    path: `${pathRoute.website.purchaseDetail}/:id`,
    name: "Purchase Detail",
    element: PurchaseDetail,
    isback: false,
  },

  {
    id: 14,
    path: `${pathRoute.website.saleDetail}/:id`,
    name: "Sales Detail",
    element: SaleDetail,
    isback: false,
  },

  {
    id: 15,
    path: pathRoute.website.createEvent,
    name: "Create Event",
    element: CreateEvent,
    isback: false,
  },
  {
    id: 16,
    path: `${pathRoute.website.editPost}/:id`,
    name: "Edit Post",
    element: CreatePost,
  },
  {
    id: 17,
    path: `${pathRoute.website.relatedMasterClassDetail}/:id`,
    name: "Related Master Class Detail",
    element: MasterClassDetail,
    isback: false,
  },
  {
    id: 18,
    path: pathRoute.website.stripeConnect,
    name: "StripeConnect",
    element: StripeConnect,
  },
  {
    id: 19,
    path: pathRoute.website.notifications,
    name: "Notifications",
    element: Notifications,
    isback: false,
  },
  {
    id: 19,
    path: pathRoute.website.addCard,
    name: "AddCard",
    element: WrapAddCard,
    isback: false,
  },
  {
    id: 20,
    path: `${pathRoute.website.eventDetail}/:id`,
    name: "EventDetail",
    element: EventDetail,
    isback: false,
  },
  {
    id: 21,
    path: pathRoute.website.masterClassPlans,
    name: "MasterClassPlans",
    element: MasterClassPlans,
    isback: false,
  },
  {
    id: 22,
    path: pathRoute.website.deleteAccount,
    name: "DeleteAccount",
    element: DeleteAccount,
    isback: false,
  },
];
