import React, { useEffect, useState } from "react";
import Paragraph from "../core/typography/Paragraph";
import InputType from "../core/form-components/InputType";
import { Form, Formik } from "formik";
import Dropdown from "../core/form-components/Dropdown";
import userProfile from "../../assets/images/dummy_profile.png";
import { FaUser } from "react-icons/fa";
import SelectType from "../core/form-components/SelectType";
import SearchIcon from "../../assets/images/icon_search.svg";
import OffCanvas from "../core/OffCanvas";
import SearchFilter from "../../pages/website/social/SearchFilter";
import Modal from "../core/Modal";
import { COUNT_INTERVAL, countryOptions } from "../../constant/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  languageIdSelector,
  setLanguageId,
} from "../../redux/slices/website/languageSlice";
import { Link, useLocation } from "react-router-dom";
import { pathRoute } from "../../routes/pathRoute";
import { LanguageSettings } from "../../services/website/languageService";
import { tokenSelector, userSelector } from "../../redux/slices/userSlice";
import DummyProfile from "../../assets/images/dummy_logo.png";
import { LuBell } from "react-icons/lu";
import { getAllStartData } from "../../services/website/socialService";
import { setCommonData } from "../../redux/slices/website/commonSlice";
import { getNotificationCount } from "../../services/website/notificationService";

interface headerProps {
  sideBarMobileOpen: boolean;
  handleMobileSidebar: () => void;
}

function Header({ sideBarMobileOpen, handleMobileSidebar }: headerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const profileData = useSelector(userSelector);
  const location = useLocation();
  const pathname = location?.pathname;
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  console.log("location", pathname);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchAlldata = async () => {
      try {
        const response = await getAllStartData();
        dispatch(setCommonData(response?.data));
      } catch (error) {
        console.error("Failed to fetch language settings:", error);
      }
    };

    fetchAlldata();
  }, [dispatch]);

  const handleDeactivate = async () => {
    try {
      const response = await getNotificationCount();
      if (response?.data?.code === 204) {
        setCount(response?.data?.unreadNotificationCount);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  useEffect(() => {
    const fetchNotificationCount = async () => {
      if (!token) return; // Exit if there is no token
      await handleDeactivate(); // Call the function if the token exists
    };

    fetchNotificationCount();

    if (token) {
      const interval = setInterval(() => {
        handleDeactivate();
      }, COUNT_INTERVAL);

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [dispatch, token]);

  const OnSubmit = () => {
    console.log("test");
  };

  const profileDropDownItems = [
    { Link: pathRoute.website.myProfile, LinkName: "My Profile" },
    {
      Link: "./login",
      LinkName: "Logout",
    },
  ];
  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };
  const closeDropdown = () => {
    setIsOpen(false);
  };
  const handleModal = () => {
    setModalOpen(!modalOpen);
  };
  const closeModal = () => {
    setModalOpen(false);
  };

  const handleLanguageChange = (option: { value: string } | null) => {
    if (option) {
      dispatch(setLanguageId({ languageId: option.value }));
    } else {
      dispatch(setLanguageId(null));
    }
  };

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  console.log("profileData", profileData);
  return (
    <header className="border-b border-medium-grey xl:px-10 py-3 md:px-4 px-3">
      <div className="grid grid-cols-2 lg:gap-6 gap-4">
        <div className="inline-flex items-center lg:gap-[30px] md:gap-5 gap-2">
          {windowWidth < 992 && (
            <button
              className="lg:hidden flex items-center justify-center z-30 relative w-8 h-8 text-white focus:outline-none"
              onClick={() => {
                handleMobileSidebar();
                setNavbarOpen(!navbarOpen);
              }}
            >
              <span
                className={`absolute h-0.5 w-5 bg-site-black transform transition duration-300 ease-in-out ${
                  sideBarMobileOpen ? "rotate-45 delay-200" : "-translate-y-1.5"
                }`}
              ></span>
              <span
                className={`absolute h-0.5 bg-site-black transform transition-all duration-200 ease-in-out ${
                  sideBarMobileOpen
                    ? "w-0 opacity-50"
                    : "w-5 delay-200 opacity-100"
                }`}
              ></span>
              <span
                className={`absolute h-0.5 w-5 bg-site-black transform transition duration-300 ease-in-out ${
                  sideBarMobileOpen ? "-rotate-45 delay-200" : "translate-y-1.5"
                }`}
              ></span>
            </button>
          )}

          <Modal open={modalOpen} onClose={closeModal}>
            <Formik initialValues={{ otp: "" }} onSubmit={() => OnSubmit()}>
              <Form className="w-full">
                <InputType
                  type="search"
                  name="search"
                  placeholder="Search"
                  fullWidth
                  className="!max-w-[348px] !w-full "
                />
              </Form>
            </Formik>
          </Modal>
        </div>
        <OffCanvas
          isOpen={isOffcanvasOpen}
          onClose={() => setIsOffcanvasOpen(false)}
          position="right"
          size="400px"
          backdrop
          offCanvasTitle="Filter"
        >
          <SearchFilter />
        </OffCanvas>
        <div className="inline-flex justify-end items-center lg:gap-4 gap-3">
          {windowWidth < 768 && (
            <img src={SearchIcon} alt="searchIcon" onClick={handleModal} />
          )}
          <Link to={pathRoute?.website?.notifications} className="relative">
            <LuBell size={24} />
            {count > 0 ? (
              <span className="absolute top-0 right-0 w-[14px] h-[14px] rounded-full bg-site-red text-white text-xs leading-[21px] pt-[2px] font-semibold inline-flex items-center justify-center">
                {count}
              </span>
            ) : (
              ""
            )}
          </Link>
          {!token && windowWidth < 768 && (
            <Link
              className="text-base leading-4 font-medium text-site-black"
              to={pathRoute?.auth?.login}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 24C7.5 24 6 24 6 22.5C6 21 7.5 16.5 15 16.5C22.5 16.5 24 21 24 22.5C24 24 22.5 24 22.5 24H7.5Z"
                  fill={"#121111"}
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 15C16.1935 15 17.3381 14.5259 18.182 13.682C19.0259 12.8381 19.5 11.6935 19.5 10.5C19.5 9.30653 19.0259 8.16193 18.182 7.31802C17.3381 6.47411 16.1935 6 15 6C13.8065 6 12.6619 6.47411 11.818 7.31802C10.9741 8.16193 10.5 9.30653 10.5 10.5C10.5 11.6935 10.9741 12.8381 11.818 13.682C12.6619 14.5259 13.8065 15 15 15Z"
                  fill={"#121111"}
                />
              </svg>
            </Link>
          )}
          {windowWidth > 767 && (
            <SelectType
              options={countryOptions}
              onChange={handleLanguageChange}
              countryDropdown
              className="!border-0 !py-0 !px-0 !w-[77px]"
              value={
                countryOptions?.find((option) => option?.value === langId) || ""
              }
            />
          )}
          {!token && windowWidth > 767 && (
            <div className="inline-flex items-center gap-1">
              <Link
                className="text-base leading-4 font-medium text-site-black"
                to={pathRoute?.auth?.login}
              >
                Sign In
              </Link>{" "}
              /{" "}
              <Link
                className="text-base leading-4 font-medium text-site-black"
                to={pathRoute?.auth?.register}
              >
                Sign Up
              </Link>
            </div>
          )}
          {token && (
            <Dropdown
              className={"flex items-center justify-center"}
              dropdownItems={profileDropDownItems}
              withChevron
              onClick={() => toggleDropdown("userProfile")}
              chevronColor="#808186"
            >
              <div
                className={
                  "md:w-10 md:h-10 sm:w-8 sm:h-8 rounded-full bg-[#F7F7FC] flex items-center justify-center"
                }
              >
                {profileData?.avatar ? (
                  <img
                    src={getProfileImage(profileData?.avatar)}
                    alt="userprofile"
                    width={windowWidth < 768 ? 30 : 40}
                    height={windowWidth < 768 ? 30 : 40}
                    className="rounded-full object-cover h-full w-full"
                  />
                ) : (
                  <FaUser fontSize={"20px"} />
                )}
              </div>
              <Paragraph
                text16
                className={
                  "font-normal truncate xxl:max-w-[96px] lg:max-w-[80px] max-w-[40px]"
                }
              >
                {profileData?.name} {profileData?.surname}
              </Paragraph>
            </Dropdown>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
