import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import DummyProfile from "../../assets/images/dummy_logo.png";

import { useDispatch, useSelector } from "react-redux";
import IconShare from "../../assets/images/icon_share.svg";
import { ResponseCommentInterface } from "../../interface/ResponseComment";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import {
  addReplyComments,
  editReplyComments,
  getFeedResponseCommentsById,
  likeFeedInReplyCommentSection,
  removeReplyComment,
} from "../../services/website/feedService";
import Paragraph from "../core/typography/Paragraph";
import TimeDifference from "./TimeDifference";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { userSelector } from "../../redux/slices/userSlice";
import { BiSolidPencil } from "react-icons/bi";
import IconDelete from "../../assets/images/icon-delete.svg";
import IconCopy from "../../assets/images/icon-copy.svg";
import IconReport from "../../assets/images/icon-info.svg";
import IconHide from "../../assets/images/icon-hide.svg";
import Dropdown from "../core/form-components/Dropdown";

interface ReplyInterface {
  selectedReplyCommentId: number;
}

const CommentReplySection = ({ selectedReplyCommentId }: ReplyInterface) => {
  const [currentResponsePage, setCurrentResponsePage] = useState(1);
  const [responseCommentData, setResponseCommentData] = useState<
    ResponseCommentInterface[]
  >([]);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [replyInputId, setReplyInputId] = useState<number | null>(null);
  const [replyComment, setReplyComment] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const webProfileData = useSelector(userSelector);
  const [isEdit, setIsEdit] = useState<number | null>(null);
  const [editText, setEditText] = useState("");

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  const fetchResponseComments = async (page: number, id?: number) => {
    if (id) {
      const queryString = `?limit=${itemsPerPage}&page=${page}`;
      dispatch(showLoader());
      setLoading(true);

      try {
        const response = await getFeedResponseCommentsById(id, queryString);
        const count = response?.data?.total || 0;
        if (response?.data?.responses) {
          setResponseCommentData((prevData) => [
            ...prevData,
            ...response.data.responses,
          ]);
          setTotalItems(count);
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
        setLoading(false);
      }
    } else {
      console.warn("selectedReplyCommentId is undefined, skipping API call.");
    }
  };

  useEffect(() => {
    if (selectedReplyCommentId) {
      fetchResponseComments(currentResponsePage, selectedReplyCommentId);
    }
  }, [currentResponsePage, selectedReplyCommentId]);

  const handlereplyLikeCommentSection = async (
    item: ResponseCommentInterface
  ) => {
    try {
      const response = await likeFeedInReplyCommentSection(item?.id || 0);

      if (response?.data?.code === 200) {
        setResponseCommentData((prevData) =>
          prevData.map((feedItem) =>
            feedItem.id === item.id
              ? {
                  ...feedItem,
                  likedByMe: !feedItem.likedByMe,
                  likerCount: feedItem.likedByMe
                    ? feedItem.likerCount - 1
                    : feedItem.likerCount + 1,
                }
              : feedItem
          )
        );
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    }
  };

  const handleReplySection = (id: number, username: string) => {
    if (replyInputId === id) {
      // Hide the input box if the same reply is clicked
      setReplyInputId(null);
      setReplyComment(""); // Clear the reply input when toggled off
    } else {
      // Show the input box for the selected reply
      setReplyInputId(id);
      setReplyComment(`@${username} `); // Set the username in the reply input
    }
  };

  const handleCommentReply = async (id: any) => {
    const textWithoutMention = replyComment.replace(/^@\w+\s*/, "");

    const trimmedComment = textWithoutMention.trim();
    if (!trimmedComment) return;
    dispatch(showLoader());

    try {
      let data = {
        text: trimmedComment, // Ensure no trailing whitespace
      };
      const response = await addReplyComments(data, id);
      if (response?.data?.code === 200) {
        setResponseCommentData((prevData) => [...prevData, response.data.data]);
        setReplyComment("");
        setReplyInputId(null);
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const commentProfileDownItems = [
    {
      icon: <BiSolidPencil />,
      Link: "",
      LinkName: "Edit",
      handleAction: true,
    },
    {
      icon: <img src={IconDelete} alt="icon-delete" width={12} height={12} />,
      Link: "",
      LinkName: "Delete",
      handleAction: true,
    },
  ];

  const commentUserDownItems = [
    {
      icon: <img src={IconCopy} alt="icon-copy" width={12} height={12} />,
      Link: "",
      LinkName: "Copy",
      handleAction: true,
    },
    {
      icon: <img src={IconReport} alt="icon-report" width={12} height={12} />,
      Link: "",
      LinkName: "Report",
      handleAction: true,
    },
    {
      icon: <img src={IconHide} alt="icon-hide" width={12} height={12} />,
      Link: "",
      LinkName: "Hide",
      handleAction: true,
    },
    {
      icon: <img src={IconDelete} alt="icon-remove" width={12} height={12} />,
      Link: "",
      LinkName: "Remove",
      handleAction: true,
    },
  ];

  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };

  const handleDeleteItem = async (id: any) => {
    dispatch(showLoader());
    try {
      const response = await removeReplyComment(id);
      if (response?.data?.code === 200) {
        setResponseCommentData((prevData) =>
          prevData?.filter((item) => item.id !== id)
        );
        setTotalItems((prevTotal) => prevTotal - 1);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleCancelComments = () => {
    setIsEdit(null);
    setEditText("");
  };
  const handleEditComments = async () => {
    dispatch(showLoader());

    try {
      let data = {
        text: editText,
      };
      const response = await editReplyComments(data, isEdit || 0);
      if (response?.data?.code === 200) {
        setResponseCommentData((prevData) =>
          prevData.map((feedItem) =>
            feedItem.id === isEdit
              ? {
                  ...feedItem,
                  text: editText, // Toggle likedByMe
                }
              : feedItem
          )
        );

        setEditText("");
        setIsEdit(null);
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleEditSection = (id: number, text: string) => {
    if (isEdit === id) {
      setIsEdit(null);
      setEditText("");
    } else {
      setIsEdit(id);
      setEditText(text);
    }
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Text copied to clipboard:", text);
        // Optionally, display a success toast/message here
      },
      (err) => {
        console.error("Failed to copy text:", err);
        // Optionally, display an error toast/message here
      }
    );
  };

  const handleActionsInweb = (type: string, item: any) => {
    console.log("dfsdfsdfds", item);
    switch (type) {
      case "Delete":
        handleDeleteItem(item?.id);
        break;
      case "Edit":
        handleEditSection(item?.id, item?.text);
        break;
      case "Copy":
        handleCopyToClipboard(item?.text);
        break;
      case "Remove":
        handleDeleteItem(item?.id);
        break;
      default:
        console.log("Unknown action type:", type);
        break;
    }
  };

  return (
    <div
      id="scrollableDiv"
      style={{ overflowY: "auto", maxHeight: "200px", height: "100%" }}
    >
      <InfiniteScroll
        dataLength={responseCommentData?.length}
        next={() => setCurrentResponsePage((prevPage) => prevPage + 1)}
        hasMore={responseCommentData?.length < totalItems}
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
      >
        <ul className="mt-6">
          {responseCommentData.map((respItem: ResponseCommentInterface) => (
            <li
              key={respItem.id}
              className="flex items-start sm:gap-3 gap-2 lg:pb-5 pb-4 border-medium-grey"
            >
              <img
                src={getProfileImage(respItem?.user?.avatar)}
                alt="profile"
                className="sm:w-10 sm:h-10 w-8 h-8 rounded-full flex-shrink-0"
              />
              <div className="w-full">
                <div className="flex items-start gap-3 justify-between w-full">
                  <div>
                    <div className={`flex items-center sm:gap-3 gap-2`}>
                      <Paragraph text16 className="!leading-4 !font-medium">
                        @{respItem?.user?.nickname}
                      </Paragraph>
                      <Dropdown
                        onClick={() => toggleDropdown("commentDrop")}
                        dropdownItems={
                          respItem?.user?.id == webProfileData?.id
                            ? commentProfileDownItems
                            : commentUserDownItems
                        }
                        dropdownWidth="w-[174px]"
                        handleActionsInweb={(e: any) =>
                          handleActionsInweb(e, respItem)
                        }
                      >
                        <IoEllipsisHorizontal size={14} />
                      </Dropdown>
                    </div>
                    {isEdit == respItem?.id ? (
                      <div className="relative">
                        <input
                          name="comment"
                          value={editText}
                          onChange={(e) => setEditText(e.target.value)}
                          placeholder="Add a comment..."
                          className="bg-light-grey rounded-lg px-3 py-2.5 pr-20 w-full focus:outline-none"
                        />
                        <div className="absolute right-3 top-2.5 flex space-x-2">
                          <button
                            onClick={handleCancelComments}
                            className="inline-flex items-center justify-center w-6 h-6"
                          >
                            <img src={IconDelete} alt="icon-cancel" />
                          </button>
                          <button
                            onClick={handleEditComments}
                            className="inline-flex items-center justify-center w-6 h-6"
                          >
                            <img src={IconShare} alt="icon-share" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <Paragraph
                        text14
                        className="!leading-[17px] !font-normal !text-dark-grey mb-3"
                      >
                        {respItem?.text}
                      </Paragraph>
                    )}

                    <div className="inline-flex items-center">
                      <Paragraph
                        text14
                        className="!leading-[15px] !font-normal !text-dark-grey sm:pr-4 sm:mr-4 pr-3 mr-3 border-r border-medium-grey "
                      >
                        <TimeDifference date={respItem?.createdAt} />
                      </Paragraph>
                      <Paragraph
                        text14
                        className="!leading-[15px] cursor-pointer !font-normal !text-dark-grey  "
                        onClick={() =>
                          handleReplySection(
                            respItem?.id || 0,
                            respItem?.user?.nickname || ""
                          )
                        }
                      >
                        {replyInputId === respItem.id ? "Cancel" : "Reply"}
                      </Paragraph>
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-1">
                    <button>
                      {respItem?.likedByMe ? (
                        <IoMdHeart
                          color="#DF3131"
                          size={18}
                          onClick={() =>
                            handlereplyLikeCommentSection(respItem)
                          }
                        />
                      ) : (
                        <IoMdHeartEmpty
                          size={18}
                          color={"#4D4B4B"}
                          onClick={() =>
                            handlereplyLikeCommentSection(respItem)
                          }
                        />
                      )}
                    </button>
                    <Paragraph text14 className="!leading-3">
                      {respItem?.likerCount}
                    </Paragraph>
                  </div>
                </div>
                {replyInputId === respItem.id && (
                  <div className="relative mt-3.5">
                    <input
                      name="reply"
                      value={replyComment}
                      onChange={(e) => setReplyComment(e.target.value)}
                      placeholder="Add a comment..."
                      className="bg-light-grey rounded-lg px-3 py-2.5 pr-10 w-full focus:outline-none"
                    />
                    <button
                      onClick={() => handleCommentReply(selectedReplyCommentId)}
                      className="inline-flex items-center justify-center w-6 h-6 absolute right-3 top-2.5"
                    >
                      <img src={IconShare} alt="icon-share" />
                    </button>
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
      </InfiniteScroll>
    </div>
  );
};

export default CommentReplySection;
