import React from "react";
import Paragraph from "../../../../components/core/typography/Paragraph";
import Button from "../../../../components/core/form-components/Button";
import { MdError } from "react-icons/md";

function DeleteAccountPopup() {
  return (
    <div className="flex flex-col items-center justify-center text-center">
      <MdError size={80} className="text-site-red mb-30" />
      <Paragraph text24 className="lg:mb-4 mb-3">
        Are you sure you want to delete your account permanently?
      </Paragraph>
      <Paragraph text20 className="mb-30">
        You will not retrieve your data back.
      </Paragraph>
      <div className="flex items-center lg:gap-6 gap-4">
        <Button primary>Delete</Button>
        <Button borderButton>Cancel</Button>
      </div>
    </div>
  );
}

export default DeleteAccountPopup;
