import React, { useEffect, useState } from "react";
import Button from "../../../../components/core/form-components/Button";
import { useNavigate } from "react-router-dom";
import { pathRoute } from "../../../../routes/pathRoute";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "../../../../redux/slices/userSlice";
import {
  hideLoader,
  showLoader,
} from "../../../../redux/slices/siteLoaderSlice";
import { getStripeAccount } from "../../../../services/website/accountService";
import Paragraph from "../../../../components/core/typography/Paragraph";

const StripeWelcome = () => {
  const navigate = useNavigate();
  const webProfileData = useSelector(userSelector);
  const dispatch = useDispatch();
  const [stripeUrl, setStripeUrl] = useState<string | null>(null);

  const handleStripeConnect = () => {
    navigate(pathRoute.website.stripeConnect);
  };

  const getStripeLoginLink = async () => {
    dispatch(showLoader());
    try {
      const response = await getStripeAccount();
      if (response?.data?.code === 201) {
        setStripeUrl(response.data.url);
      }
    } catch (error) {
      console.error("Error fetching Stripe login link:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (webProfileData?.hasAccount) {
      getStripeLoginLink();
    }
  }, [webProfileData?.hasAccount]);

  return (
    <div>
      {/* {webProfileData?.hasAccount ? (
        stripeUrl ? (
          <iframe
            src={stripeUrl}
            width="100%"
            height="600px"
            style={{ border: "none" }}
            title="Stripe Connection"
          />
        ) : (
          <p>Loading Stripe...</p>
        )
      ) : (
        <>
          <h1>Connect with Stripe</h1>
          <Button onClick={handleStripeConnect} primary>
            Connect
          </Button>
        </>
      )} */}
      <div className="grid grid-cols-12">
        <div className="col-span-12">
          <div className="border border-medium-grey rounded-2xl lg:p-10 md:p-6 sm:p-5 p-4">
            <Paragraph text20>You haven't set up your account yet!</Paragraph>
            <Paragraph text18 className="text-dark-grey">
              You haven't set up your account yet!
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripeWelcome;
