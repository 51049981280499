import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { getCountriesByInfo } from "../../../../../../services/countryService";
import Paragraph from "../../../../../../components/core/typography/Paragraph";
import FormLabel from "../../../../../../components/core/typography/FormLabel";
import InputType from "../../../../../../components/core/form-components/InputType";
import {
  hideLoader,
  showLoader,
} from "../../../../../../redux/slices/siteLoaderSlice";
import { useDispatch } from "react-redux";
import { getCardsList } from "../../../../../../services/website/accountService";
import AmazonPay from "../../../../../../assets/images/icon_amazon_pay.svg";
import VisaPay from "../../../../../../assets/images/icon_visa.svg";
import { IoIosArrowDown, IoIosCheckmarkCircle } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import PaymentInfo from "./PaymentInfo";
import RadioButton from "../../../../../../components/core/form-components/RadioButton";
import { CardInterface } from "../../../../../../interface/CardDetails";

interface basicInterface {
  handleOtherCard: (val: any) => void;
}

const BasicInfo = ({ handleOtherCard }: basicInterface) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [cards, setCards] = useState<CardInterface[]>([]);

  const { values, errors, setFieldValue, handleBlur, setErrors } =
    useFormikContext<{
      name: string;
      email: string;
      phone_number: string;
      phone_country_code: string;
      isCardSelected: {
        id: string;
        stripeId: string;
      };
      otherCard: number;
    }>();

  const getCards = async () => {
    dispatch(showLoader());
    try {
      const response = await getCardsList();
      if (response?.data?.code === 200) {
        setCards(response?.data?.cards);
        if (response?.data?.cards.length === 0) {
          console.log("vishwa", response?.data?.cards.length);
          handleOtherCard(true);
        }
      }
    } catch (error) {
      console.error("Error fetching Stripe login link:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    getCards();
  }, []);

  const getIcon = (item: any) => {
    switch (item) {
      case "visa":
        return VisaPay;
      default:
        return AmazonPay;
    }
  };

  const handleAddCard = () => {
    setFieldValue("isCardSelected", {
      id: "",
      stripeId: "",
    });
    setFieldValue("otherCard", true);
    handleOtherCard(true);
  };

  const handleSelect = (item: any) => {
    setFieldValue("otherCard", false);
    handleOtherCard(false);
    setErrors({});
    setFieldValue("isCardSelected", {
      id: item.id,
      stripeId: item.idStripePaymentMethod,
    });
  };
  console.log("otfsfdsfherCard", values?.otherCard);
  return (
    <div className="md:pt-10 pt-7 w-full">
      {cards?.length > 0 && (
        <div className="grid grid-cols-12 gap-3">
          <div className="col-span-12">
            <Paragraph text20 className="font-medium md:mb-4 mb-3">
              Payment Info.
            </Paragraph>
            <ul>
              {cards?.map((item: CardInterface, index: number) => {
                return (
                  <li
                    className="mb-3"
                    key={item?.id}
                    onClick={() => handleSelect(item)}
                  >
                    <label
                      htmlFor="master-card"
                      className="border border-medium-grey rounded-2xl md:p-4 p-3 flex items-center justify-between cursor-pointer"
                    >
                      <RadioButton
                        key={item?.id}
                        id={`radio ${index + 1}`}
                        name={item?.name}
                        labelClass="!text-base"
                        checked={
                          parseInt(values?.isCardSelected?.id) === item?.id
                        }
                        onChange={() => handleSelect(item)}
                      >
                        <div className="inline-flex items-center md:gap-4 gap-3">
                          <div className="bg-light-grey w-[54px] h-[36px] rounded-[4px] inline-flex items-center justify-center">
                            <img src={getIcon(item?.brand)} alt="amazon-pay" />
                          </div>
                          <div>
                            <Paragraph
                              text18
                              className="!font-medium inline-flex items-center gap-3 !leading-4"
                            >
                              Mastercard
                              <span className="text-dark-grey">|</span>
                              <span className="">...{item?.last4}</span>
                            </Paragraph>
                            <Paragraph
                              text14
                              className="!font-normal !text-dark-grey leading-[12px]"
                            >
                              Pay & secure with mastercard
                            </Paragraph>
                          </div>
                        </div>
                      </RadioButton>
                    </label>
                  </li>
                );
              })}
              <li key="other card" onClick={handleAddCard}>
                <div className="border border-medium-grey rounded-2xl p-3">
                  <label className="flex items-center justify-between cursor-pointer">
                    <div className="inline-flex items-center md:gap-4 gap-3">
                      <div className="bg-light-grey w-[54px] h-[36px] rounded-[4px] inline-flex items-center justify-center">
                        <FiPlus color="#1AB53C" size={24} />
                      </div>
                      <div>
                        <Paragraph
                          text18
                          className="!font-medium inline-flex items-center gap-3 !leading-4"
                        >
                          Use an another card
                        </Paragraph>
                      </div>
                    </div>
                  </label>
                  {values?.otherCard && <PaymentInfo isCard={true} />}
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}
      {cards.length === 0 && <PaymentInfo />}
    </div>
  );
};

export default BasicInfo;
