import * as Yup from "yup";
import { HTTPS_STRING, HTTP_STRING, WWW_STRING } from "../../constant/constant";

export const eventValidationSchema = Yup.object({
  eventName: Yup.string()
    .trim()
    .max(255, "Maximum 255 characters allowed")
    .required("Event name is required"),

  location: Yup.string()
    .trim()
    .max(255, "Maximum 255 characters allowed")
    .required("Location is required"),

  startDate: Yup.date()
    .typeError("Invalid date format")
    .required("Start date is required"),

  endDate: Yup.date()
    .typeError("Invalid date format")
    .min(Yup.ref("startDate"), "End date cannot be before start date")
    .required("End date is required"),

  description: Yup.string()
    .trim()
    .max(1000, "Maximum 1000 characters allowed")
    .required("Description is required"),

  imageUrl: Yup.string().required("Image is required"),
  price: Yup.number().min(0, "Price cannot be negative"),
  trackingUrl: Yup.string()
    .trim()
    .test("is-valid-url", "Invalid tracking URL", (value) => {
      if (!value) return true; // Allow empty value (optional field)
      const lowerValue = value.toLowerCase();
      return (
        lowerValue.includes(HTTP_STRING) ||
        lowerValue.includes(HTTPS_STRING) ||
        lowerValue.includes(WWW_STRING)
      );
    }),
});
