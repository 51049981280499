import React, { useEffect, useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";
import Dropdown from "../../../components/core/form-components/Dropdown";
import { IoEllipsisVertical, IoQrCode } from "react-icons/io5";
import Cultural from "../../../assets/images/cultural.png";
import ImageWithDefault from "../../../components/web-component/DefaultImage";
import { IoLocationSharp } from "react-icons/io5";
import TextLink from "../../../components/core/form-components/TextLink";
import { useLocation } from "react-router-dom";

function EventDetail() {
  const location = useLocation();
  const item = location?.state?.item;
  console.log("fdsfsdfsf", item);

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")} at ${String(
      date.getHours()
    ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}`;
  };
  return (
    <>
      <section>
        <div className="py-4 xl:px-10 lg:px-6 md:px-4 px-3">
          <div className={"pt-6 md:py-[50px] py-[30px]"}>
            <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
              <div className="col-span-1">
                <div className="sticky top-4">
                  <div className="w-full rounded-[20px] flex items-center justify-center mb-3">
                    {/* {renderMedia(artDetails)} */}
                    <img
                      src={item?.event?.coverImage?.imageUrl}
                      alt="dashboard_mockup"
                      width="620px"
                      height="582px"
                      className="inline-block"
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1">
                <div className="md:mb-[30px] mb-5">
                  <Paragraph
                    text24
                    className="!font-semibold text-site-black mb-3"
                  >
                    {item?.event?.name}
                    <span className="inline-block text-[18px] leading-[20px] font-semibold text-site-green ps-[10px]">
                      {item?.event?.public ? "(Public)" : "(Private)"}
                    </span>
                  </Paragraph>
                  <Paragraph
                    text18
                    className="flex items-center gap-1 text-site-black mb-3"
                  >
                    <IoLocationSharp size={24} className="text-dark-grey" />
                    {item?.event?.location}
                  </Paragraph>
                  <Paragraph text18 className="mb-2 font-normal">
                    <span className="font-medium">Start Date | </span>
                    {formatDate(item?.event?.startDateTime)}
                  </Paragraph>
                  <Paragraph text18 className="md:mb-4 mb-3 font-normal">
                    <span className="font-medium">End Date | </span>
                    {formatDate(item?.event?.endDateTime)}
                  </Paragraph>
                  {item?.event?.price && (
                    <Paragraph
                      text24
                      className="!font-semibold md:mb-[30px] mb-5 text-site-black"
                    >
                      {item?.event?.price}€
                    </Paragraph>
                  )}
                  {item?.event?.ticketingLink && (
                    <Button primary className="w-full">
                      Access Ticket URL
                    </Button>
                  )}
                </div>
                <div className="mb-4 pb-4 border-b border-medium-grey">
                  <Paragraph
                    text20
                    className="text-site-black mb-3 font-medium !leading-6"
                  >
                    {item?.event?.description}
                  </Paragraph>
                </div>
                <div>
                  <Paragraph
                    text20
                    className="text-site-black mb-3 font-medium !leading-6"
                  >
                    Event URLs
                  </Paragraph>
                  <ul>
                    <li className="mb-2">
                      <Paragraph text18 className="!font-normal">
                        Google:
                      </Paragraph>
                      <TextLink
                        to={item?.event?.urls?.googleCalUrl}
                        className="!text-[18px] !leading-7 !font-normal text-[#61AEE7]"
                        target="_blank"
                      >
                        {item?.event?.urls?.googleCalUrl}
                      </TextLink>
                    </li>
                    <li className="mb-2">
                      <Paragraph text18 className="!font-normal">
                        iCal:
                      </Paragraph>
                      <TextLink
                        to={item?.event?.urls?.iCalUrl}
                        className="!text-[18px] !leading-7 !font-normal text-[#61AEE7]"
                        target="_blank"
                      >
                        {item?.event?.urls?.iCalUrl}
                      </TextLink>
                    </li>
                    <li className="mb-2">
                      <Paragraph text18 className="!font-normal">
                        Yahoo:
                      </Paragraph>
                      <TextLink
                        to={item?.event?.urls?.yahooCalUrl}
                        className="!text-[18px] !leading-7 !font-normal text-[#61AEE7]"
                        target="_blank"
                      >
                        {item?.event?.urls?.yahooCalUrl}
                      </TextLink>
                    </li>
                    <li>
                      <Paragraph text18 className="!font-normal">
                        Outlook:
                      </Paragraph>
                      <TextLink
                        to={item?.event?.urls?.outlookCalUrl}
                        className="!text-[18px] !leading-7 !font-normal text-[#61AEE7]"
                        target="_blank"
                      >
                        {item?.event?.urls?.outlookCalUrl}
                      </TextLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EventDetail;
