import { ErrorMessage, Field, useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getCountries } from "../../../../../../services/countryService";
import FormLabel from "../../../../../../components/core/typography/FormLabel";
import Paragraph from "../../../../../../components/core/typography/Paragraph";
import InputType from "../../../../../../components/core/form-components/InputType";
import SwitchToggle from "../../../../../../components/core/form-components/SwitchToggle";

const ShippingAddress = () => {
  const [selectedOption, setSelectedOption] = useState("");
  // const [isBillingSame, setIsBillingSame] = useState(true); // By default set to true
  const [countryList, setCountryList] = useState<
    { code: string; name: string }[]
  >([]);
  const { t } = useTranslation();

  const { values, errors, setFieldValue, touched } = useFormikContext<{
    shipping_address: string;
    shipping_city: string;
    shipping_zip: string;
    shiiping_country: string;
    billing_address: string;
    billing_city: string;
    billing_zip: string;
    blilling_country: string;
    isBillingSame: boolean;
  }>();

  const countryOptions = countryList.map((country) => ({
    label: country.name,
    value: country.code,
  }));

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getCountries();
        setCountryList(response?.data?.countries || []);
      } catch (error) {
        console.error("Error validating token:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (values.isBillingSame) {
      // If true on initial load, copy shipping values to billing
      setFieldValue("billing_address", values.shipping_address);
      setFieldValue("billing_city", values.shipping_city);
      setFieldValue("billing_zip", values.shipping_zip);
      setFieldValue("blilling_country", values.shiiping_country);
    }
  }, [
    values.isBillingSame,
    values.shipping_address,
    values.shipping_city,
    values.shipping_zip,
    values.shiiping_country,
    setFieldValue,
  ]);

  const handleChange = (selected: any, name: string) => {
    setSelectedOption(selected);
    setFieldValue(name, selected ? selected.label : "");
  };

  const handleToggleChange = () => {
    const newIsBillingSame = !values.isBillingSame;
    setFieldValue("isBillingSame", newIsBillingSame); // Toggle the value

    if (newIsBillingSame) {
      // If true, copy shipping values to billing
      setFieldValue("billing_address", values.shipping_address);
      setFieldValue("billing_city", values.shipping_city);
      setFieldValue("billing_zip", values.shipping_zip);
      setFieldValue("blilling_country", values.shiiping_country);
    } else {
      // If false, clear billing fields
      setFieldValue("billing_address", "");
      setFieldValue("billing_city", "");
      setFieldValue("billing_zip", "");
      setFieldValue("blilling_country", "");
    }
  };
  return (
    <div className="md:pt-10 pt-7 w-full">
      <Paragraph text20 className="font-medium mb-4">
        {t("checkout.shipping")}
      </Paragraph>
      <div className="grid grid-cols-12 lg:gap-6 gap-3 mb-4">
        <div className="col-span-12">
          <FormLabel>
            {t("checkout.form_fields.full_Address.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          <InputType
            name="shipping_address"
            placeholder="Enter full address"
            value={values.shipping_address}
          />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            {t("checkout.form_fields.city.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          <InputType
            name="shipping_city"
            placeholder="Enter city"
            value={values.shipping_city}
          />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            {t("checkout.form_fields.post_code.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          <InputType
            name="shipping_zip"
            placeholder="Enter post code"
            value={values.shipping_zip}
          />
        </div>
        <div className="md:col-span-6 col-span-12">
          <FormLabel>
            {t("checkout.form_fields.country.label")}
            <span className="text-site-red">*</span>
          </FormLabel>
          <Select
            unstyled
            onChange={(selected) => handleChange(selected, "shiiping_country")}
            options={countryOptions}
            className="react-select-container"
            classNamePrefix="react-select"
            placeholder="Select country"
            name={"shiiping_country"}
            value={countryOptions?.find(
              (option) => option.label === values.shiiping_country
            )}
          />
          {touched.shiiping_country && errors.shiiping_country && (
            <div className="text-site-red text-sm font-medium">
              {errors?.shiiping_country}
            </div>
          )}
        </div>
      </div>
      <SwitchToggle
        rightLabel="Delivery address is the same as the billing address"
        onChange={handleToggleChange}
        isChecked={values.isBillingSame} // Bind to isBillingSame
      />
      {!values.isBillingSame && (
        <div className="mt-30">
          <Paragraph text20 className="font-medium mb-4">
            {t("checkout.billing")}
          </Paragraph>
          <div className="grid grid-cols-12 lg:gap-6 gap-3">
            <div className="col-span-12">
              <FormLabel>
                {t("checkout.form_fields.full_Address.label")}
                <span className="text-site-red">*</span>
              </FormLabel>
              <InputType
                name="billing_address"
                placeholder="Enter full address"
                value={values.billing_address}
              />
            </div>
            <div className="md:col-span-6 col-span-12">
              <FormLabel>
                {t("checkout.form_fields.city.label")}
                <span className="text-site-red">*</span>
              </FormLabel>
              <InputType
                name="billing_city"
                placeholder="Enter city"
                value={values.billing_city}
              />
            </div>
            <div className="md:col-span-6 col-span-12">
              <FormLabel>
                {t("checkout.form_fields.post_code.label")}
                <span className="text-site-red">*</span>
              </FormLabel>
              <InputType
                name="billing_zip"
                placeholder="Enter post code"
                value={values.billing_zip}
              />
            </div>
            <div className="md:col-span-6 col-span-12">
              <FormLabel>
                {t("checkout.form_fields.country.label")}
                <span className="text-site-red">*</span>
              </FormLabel>
              <Select
                unstyled
                onChange={(selected) =>
                  handleChange(selected, "blilling_country")
                }
                options={countryOptions}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select country"
                name={"blilling_country"}
                value={countryOptions?.find(
                  (option) => option.label === values.blilling_country
                )}
              />

              {touched.blilling_country && errors.blilling_country && (
                <div className="text-site-red text-sm font-medium">
                  {errors?.blilling_country}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShippingAddress;
