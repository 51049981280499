import React, { useEffect, useState } from "react";
import Checkbox from "../../../components/core/form-components/Checkbox";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";
import { useFormikContext } from "formik";

interface colorInterface {
  id: number;
  hexcode: string;
  name: string;
}
interface ColorPickerModalProps {
  open: boolean;
  onClose?: () => void;
  colorOptions: any[];
  id: any;
  selectedColors: colorInterface[];
}

function ColorPickerModal({
  open,
  onClose,
  colorOptions,
  id,
  selectedColors,
}: ColorPickerModalProps) {
  // const [selectedColors, setSelectedColors] = useState<
  //   Array<{ id: number; hexcode: string; name: string }>
  // >([]);

  const { values, setFieldValue } = useFormikContext<{
    mainColor: {
      hexcode: string;
      id: number;
      name: string;
    } | null;
    secondaryColor: {
      hexcode: string;
      id: number;
      name: string;
    } | null;
  }>();

  useEffect(() => {
    // Initialize selectedColors based on form values and id
    if (id) {
      const colors = [];
      if (values.mainColor) {
        colors.push(values.mainColor);
      }
      if (values.secondaryColor) {
        colors.push(values.secondaryColor);
      }
      setFieldValue("selectedColors", colors);
    }
  }, [id, values.mainColor, values.secondaryColor]);

  useEffect(() => {
    // Update formik values whenever selectedColors changes
    if (selectedColors?.length > 0) {
      setFieldValue("mainColor", selectedColors[0] || null);
      setFieldValue("secondaryColor", selectedColors[1] || null);
    }
  }, [selectedColors, id, setFieldValue]);

  const handleCheckboxChange = (color: {
    id: number;
    name: string;
    hexcode: string;
  }) => {
    if (selectedColors.some((selected) => selected.id === color.id)) {
      // Deselect the color if it's already selected

      setFieldValue(
        "selectedColors",
        selectedColors.filter((selected) => selected.id !== color.id)
      );
    } else if (selectedColors.length < 2) {
      // Add color if we have space for another selection
      setFieldValue("selectedColors", [...selectedColors, color]);
    } else {
      alert("You can only select up to 2 colors.");
    }
  };

  console.log("selectedColors", selectedColors, values);
  return (
    <div>
      <Paragraph text20 className="font-medium mb-2">
        Colourimetry
      </Paragraph>
      <Paragraph text16 className="!text-dark-grey mb-3">
        Note: You can select a maximum of two colors.
      </Paragraph>
      <ul className="flex flex-col gap-3 mb-4">
        {colorOptions?.map((color, index) => {
          return (
            <li key={color.id}>
              <Checkbox
                w18
                name={"colourimetry"}
                id={`color-${color.id}`}
                value={color.id.toString()}
                checked={selectedColors.some(
                  (selected) => selected.id === color.id
                )}
                onChange={() => handleCheckboxChange(color)}
                disabled={
                  selectedColors.length >= 2 &&
                  !selectedColors.some((selected) => selected.id === color.id)
                }
              >
                {" "}
                <div className="flex items-center gap-2">
                  <span
                    className="w-5 h-5 rounded-full block"
                    style={{ background: color.hexcode }}
                  ></span>
                  {color?.name}
                </div>
              </Checkbox>
            </li>
          );
        })}
      </ul>
      <div className="float-right gap-3 mt-30">
        <Button onClick={onClose} borderButton className="mb-2">
          Cancel
        </Button>
        {/* <Button primary>Select Color</Button> */}
      </div>
    </div>
  );
}

export default ColorPickerModal;
