import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import FormLabel from "../../../../components/core/typography/FormLabel";
import InputType from "../../../../components/core/form-components/InputType";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import SelectType from "../../../../components/core/form-components/SelectType";
import Button from "../../../../components/core/form-components/Button";
import { useDispatch, useSelector } from "react-redux";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { useNavigate } from "react-router-dom";
import { pathRoute } from "../../../../routes/pathRoute";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { getCountriesByInfo } from "../../../../services/countryService";
import { paymentCardsValidationSchema } from "../../../../validations/website/paymentCardValidationSchema";
import {
  hideLoader,
  showLoader,
} from "../../../../redux/slices/siteLoaderSlice";
import { addCard, saveCard } from "../../../../services/website/accountService";

const AddCard = () => {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const initialValues = {
    cardHolderName: "",
    cardExpiry: "",
    cardNumber: "",
    cardCvc: "",
    countryCode: "",
    countryId: "",
    countryName: "",
    zip: "",
  };
  const [cardErrors, setCardErrors] = useState({
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });
  const [countryList, setCountryList] = useState<
    { label: string; value: string; id: number }[]
  >([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        let queryString = `?sort_by=code&order=asc`;

        const response = await getCountriesByInfo(queryString);
        console.log("dfsfdsfds", response?.data?.countries);

        const formattedData = response?.data?.countries.map(
          (category: any) => ({
            label: category.name,
            value: category.code,
            id: category?.id,
          })
        );
        setCountryList(formattedData || []);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  const handleCancel = () => {
    navigate(pathRoute.website.paymentMethods);
  };

  const handleCard = (change: any, setFieldValue: any, setFieldError: any) => {
    const { error, complete, elementType, empty } = change || {};
    if (error && !complete) {
      setCardErrors((prevErrors) => ({
        ...prevErrors,
        [elementType]: error.message,
      }));
    } else if (empty) {
      let errorMessage = "";

      switch (elementType) {
        case "cardNumber":
          errorMessage = "Card number cannot be empty";
          break;
        case "cardExpiry":
          errorMessage = "Expiry date cannot be empty";
          break;
        case "cardCvc":
          errorMessage = "cannot be empty";
          break;
        default:
          errorMessage = `${elementType} cannot be empty`;
      }
      setCardErrors((prevErrors) => ({
        ...prevErrors,
        [elementType]: errorMessage,
      }));
    } else if (!error && complete) {
      setCardErrors((prevErrors) => ({
        ...prevErrors,
        [elementType]: "",
      }));
      setFieldValue(elementType, "trgdfg");
      setFieldError(elementType, "");
    }
  };

  const onSubmit = async (values: any, actions: any) => {
    console.log("Form Data: ", values);
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardNumberElement);
    if (cardElement) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name: values.cardHolderName,
        },
      });
      if (error) {
        console.error("Error creating payment method: ", error);
        return;
      } else {
        actions.setFieldValue("paymentMethodId", paymentMethod?.id);
        dispatch(showLoader());
        try {
          let data = {
            paymentMethodData: {
              paymentMethodId: paymentMethod?.id,
            },
            paymentMethodType: "card",
            paymentMethod: paymentMethod?.id,
          };
          const response = await addCard(data);
          if (response?.data?.secret) {
            const respSave = await saveCard(data);
            console.log("dfsfsdfdsfdsf", respSave);
            if (respSave?.data?.code === 201) {
              handleCancel();
            }
          }
        } catch (error) {
          console.error("Error during step processing:", error);
        } finally {
          dispatch(hideLoader());
          actions.setTouched({});
          actions.setSubmitting(false);
        }
      }
    }
  };
  return (
    <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
      <div className="grid grid-cols-12 gap-3">
        <div className="lg:col-span-8 col-span-12">
          <Formik
            initialValues={initialValues}
            validationSchema={paymentCardsValidationSchema}
            onSubmit={onSubmit}
          >
            {({
              isSubmitting,
              values,
              setFieldValue,
              setFieldError,
              errors,
            }) => (
              <Form>
                <div className="grid grid-cols-12 gap-4">
                  <div className="md:col-span-6 col-span-12 form-group">
                    <FormLabel>
                      {LanguageTranslation({
                        labelName: "card_number",
                        languageCode: langId || "fr",
                      }) || "Card number"}
                      <span className="text-site-red">*</span>
                    </FormLabel>
                    <div className="border border-medium-grey py-[13px] px-[15px] focus:border-dark-grey rounded-lg appearance-none text-base leading-5 w-full font-normal ">
                      <CardNumberElement
                        onChange={(e) =>
                          handleCard(e, setFieldValue, setFieldError)
                        }
                        id="Card Number"
                      />
                    </div>
                    {cardErrors?.cardNumber ? (
                      <div className="text-site-red text-sm font-medium">
                        {cardErrors.cardNumber}
                      </div>
                    ) : (
                      <div className="text-site-red text-sm font-medium">
                        {errors?.cardNumber}
                      </div>
                    )}
                  </div>
                  <div className="md:col-span-6 col-span-12 form-group">
                    <FormLabel>
                      {LanguageTranslation({
                        labelName: "card_holder",
                        languageCode: langId || "fr",
                      }) || "Card holder"}
                      <span className="text-site-red">*</span>
                    </FormLabel>
                    <InputType
                      name="cardHolderName"
                      placeholder="Enter card holder"
                      value={values.cardHolderName}
                    />
                  </div>
                  <div className="md:col-span-6 col-span-12 form-group">
                    <FormLabel>
                      {LanguageTranslation({
                        labelName: "expiration_date",
                        languageCode: langId || "fr",
                      }) || "Expiration date"}
                      <span className="text-site-red">*</span>
                    </FormLabel>
                    <div className="border border-medium-grey py-[13px] px-[15px] focus:border-dark-grey rounded-lg appearance-none text-base leading-5 w-full font-normal ">
                      <CardExpiryElement
                        onChange={(e) =>
                          handleCard(e, setFieldValue, setFieldError)
                        }
                      />
                    </div>
                    {cardErrors?.cardExpiry ? (
                      <div className="text-site-red text-sm font-medium">
                        {cardErrors.cardExpiry}
                      </div>
                    ) : (
                      <div className="text-site-red text-sm font-medium">
                        {errors?.cardExpiry}
                      </div>
                    )}
                  </div>
                  <div className="md:col-span-6 col-span-12 form-group">
                    <FormLabel>
                      {LanguageTranslation({
                        labelName: "cvv",
                        languageCode: langId || "fr",
                      }) || "CVV"}
                      <span className="text-site-red">*</span>
                    </FormLabel>
                    <div className="border border-medium-grey py-[13px] px-[15px] focus:border-dark-grey rounded-lg appearance-none text-base leading-5 w-full font-normal ">
                      <CardCvcElement
                        onChange={(e) =>
                          handleCard(e, setFieldValue, setFieldError)
                        }
                      />
                    </div>
                    {cardErrors?.cardCvc ? (
                      <div className="text-site-red text-sm font-medium">
                        {cardErrors.cardCvc}
                      </div>
                    ) : (
                      <div className="text-site-red text-sm font-medium">
                        {errors?.cardCvc}
                      </div>
                    )}
                  </div>
                  <div className="md:col-span-6 col-span-12 form-group">
                    <FormLabel>
                      {LanguageTranslation({
                        labelName: "country",
                        languageCode: langId || "fr",
                      }) || "Country"}
                      <span className="text-site-red">*</span>
                    </FormLabel>
                    <SelectType
                      options={countryList}
                      fullWidth
                      isSearchable
                      placeholder={"Select"}
                      onChange={(option) => {
                        setFieldValue("countryCode", option?.value);
                        setFieldValue("countryId", option?.id);
                        setFieldValue("countryName", option?.label);
                      }}
                      value={
                        countryList?.find(
                          (option) => option?.value === values?.countryCode
                        ) || ""
                      }
                    ></SelectType>
                  </div>
                  <div className="md:col-span-6 col-span-12 form-group">
                    <FormLabel>
                      {LanguageTranslation({
                        labelName: "postal_code",
                        languageCode: langId || "fr",
                      }) || "Postal code"}
                      <span className="text-site-red">*</span>
                    </FormLabel>
                    <InputType
                      name="zip"
                      type="number"
                      value={values.zip}
                      placeholder="Enter postal code"
                    />
                  </div>
                </div>
                <div className="flex items-center gap-4 md:mt-30 mt-5">
                  <Button borderButton onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button primary type="submit">
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddCard;
