import * as Yup from "yup";

export const paymentCardsValidationSchema = Yup.object({
  cardHolderName: Yup.string().trim().required("Cardholder name is required"),
  cardNumber: Yup.string().trim().required("Card number is required"),
  cardExpiry: Yup.string().trim().required("Card expiration date is required"),
  cardCvc: Yup.string().trim().required("Card cvc is required"),
  zip: Yup.string()
    .matches(/^\d+$/, "Postcode must be a number")
    .required("Postcode is required"),
});
