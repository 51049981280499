import React, { useState } from "react";
import Paragraph from "../../../../components/core/typography/Paragraph";
import FormLabel from "../../../../components/core/typography/FormLabel";
import TextArea from "../../../../components/core/form-components/TextArea";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import { useSelector } from "react-redux";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { Form, Formik } from "formik";
import Checkbox from "../../../../components/core/form-components/Checkbox";
import Button from "../../../../components/core/form-components/Button";
import Modal from "../../../../components/core/Modal";
import DeleteAccountPopup from "./DeleteAccountPopup";

interface FormValues {
  otp: string;
}

function DeleteAccount() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSubmit = (values: FormValues) => {
    console.log("Form Data: ", values);
  };
  return (
    <div>
      <Paragraph text24 className="font-semibold mb-6">
        Delete Account
      </Paragraph>
      <div className="grid-cols-12 grid gap-6">
        <div className="xxl:col-span-8 xl:col-span-10 col-span-12">
          <div className="border border-medium-grey rounded-2xl lg:p-30 md:p-6 sm:p-5 p-4">
            <Paragraph text20 className="lg:mb-4 mb-3">
              Help us do better
            </Paragraph>
            <Paragraph text16 className="!text-dark-grey lg:mb-4 mb-3">
              Let us know why you want to leave.
            </Paragraph>
            <Formik initialValues={{ otp: "" }} onSubmit={onSubmit}>
              {({ isSubmitting }) => (
                <Form>
                  <div className="col-span-12 mb-4">
                    <FormLabel>
                      {LanguageTranslation({
                        labelName: "description",
                        languageCode: langId || "fr",
                      }) || "Description"}
                    </FormLabel>
                    <TextArea
                      name="description"
                      rows={3}
                      placeholder="What do you want to share?"
                    />
                  </div>
                  <div className="col-span-12">
                    <Checkbox
                      w18
                      name="myCheckbox"
                      id="checkbox1"
                      labelText="Accept Terms & Conditions"
                      className="!text-dark-grey"
                    >
                      I confirm that all my transactions have been finalised and
                      that I currently do not have any active listings
                    </Checkbox>
                  </div>
                </Form>
              )}
            </Formik>
            <div className="flex justify-end gap-4 mt-30">
              <Button primary onClick={() => setIsModalOpen(true)}>
                Delete
              </Button>
              <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                header
                width="max-w-md"
              >
                <DeleteAccountPopup />
              </Modal>
              <Button borderButton>Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteAccount;
