export const pathRoute = {
  web: {
    profile: "/user",
    artDetail: "/art-detail",
    checkout: "/checkout",
    commentDetail: "/comment-details",
  },
  auth: {
    login: "/login",
    register: "/register",
    profileSetup: "/profile-setup",
    addNewPassword: "/add-new-password",
    forgotPassword: "/forget-password",
    otp: "/otp",
    changePassword: "/change-password",
    resetPassword: "/reset-password",
    social: "/",
  },
  website: {
    dashboard: "/dashboard",
    createPost: "/create-post",
    opportunities: "/opportunities",
    opportunityDetail: "/opportunity-detail",
    artDetail: "/art-details",
    masterClass: "/masterclass",
    masterClassDetail: "/masterclass-detail",
    userProfile: "/user-profile",
    myProfile: "/my-profile",
    myAccount: "/my-account",
    settings: "/my-account/settings",
    stripe: "/my-account/stripe",
    myPurchase: "/my-account/my-purchase",
    purchaseDetail: "/my-account/purchase-detail",
    mySales: "/my-account/my-sales",
    saleDetail: "/my-account/sales-detail",
    myMasterclass: "/my-account/my-masterclass",
    myTransactions: "/my-account/my-transactions",
    paymentMethods: "/my-account/payment-methods",
    createEvent: "/create-event",
    searchList: "/search-list",
    piece: "/piece",
    people: "/people",
    notifications: "/notifications",
    checkout: "/web-checkout",
    editProfile: "/edit-profile",
    editPost: "/edit-post",
    relatedMasterClassDetail: "/masterclass-detail/related-class",
    stripeConnect: "/my-account/stripe-connect",
    addCard: "/my-account/add-card",
    eventDetail: "/event-detail",
    masterClassPlans: "/masterclass-plans",
    deleteAccount: "/my-account/delete-account",
  },
};
