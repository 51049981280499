import { API } from "../../apiEndPoints/website/apiEndPoints";
import {
  axiosDelete,
  axiosGet,
  axiosPatch,
  axiosPost,
} from "../../axios/axiosMiddleware";

export const getWebFeedById = (id: number, queryString: String) => {
  return axiosGet(
    `${API?.OTHER?.GET_ARTROOM_INFO}/${id}/publications${queryString}`
  );
};

export const deleteFeedById = (id: number) => {
  return axiosDelete(`${API?.OTHER?.DELETE_FEED}/${id}`);
};

export const likeFeedByid = (id: number) => {
  return axiosPost(`${API?.OTHER?.DELETE_FEED}/${id}/likes`, {});
};

export const getFeedCommentsById = (id: number, query: any) => {
  return axiosGet(`${API?.OTHER?.DELETE_FEED}/${id}/comments/${query}`);
};

export const getFeedResponseCommentsById = (id: number, query: any) => {
  return axiosGet(`${API?.OTHER?.FEED_RESPONSES}/${id}/responses/${query}`);
};

export const likeFeedInCommentSection = (id: number) => {
  return axiosPost(`${API?.OTHER?.FEED_RESPONSES}/${id}/likes`, {});
};

export const likeFeedInReplyCommentSection = (id: number) => {
  return axiosPost(`${API?.OTHER?.FEED_RESPONSES}/responses/${id}/likes`, {});
};

export const addComments = (data: any, id: number) => {
  return axiosPost(`${API?.OTHER?.ADD_COMMENTS}/${id}/comments`, data);
};
export const addReplyComments = (data: any, id: number) => {
  return axiosPost(`${API?.OTHER?.ADD_COMMENTS}/${id}/responses`, data);
};

export const getArtDetails = (id: any) => {
  return axiosGet(`${API?.OTHER?.GET_ARTROOM_DETAILS}/${id}`);
};

export const getArtTags = (query: string) => {
  return axiosGet(`${API?.OTHER?.TAGS}/${query}`);
};

export const removeRepost = (id: number) => {
  return axiosDelete(`${API?.OTHER?.DELETE_REPOST}/${id}`);
};

export const removeComment = (id: number) => {
  return axiosDelete(`${API?.OTHER?.FEED_RESPONSES}/${id}`);
};

export const removeReplyComment = (id: number) => {
  return axiosDelete(`${API?.OTHER?.FEED_RESPONSES}/responses/${id}`);
};
export const addRepost = (data: any) => {
  return axiosPost(`${API?.OTHER?.ADD_REPOST}`, data);
};

export const editComments = (data: any, id: number) => {
  return axiosPatch(`${API?.OTHER?.FEED_RESPONSES}/${id}`, data);
};
export const editReplyComments = (data: any, id: number) => {
  return axiosPatch(`${API?.OTHER?.FEED_RESPONSES}/responses/${id}`, data);
};
