import * as Yup from "yup";

export default [
  Yup.object({}),
  Yup.object({
    shipping_address: Yup.string()
      .trim()
      .required("L'adresse de livraison est requis"),
    shipping_city: Yup.string()
      .trim()
      .required("La ville d'expédition est requis"),
    shipping_zip: Yup.string()
      .trim()
      .min(5, "Le code postal d'expédition doit contenir au moins 5 caractères")
      .max(
        6,
        "Le code postal d'expédition doit comporter au maximum 6 caractères"
      )
      .required("Le code postal d'expédition est requis"),
    shiiping_country: Yup.string()
      .trim()
      .required("Le pays d'expédition est requis"),
    billing_address: Yup.string()
      .trim()
      .required("L'adresse de facturation est requis"),
    billing_city: Yup.string()
      .trim()
      .required("La ville de facturation est requis"),
    billing_zip: Yup.string()
      .trim()
      .min(
        5,
        "Le code postal de facturation doit contenir au moins 5 caractères"
      )
      .max(
        6,
        "Le code postal de facturation doit comporter au maximum 6 caractères"
      )
      .required("Le code postal de facturation est requis"),
    blilling_country: Yup.string()
      .trim()
      .required("Le pays de facturation est requis"),
  }),
];
