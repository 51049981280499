import React, { useState } from "react";
import { languageIdSelector } from "../../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../../components/language-translation/LanguageTranslation";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import FormLabel from "../../../../../components/core/typography/FormLabel";
import InputType from "../../../../../components/core/form-components/InputType";
import Button from "../../../../../components/core/form-components/Button";
import { resetPasswordValidationSchema } from "../../../../../validations/website/resetPasswordValidationSchema";
import {
  hideLoader,
  showLoader,
} from "../../../../../redux/slices/siteLoaderSlice";
import { changePassword } from "../../../../../services/website/accountService";

interface ConfirmPasswordValues {
  current_password: string;
  new_password: string;
  confirm_password: string;
}

function ChangePassword() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;

  const initialValues: ConfirmPasswordValues = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  const dispatch = useDispatch();

  const onSubmit = async (
    values: ConfirmPasswordValues,
    { resetForm }: any
  ) => {
    dispatch(showLoader());
    const params = {
      oldPassword: values?.current_password,
      newPassword: values?.new_password,
      confirmPassword: values?.confirm_password,
    };

    try {
      const response = await changePassword(params);
      if (response?.data?.code === 200) {
        // Reset the form to initial values
        resetForm();
      }
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="lg:p-30 md:p-4 p-3 !pt-0 grid grid-cols-12">
      <div className="xl:col-span-8 col-span-12">
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Form>
              <div className="form-group lg:mb-6 mb-4">
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "current_password",
                    languageCode: langId || "fr",
                  }) || "Current Password"}
                  <span className="text-site-red">*</span>
                </FormLabel>
                <InputType
                  placeholder="Enter current password"
                  type="password"
                  name="current_password"
                  value={values.current_password}
                />
              </div>
              <div className="form-group lg:mb-6 mb-4">
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "new_password",
                    languageCode: langId || "fr",
                  }) || "New Password"}
                  <span className="text-site-red">*</span>
                </FormLabel>
                <InputType
                  placeholder="Enter new password"
                  type="password"
                  name="new_password"
                  value={values.new_password}
                />
              </div>
              <div className="form-group lg:mb-6 mb-4">
                <FormLabel>
                  Confirm Password<span className="text-site-red">*</span>
                </FormLabel>
                <InputType
                  placeholder="Enter confirm password"
                  type="password"
                  name="confirm_password"
                  value={values.confirm_password}
                />
              </div>
              <div className="flex items-center gap-4">
                <Button primary type="submit">
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;
