import React, { ReactNode, useEffect, useRef, useState } from "react";
import { LuListFilter } from "react-icons/lu";
import Button from "./form-components/Button";
import RadioButton from "./form-components/RadioButton";

interface DropdownProps {
  children: ReactNode;
  className?: string;
  options: { value: string; label: string }[]; // Array of radio options
  dropdownWidth?: string;
  onChange: (value: string) => void; // Triggered when a radio button is selected
}

function FilterDropDown({
  children,
  className,
  options,
  dropdownWidth,
  onChange,
}: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as HTMLDivElement)
    ) {
      closeDropdown();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChange = (value: string) => {
    setSelectedValue(value);
    onChange(value);
    closeDropdown();
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex items-center gap-3">
        {children}
        <Button
          type="button"
          onlyIcon
          primary
          className={`${className ? className : ""} block`}
          onClick={toggleDropdown}
        >
          <LuListFilter size={24} />
        </Button>
      </div>

      {isOpen && (
        <div
          className={`origin-top-right pt-2 pb-0 absolute right-0 mt-2 rounded-[6px] shadow-dropdownShadow bg-white z-10 ${
            dropdownWidth ? dropdownWidth : "w-full"
          }`}
        >
          <div className="flex flex-col gap-5 px-2 py-4">
            {options.map((option) => (
              <RadioButton
                key={option.value}
                id={option.value}
                name="dropdown-radio"
                value={option.value}
                checked={selectedValue === option.value}
                onChange={() => handleChange(option.value)}
                labelClass="!text-[16px] !leading-[15px] font-normal"
              >
                {option.label}
              </RadioButton>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default FilterDropDown;
