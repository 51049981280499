import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/black_logo.svg";
import Favicon from "../../assets/images/artgapi_logo.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { pathRoute } from "../../routes/pathRoute";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { languageIdSelector } from "../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../components/language-translation/LanguageTranslation";
import { setToken, setUser, tokenSelector } from "../../redux/slices/userSlice";
import { LanguageSettings } from "../../services/website/languageService";
import { ReactComponent as IconWebSettings } from "../../assets/images/icon_websettings.svg";
import { ReactComponent as IconProfile } from "../../assets/images/icon_profile.svg";
import { ReactComponent as IconLogout } from "../../assets/images/icon_logout.svg";
import {
  orderSidebarItems,
  settingsSidebarItems,
  sideBarItems,
} from "../../constant/constant";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import { LogoutApi } from "../../services/website/authService";

interface sidebarProps {
  sideBarOpen: boolean;
  sideBarMobileOpen: boolean;
  handleMobileSidebar: () => void;
}

interface LanguageTranslation {
  languageName: string;
  Language: {
    languageCode: string;
  };
  labelValue: string;
}

interface LabelTranslation {
  languageId: number; // ID of the language
  labelValue: string; // Translated label value
  LabelId: number; // ID of the label this translation belongs to
  Language: {
    id: number; // ID of the language
    languageName: string; // Name of the language (e.g., "French (Français)")
    languageCode: string; // ISO code of the language (e.g., "fr" for French)
  };
}

interface LabelObject {
  id: number;
  labelName: string;
  LabelTranslations: LanguageTranslation[];
}

function Sidebar({
  sideBarOpen,
  sideBarMobileOpen,
  handleMobileSidebar,
}: sidebarProps) {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const token = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("fdsfsdfdsf", location?.pathname);
  // Filter sidebar items: Show items that don't require auth or that do require auth and the token is present
  const visibleSidebarItems = sideBarItems.filter(
    (item) => !item.requireAuth || (item.requireAuth && token)
  );

  const accountSidebarItems = [...orderSidebarItems, ...settingsSidebarItems];
  const visibleAccountSidebarItems = accountSidebarItems.filter(
    (item) => !item.requireAuth || (item.requireAuth && token)
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  function mergeTranslations(
    oldTranslations: any,
    newTranslations: LabelTranslation[]
  ): LabelTranslation[] {
    const translationMap = new Map<number, LabelTranslation>();

    // Add or update existing translations
    oldTranslations.forEach((translation: any) =>
      translationMap.set(translation.languageId, translation)
    );

    newTranslations.forEach((translation) =>
      translationMap.set(translation.languageId, { ...translation })
    );

    return Array.from(translationMap.values());
  }

  useEffect(() => {
    const fetchLanguageSettings = async () => {
      const currentDate = localStorage.getItem("currentDate");

      try {
        if (!currentDate) {
          // Fetch initial data if no currentDate is set
          const response = await LanguageSettings("");
          console.log("Initial response:", response?.data);
          if (response?.data?.success) {
            localStorage.setItem(
              "currentDate",
              new Date().toISOString().split("T")[0]
            );
            localStorage.setItem(
              "labels",
              JSON.stringify(response?.data?.label)
            );
          }
        } else {
          // Fetch updated data based on currentDate
          const response = await LanguageSettings(currentDate);
          if (response?.data?.success) {
            // Parse old data from localStorage
            const storedDataRaw = localStorage.getItem("labels");
            const oldLabels: LabelObject[] = storedDataRaw
              ? JSON.parse(storedDataRaw)
              : []; // Default to an empty array if null
            const newLabels = response?.data?.label || [];
            const updatedLabels = [...oldLabels];
            newLabels.forEach((newLabel: any) => {
              const existingLabelIndex = updatedLabels?.findIndex(
                (oldLabel) => oldLabel.id === newLabel.id
              );
              const transformedTranslations = newLabel?.LabelTranslations.map(
                (translation: any) => ({
                  languageId: translation.languageId,
                  labelValue: translation.labelValue,
                  LabelId: newLabel.id,
                  Language: translation.Language,
                })
              );

              if (existingLabelIndex !== -1) {
                // Update existing label
                updatedLabels[existingLabelIndex] = {
                  ...updatedLabels[existingLabelIndex],
                  ...newLabel, // Merge newLabel data
                  LabelTranslations: mergeTranslations(
                    updatedLabels[existingLabelIndex]?.LabelTranslations,
                    transformedTranslations // Use transformed translations here
                  ),
                };
              } else {
                // Add new label
                updatedLabels.push(newLabel);
              }
            });
            localStorage.setItem("labels", JSON.stringify(updatedLabels));
            console.log("updatedLabels", updatedLabels);
          }
        }
      } catch (error) {
        console.error("Failed to fetch language settings:", error);
      }
    };

    fetchLanguageSettings();
  }, [dispatch]);

  const handleLogoutClick = async () => {
    dispatch(showLoader());
    const response = await LogoutApi();

    dispatch(setToken(null));
    dispatch(setUser(null));
    navigate(pathRoute.auth.login);

    dispatch(hideLoader());
  };

  return (
    <div
      className={`${
        windowWidth < 992 && "fixed top-0 z-50 bg-white w-[254px] p-4"
      } ${
        sideBarMobileOpen ? "left-0" : "-left-full"
      } duration-500 transition-all ease-in-out`}
    >
      <div className="text-center flex items-center lg:justify-center justify-between">
        <img
          src={sideBarOpen ? Favicon : Logo}
          alt="logo"
          title="ArtGapi"
          width={sideBarOpen ? "auto" : 122}
          height={sideBarOpen ? 50 : 50}
          className="inline-block transition-all ease-in-out"
        />
        {windowWidth < 992 && (
          <button
            className="lg:hidden flex items-center justify-center z-30 relative w-8 h-8 text-white focus:outline-none"
            onClick={() => {
              handleMobileSidebar();
              setNavbarOpen(!navbarOpen);
            }}
          >
            <span
              className={`absolute h-0.5 w-5 bg-site-black transform transition duration-300 ease-in-out ${
                sideBarMobileOpen ? "rotate-45 delay-200" : "-translate-y-1.5"
              }`}
            ></span>
            <span
              className={`absolute h-0.5 bg-site-black transform transition-all duration-200 ease-in-out ${
                sideBarMobileOpen
                  ? "w-0 opacity-50"
                  : "w-5 delay-200 opacity-100"
              }`}
            ></span>
            <span
              className={`absolute h-0.5 w-5 bg-site-black transform transition duration-300 ease-in-out ${
                sideBarMobileOpen ? "-rotate-45 delay-200" : "translate-y-1.5"
              }`}
            ></span>
          </button>
        )}
      </div>
      <nav className={`web-nav-items ${sideBarOpen && "text-center"} mt-30`}>
        <ul className="lg:h-[calc(100vh-170px)] h-[calc(100vh-162px)] overflow-y-auto">
          {visibleSidebarItems?.map((item: any) => (
            <li
              className={`transition-all ease-in-out delay-700 duration-500 ${
                !sideBarMobileOpen && sideBarOpen ? "inline-block" : "block"
              }`}
            >
              <NavLink
                to={item?.href}
                className={({ isActive }) =>
                  `flex items-center ${
                    !sideBarMobileOpen && sideBarOpen
                      ? "w-10 h-10 justify-center"
                      : "gap-[10px] py-[6px] px-4"
                  }  mb-3 rounded-[20px]  transition-all ease-in-out duration-500 ${
                    isActive
                      ? "bg-site-black hover:bg-site-black bg-icon-color"
                      : "bg-white lg:hover:bg-site-yellow"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <item.icon />
                    {!sideBarOpen && (
                      <span
                        className={`text-[18px] leading-[17px] font-normal ${
                          isActive ? "text-white" : "text-site-black"
                        }`}
                      >
                        {item.name}
                      </span>
                    )}
                  </>
                )}
              </NavLink>
            </li>
          ))}

          <li
            className={`transition-all ease-in-out delay-700 duration-500 ${
              !sideBarMobileOpen && sideBarOpen ? "inline-block" : "block"
            }`}
          >
            {windowWidth > 991 ? (
              <NavLink
                to={pathRoute?.website?.settings}
                className={({ isActive }) =>
                  `flex items-center ${
                    !sideBarMobileOpen && sideBarOpen
                      ? "w-10 h-10 justify-center"
                      : "gap-[10px] py-[6px] px-4"
                  }  mb-3 rounded-[20px]  transition-all ease-in-out duration-500 ${
                    isActive
                      ? "bg-site-black hover:bg-site-black bg-icon-color"
                      : "bg-white lg:hover:bg-site-yellow"
                  }`
                }
              >
                {({ isActive }) => (
                  <>
                    <IconWebSettings />
                    {!sideBarOpen && (
                      <span
                        className={`text-[18px] leading-[17px] font-normal ${
                          isActive ? "text-white" : "text-site-black"
                        }`}
                      >
                        Account
                      </span>
                    )}
                  </>
                )}
              </NavLink>
            ) : (
              <>
                <button
                  onClick={toggleDropdown}
                  className={`flex items-center w-full mb-3 gap-[10px] py-[6px] px-4 rounded-[20px] transition-all ease-in-out duration-500 ${
                    dropdownOpen
                      ? "bg-site-black hover:bg-site-black bg-icon-color"
                      : "bg-white lg:hover:bg-site-yellow"
                  }`}
                >
                  <IconProfile />
                  <span
                    className={`text-[18px] leading-[17px] font-normal ${
                      dropdownOpen ? "text-white" : "text-site-black"
                    }`}
                  >
                    Account
                  </span>
                  <IoIosArrowDown
                    size={16}
                    color={dropdownOpen ? "#fff" : "#121111"}
                    className={`ml-auto transition-transform ${
                      dropdownOpen ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </button>

                <div
                  className={`${
                    dropdownOpen ? "h-[336px]" : "h-0"
                  } overflow-hidden transition-all ease-in-out duration-700`}
                >
                  <ul className="pl-6 pt-2">
                    {visibleAccountSidebarItems?.map((item: any) => (
                      <li
                        className={`transition-all ease-in-out delay-700 duration-500 block`}
                      >
                        <NavLink
                          to={item?.href}
                          className={({ isActive }) =>
                            `flex items-center gap-[10px] py-[6px] px-4 mb-3 rounded-[20px] transition-all ease-in-out duration-500 ${
                              isActive
                                ? "bg-light-grey hover:bg-light-grey"
                                : "bg-white hover:bg-light-grey"
                            }`
                          }
                        >
                          {({ isActive }) => (
                            <>
                              <item.icon />
                              <span
                                className={`text-[18px] leading-[17px] font-normal text-site-black`}
                              >
                                {LanguageTranslation({
                                  labelName: item.labelName,
                                  languageCode: langId || "fr",
                                }) || item.name}
                              </span>
                            </>
                          )}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </li>
        </ul>
        {token && (
          <div
            className={`${
              !sideBarMobileOpen && sideBarOpen ? "inline-block" : "block"
            } lg:pt-4 pt-2 transition-all ease-in-out delay-700 duration-500 before:h-[1px] before:w-full before:bg-medium-grey before:absolute before:left-0 lg:before:bottom-[73px] before:bottom-[66px]`}
          >
            <NavLink
              to={pathRoute.auth.login}
              onClick={(e) => {
                e.preventDefault(); // Prevent default navigation
                handleLogoutClick(); // Call your logout function
              }}
              className={({ isActive }) =>
                `flex items-center ${
                  !sideBarMobileOpen && sideBarOpen
                    ? "w-10 h-10 justify-center"
                    : "gap-[10px] py-[6px] px-4"
                }  lg:mb-4 mb-0 rounded-[20px]  transition-all ease-in-out duration-500 ${
                  isActive
                    ? "bg-site-black hover:bg-site-black"
                    : "bg-white lg:hover:bg-site-yellow"
                }`
              }
            >
              {({ isActive }) => (
                <>
                  <IconLogout />
                  {!sideBarOpen && (
                    <span
                      className={`text-[18px] leading-[17px] font-normal ${
                        isActive ? "text-white" : "text-site-black"
                      }`}
                    >
                      Logout
                    </span>
                  )}
                </>
              )}
            </NavLink>
          </div>
        )}
      </nav>
    </div>
  );
}

export default Sidebar;
