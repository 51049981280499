import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import FormLabel from "../../../components/core/typography/FormLabel";
import TextArea from "../../../components/core/form-components/TextArea";
import RadioButton from "../../../components/core/form-components/RadioButton";
import UploadFile from "../../../assets/images/icon_image_upload.svg";
import Button from "../../../components/core/form-components/Button";
import Paragraph from "../../../components/core/typography/Paragraph";
import SwitchToggle from "../../../components/core/form-components/SwitchToggle";
import InputType from "../../../components/core/form-components/InputType";
import SelectType from "../../../components/core/form-components/SelectType";
import Modal from "../../../components/core/Modal";
import UploadFileModal from "./UploadFileModal";
import GuideModal from "./GuideModal";
import ColorWheel from "../../../assets/images/icon_color_wheel.svg";
import ColorPickerModal from "./ColorPickerModal";
import { useDispatch, useSelector } from "react-redux";
import { languageIdSelector } from "../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../components/language-translation/LanguageTranslation";
import DummyImg from "../../../assets/images/art2.png";
import { IoClose } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import {
  getArtDetails,
  getArtTags,
} from "../../../services/website/feedService";
import { commonListSelector } from "../../../redux/slices/website/commonSlice";
import {
  createArtroomPost,
  createPost,
  createPostText,
  createPostVideo,
  editArt,
} from "../../../services/website/piecesService";
import { pathRoute } from "../../../routes/pathRoute";
import { userSelector } from "../../../redux/slices/userSlice";
import { string } from "yup";
import { ProductStatus } from "../../../constant/constant";
import { IoMdPlay } from "react-icons/io";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import { postValidationSchema } from "../../../validations/website/createPostValidationSchema";

interface colorInterface {
  id: number;
  hexcode: string;
  name: string;
}
interface FormValues {
  profileImage?: string;
  description?: string;
  title: string;
  width: number;
  height: number;
  depth: number;
  weight: number;
  price: number;
  quantity: number;
  category: string;
  artTag: {
    id: number;
    name: string;
  };
  mainColor: {
    hexcode: string;
    id: number;
    name: string;
  };
  secondaryColor: {
    hexcode: string;
    id: number;
    name: string;
  };
  status?: string;
  framed?: boolean;
  categoryId?: number;
  type?: number;
  publicationDate?: string;
  video?: any;
  creationYear?: string;
  selectedColors?: colorInterface[];
}

interface Photo {
  file: File; // Specify File type for the file
  photos: string; // URL string for the photo preview
}

function CreatePost() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const [modalOpen, setModalOpen] = useState(false);
  const [guideModal, setGuideModal] = useState(false);
  const [colorPickerModal, setColorPickerModal] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState();
  const allData = useSelector(commonListSelector);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const webProfileData = useSelector(userSelector);
  const [photoUrl, setPhotoUrl] = useState<Photo[]>([]);
  const [allImage, setAllImages] = useState<Photo[]>([]);
  const [isFramed, setIsFramed] = useState(false);
  const [isMedia, setIsMedia] = useState(0);
  const [isFileType, setIsFileType] = useState("");
  const [artoptions, setArtOptions] = useState([]);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [isFeed, setIsFeed] = useState(0);
  const [defaultInitialValues, setDefaultInitialValues] = useState({
    profileImage: "",
    description: "",
    title: "",
    width: 0,
    height: 0,
    depth: 0,
    weight: 0,
    price: 0,
    quantity: 0,
    category: "",
    artTag: {
      id: 0,
      name: "",
    },
    mainColor: {
      id: 0,
      name: "",
      hexcode: "",
    },
    secondaryColor: {
      id: 0,
      name: "",
      hexcode: "",
    },
    status: "",
    framed: false,
    categoryId: 0,
    type: 0,
    publicationDate: "",
    video: "",
    creationYear: "",
  });

  const categoryOptions = allData?.categories.map(
    (category: { id: string; name: string }) => ({
      label: category.name, // The label displayed in the dropdown
      value: category.id, // The value to be sent when an option is selected
    })
  );

  const colorOptions = allData?.colors;
  const getArtTagsFunc = async () => {
    let queryString = `?criteria=${search}&page=${page}`;
    const response = await getArtTags(queryString);
    if (response?.data?.code === 200) {
      const formattedData = response?.data?.tags?.map((category: any) => ({
        label: category.name,
        value: category.id,
      }));
      setArtOptions(formattedData || []);
    }
  };

  useEffect(() => {
    if (search) {
      getArtTagsFunc();
    }
  }, [search]);

  const fetchArtDetails = async () => {
    dispatch(showLoader());

    try {
      const response = await getArtDetails(id);
      setType(response?.data?.art?.type);
      let profileData = response?.data?.art;

      let data = {
        profileImage: profileData.images[0].imageUrl || "",
        title: profileData.title || "",
        description: profileData.description || "",
        width: profileData.width || 0,
        height: profileData.height || 0,
        depth: profileData.depth || 0,
        weight: profileData.weight || 0,
        price: profileData.price || 0,
        quantity: profileData.quantity || 0,
        category: profileData.category.name || "",
        artTag: profileData.creator || "",
        mainColor: profileData.mainColor || { id: 0, name: "", hexcode: "" },
        secondaryColor: profileData.secondaryColor || {
          id: 0,
          name: "",
          hexcode: "",
        },
        status: profileData.status || "",
        framed: profileData?.framed || false,
        categoryId: 0,
        type: profileData?.mediaType || 0,
        publicationDate: profileData?.publicationDate || "",
        video: profileData?.video,
        creationYear: profileData?.creationYear,
      };
      setDefaultInitialValues({ ...data });
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    if (id) {
      fetchArtDetails();
    }
  }, [id]);

  const openLightbox = (videoUrl: string) => {
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const handleEdit = async (values: FormValues) => {
    const params = {
      height: values?.height,
      width: values?.width,
      weight: values?.weight,
      depth: values?.depth,
      price: values?.price,
      description: values?.description,
      title: values?.title,
      quantity: values?.quantity,
      creationYear: values?.creationYear,
      status: values?.status,
      framed: values?.framed,
      type: values?.video ? 0 : 1,
      mainColorId: values?.mainColor?.id,
      secondaryColorId: values?.secondaryColor?.id,
      idCreatorTag: values?.artTag?.id,
      publicationDate: values?.publicationDate,
      id: id,
      mainColor: values?.mainColor?.id,
      secondaryColor: values?.secondaryColor?.id,
      ...(values?.categoryId && values?.categoryId !== 0
        ? { categoryId: values?.categoryId }
        : {}),
    };

    const response = await editArt(params, id);
    if (response?.data?.code === 200) {
      navigate(`${pathRoute.website.artDetail}/${id}`, {
        state: { isWebsite: true, userId: webProfileData?.id },
      });
    }
  };

  const createFeed = async (values: FormValues) => {
    const formData = new FormData();
    if (values?.description || allImage?.length > 0) {
      if (values?.description && allImage?.length === 0) {
        // this is only for text
        let params = {
          type: values?.type,
          text: values?.description,
        };
        const trimmedComment = values?.description?.trim();
        if (!trimmedComment) return;
        dispatch(showLoader());
        try {
          const response = await createPostText(params);
          if (response?.data?.code === 201) {
            navigate(pathRoute.auth.social);
          }
        } catch (error) {
          console.error("Error fetching feed:", error);
        } finally {
          dispatch(hideLoader());
        }
      } else {
        if (isMedia == 1) {
          // this is for images
          if (values?.type == 0) {
            formData.append("type", values?.type.toString()); // Convert number to string
            formData.append("text", values?.description || "");
            allImage?.forEach((learningPoint, index) => {
              formData.append("images", learningPoint.file); // Appending each File object
            });
          }
          dispatch(showLoader());
          try {
            const response = await createPost(formData);
            if (response?.data?.code === 201) {
              navigate(pathRoute.auth.social);
            }
          } catch (error) {
            console.error("Error fetching feed:", error);
          } finally {
            dispatch(hideLoader());
          }
        } else {
          // this is for video
          if (values?.type == 0) {
            formData.append("type", values?.type.toString()); // Convert number to string
            formData.append("text", values?.description || "");
            formData.append("artVideo", allImage?.[0]?.file);
          }
          dispatch(showLoader());
          try {
            const response = await createPostVideo(formData);
            if (response?.data?.code === 201) {
              navigate(pathRoute.auth.social);
            }
          } catch (error) {
            console.error("Error fetching feed:", error);
          } finally {
            dispatch(hideLoader());
          }
        }
      }
    }
  };

  const formatDimensionString = (value: string): string => {
    return value.replace(",", ".");
  };

  const createArrayTypeNode = async (values: FormValues) => {
    const fileType = allImage?.[0]?.file?.type.split("/")[1];
    const formData = new FormData();
    if (values?.type == 1) {
      let art = {
        height: values?.height,
        width: values?.width,
        depth: values?.depth,
        weight: values?.weight,
        creator: "test",
        creationYear: "2024",
        status: ProductStatus.OnSale,
        title: values?.title,
        framed: isFramed,
        categoryId: values?.categoryId,
        mainColorId: values?.mainColor?.id,
        secondaryColorId: values?.secondaryColor?.id,
        tags: JSON.stringify([values?.artTag?.name]),
        quantity: values?.quantity,
        price: values?.price
          ? +formatDimensionString(values?.price.toString())
          : 0,
        mediaType: isMedia,
        description: values?.description,
        artType: -1,
      };

      formData.append("type", values?.type.toString());
      formData.append("title", values?.title);
      formData.append("art", JSON.stringify(art));
      if (fileType?.includes("mp4")) {
        formData.append("artVideo", allImage?.[0]?.file);
      } else {
        formData.append("images", allImage?.[0]?.file);
      }
    }
    dispatch(showLoader());
    try {
      const response = await createArtroomPost(formData);
      if (response?.data?.code === 201) {
        navigate(pathRoute.auth.social);
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const onSubmit = async (values: FormValues) => {
    if (id) {
      handleEdit(values);
    } else {
      // this for feed post
      if (values?.type == 0) {
        createFeed(values);
      } else {
        // this is for artroom
        createArrayTypeNode(values);
      }
    }
  };

  const handleClose = () => {
    setModalOpen(!modalOpen);
  };
  const handleGuideClose = () => {
    setGuideModal(!guideModal);
  };
  const handleColorPickerClose = () => {
    setColorPickerModal(!colorPickerModal);
  };

  const onSearchChange = (data: any) => {
    setSearch(data);
  };

  const handleFeedupload = (
    allowedImageTypes: any,
    allowedVideoType: any,
    isVideoSelected: any,
    setFieldValue: any,
    selectedFiles: any
  ) => {
    if (!isVideoSelected) {
      // Handle image upload

      if (!selectedFiles || selectedFiles.length === 0) {
        return; // Do nothing if no file is selected
      }

      // Check if the number of selected files exceeds the image limit
      if (selectedFiles.length > 5) {
        alert("You can upload a maximum of 5 images.");
        return;
      }

      // Validate image file types
      const invalidFiles = Array.from(selectedFiles).filter(
        (file: any) => !allowedImageTypes.includes(file?.type)
      );

      if (invalidFiles.length > 0) {
        alert("Only JPG and PNG images are allowed.");
        return;
      }

      // Process valid images
      const selectedPhotos = Array.from(selectedFiles);
      const updatedPhotos = selectedPhotos?.map((photo: any) => ({
        file: photo,
        photos: URL.createObjectURL(photo),
      }));

      const logoFile = selectedFiles?.[0];
      const fileType = logoFile?.type.split("/")[1];

      setAllImages(updatedPhotos);
      setFieldValue("profileImage", updatedPhotos?.[0]?.photos);
      setIsFileType(fileType || "");
      setPhotoUrl(updatedPhotos?.slice(1));
      setIsMedia(1); // Set media type as image
    } else {
      // Handle video upload
      if (selectedFiles.length > 1) {
        alert("You can upload only one video at a time.");
        return;
      }

      const videoFile = selectedFiles[0];
      if (videoFile.type !== allowedVideoType) {
        alert("Only MP4 videos are allowed.");
        return;
      }

      // Process video similar to images
      const updatedPhotos = [
        {
          file: videoFile,
          photos: URL.createObjectURL(videoFile),
        },
      ];

      const fileType = videoFile?.type.split("/")[1];

      setAllImages(updatedPhotos); // Store video in the same structure as images
      setFieldValue("profileImage", updatedPhotos[0]?.photos); // Update profile image with video URL
      setIsFileType(fileType || ""); // Set file type (e.g., mp4)
      setPhotoUrl([]); // No additional photos for videos
      setIsMedia(0); // Media type is image-like for compatibility
    }
  };

  const handleArtupload = (
    allowedImageTypes: any,
    allowedVideoType: any,
    isVideoSelected: any,
    setFieldValue: any,
    selectedFiles: any
  ) => {
    if (!isVideoSelected) {
      // Handle image upload

      // Check if the number of selected files exceeds the image limit
      if (selectedFiles.length > 1) {
        alert("You can upload a only 1 image.");
        return;
      }

      // Validate image file types
      const invalidFiles = Array.from(selectedFiles).filter(
        (file: any) => !allowedImageTypes.includes(file?.type)
      );

      if (invalidFiles.length > 0) {
        alert("Only JPG and PNG images are allowed.");
        return;
      }

      // Process valid images
      const selectedPhotos = Array.from(selectedFiles);
      const updatedPhotos = selectedPhotos?.map((photo: any) => ({
        file: photo,
        photos: URL.createObjectURL(photo),
      }));

      const logoFile = selectedFiles?.[0];
      const fileType = logoFile?.type.split("/")[1];

      setAllImages(updatedPhotos);
      setFieldValue("profileImage", updatedPhotos?.[0]?.photos);
      setIsFileType(fileType || "");
      setPhotoUrl([]);
      setIsMedia(1); // Set media type as image
    } else {
      // Handle video upload
      if (selectedFiles.length > 1) {
        alert("You can upload only one video at a time.");
        return;
      }

      const videoFile = selectedFiles[0];
      if (videoFile.type !== allowedVideoType) {
        alert("Only MP4 videos are allowed.");
        return;
      }

      // Process video similar to images
      const updatedPhotos = [
        {
          file: videoFile,
          photos: URL.createObjectURL(videoFile),
        },
      ];

      const fileType = videoFile?.type.split("/")[1];

      setAllImages(updatedPhotos); // Store video in the same structure as images
      setFieldValue("profileImage", updatedPhotos[0]?.photos); // Update profile image with video URL
      setIsFileType(fileType || ""); // Set file type (e.g., mp4)
      setPhotoUrl([]); // No additional photos for videos
      setIsMedia(0); // Media type is image-like for compatibility
    }
  };

  const handleImageUpload = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: any,
    values: any
  ) => {
    const selectedFiles = e.target.files;

    if (!selectedFiles) return; // If no files are selected, exit early.

    const allowedImageTypes = ["image/jpeg", "image/png"]; // Allowed image types
    const allowedVideoType = "video/mp4"; // Allowed video type

    // Check if the selected file(s) include a video or image
    const isVideoSelected = Array.from(selectedFiles).some((file) =>
      file.type.startsWith("video")
    );

    if (values?.type == 0) {
      handleFeedupload(
        allowedImageTypes,
        allowedVideoType,
        isVideoSelected,
        setFieldValue,
        selectedFiles
      );
    } else {
      handleArtupload(
        allowedImageTypes,
        allowedVideoType,
        isVideoSelected,
        setFieldValue,
        selectedFiles
      );
    }
  };

  const handleRemoveImage = (index: number) => {
    setPhotoUrl((prev) => prev.filter((_, i) => i !== index));
  };
  return (
    <>
      <div className="xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3 ">
        <Paragraph text24 className="md:mb-6 sm:mb-5 mb-4">
          {id
            ? LanguageTranslation({
                labelName: "edit_post_new",
                languageCode: langId || "fr",
              }) || "Edit Post"
            : LanguageTranslation({
                labelName: "create_post_new",
                languageCode: langId || "fr",
              }) || "Create New Post"}
        </Paragraph>
        <div className="border border-medium-grey rounded-2xl overflow-hidden">
          <Formik
            initialValues={defaultInitialValues}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={isFeed == 1 && postValidationSchema}
          >
            {({ isSubmitting, values, setFieldValue, errors, setErrors }) => (
              <Form>
                <div className="grid grid-cols-12">
                  <div className="xxl:col-span-3 sm:col-span-4 col-span-12 bg-light-grey xl:p-30 md:p-5 p-3">
                    <FormLabel>Upload image</FormLabel>
                    <label
                      htmlFor="upload-file"
                      className="px-4 py-3.5 md:h-[180px] h-[120px] bg-white rounded-lg border border-dashed border-medium-grey flex flex-col justify-center items-center gap-3 cursor-pointer"
                    >
                      {!id && (
                        <input
                          type="file"
                          name="profileImage"
                          id="upload-file"
                          multiple
                          className="sr-only"
                          accept="image/*,video/*"
                          onChange={(e) =>
                            handleImageUpload(e, setFieldValue, values)
                          }
                        />
                      )}
                      {values.profileImage ? (
                        isFileType?.includes("mp4") ? (
                          <div className="block rounded-[20px] overflow-hidden relative w-auto">
                            <video
                              id={`image-video`}
                              className="h-auto rounded-[20px] bg-light-grey w-full"
                              src={values.profileImage}
                            ></video>

                            <button
                              aria-label="Play video"
                              className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center bg-gradient-to-r from-site-black to-site-black/0 backdrop-blur-md"
                            >
                              <IoMdPlay
                                color="#fff"
                                size={22}
                                onClick={() =>
                                  openLightbox(values?.profileImage || "")
                                }
                              />
                            </button>
                          </div>
                        ) : (
                          <img
                            src={values.profileImage}
                            alt="profileImage"
                            className="w-full h-full object-cover"
                          />
                        )
                      ) : (
                        <img
                          src={UploadFile}
                          alt="upload-file"
                          width={30}
                          height={30}
                        />
                      )}

                      {!id && (
                        <span className="text-sm leading-3 text-dark-grey">
                          Upload file
                        </span>
                      )}
                    </label>
                    {values?.type == 0 && photoUrl?.length > 0 ? (
                      <div className="flex flex-wrap items-stretch gap-3 mt-4">
                        {photoUrl?.map((item: Photo, index: number) => {
                          return (
                            <div className="relative sm:w-[calc(50%-6px)] w-[calc(25%-9px)]">
                              <img
                                src={item?.photos}
                                alt={`img-photo${index + 1}`}
                                className="object-cover rounded-[4px] aspect-[4/3]"
                              />
                              <span
                                onClick={() => handleRemoveImage(index)}
                                className="absolute -top-1.5 -right-1.5 sm:w-6 sm:h-6 w-5 h-5 flex items-center justify-center rounded-full bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md text-white cursor-pointer"
                              >
                                <IoClose />
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="xxl:col-span-9 sm:col-span-8 col-span-12 xl:p-30 md:p-5 p-3">
                    <div className="grid grid-cols-12 lg:gap-6 md:gap-5 gap-3">
                      <div className="col-span-12">
                        <FormLabel>Description</FormLabel>
                        <TextArea
                          name="description"
                          rows={5}
                          placeholder="What do you want to share?"
                          // value={values.description}
                        />
                      </div>
                      {!id && (
                        <>
                          <div className="col-span-12">
                            <Paragraph text16 className="mb-3">
                              Would you like to sell your product?
                            </Paragraph>
                            <div className="flex items-center gap-[30px]">
                              <RadioButton
                                id="1"
                                name="type"
                                labelClass="!text-base"
                                onChange={() => {
                                  if (webProfileData?.hasAccount) {
                                    setFieldValue("type", 1);
                                    setIsFeed(1);
                                  } else {
                                    setGuideModal(true);
                                  }
                                }}
                                checked={values?.type == 1 ? true : false}
                              >
                                Yes
                              </RadioButton>
                              <RadioButton
                                id="0"
                                name="type"
                                labelClass="!text-base"
                                defaultChecked
                                onChange={() => {
                                  setFieldValue("type", 0);
                                  setIsFeed(0);
                                }}
                                checked={values?.type == 0 ? true : false}
                              >
                                No
                              </RadioButton>
                            </div>
                          </div>
                          {values?.type == 1 && (
                            <div className="col-span-12">
                              <div className="flex items-center gap-[30px]">
                                <Paragraph text16>Framed artwork</Paragraph>
                                <SwitchToggle
                                  onChange={(value) => setIsFramed(value)}
                                  isChecked={isFramed}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      {values?.type == 1 && (
                        <>
                          <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                            <FormLabel>
                              Title<span className="text-site-red">*</span>
                            </FormLabel>
                            <InputType
                              name="title"
                              type="text"
                              value={values.title}
                              placeholder="Enter title"
                            />
                          </div>
                          <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                            <FormLabel>
                              Height<span className="text-site-red">*</span>{" "}
                              <span className="text-[#a0a0a0]">(cm)</span>
                            </FormLabel>
                            <InputType
                              name="height"
                              type="number"
                              placeholder="Enter"
                              value={values.height}
                            />
                          </div>
                          <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                            <FormLabel>
                              Width<span className="text-site-red">*</span>{" "}
                              <span className="text-[#a0a0a0]">(cm)</span>
                            </FormLabel>
                            <InputType
                              name="width"
                              type="number"
                              placeholder="Enter"
                              value={values.width}
                            />
                          </div>
                          <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                            <FormLabel>
                              Weight<span className="text-site-red">*</span>{" "}
                              <span className="text-[#a0a0a0]">(kg)</span>
                            </FormLabel>
                            <InputType
                              name="weight"
                              type="number"
                              placeholder="Enter"
                              value={values.weight}
                            />
                          </div>
                          <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                            <FormLabel>
                              Depth<span className="text-site-red">*</span>{" "}
                              <span className="text-[#a0a0a0]">(kg)</span>
                            </FormLabel>
                            <InputType
                              name="depth"
                              type="number"
                              placeholder="Enter"
                              value={values.depth}
                            />
                          </div>
                          <div className="xl:col-span-4 lg:col-span-6  sm:col-span-6 col-span-12">
                            <FormLabel>
                              Price<span className="text-site-red">*</span>
                            </FormLabel>
                            <InputType
                              name="price"
                              type="number"
                              placeholder="Enter price"
                              value={values.price}
                              disabled={id ? true : false}
                            />
                          </div>
                          <div className="xl:col-span-4 lg:col-span-6 sm:col-span-6 col-span-12">
                            <FormLabel>
                              Exemplar<span className="text-site-red">*</span>
                            </FormLabel>
                            <InputType
                              name="quantity"
                              type="number"
                              placeholder="Enter quantity"
                              disabled={id ? true : false}
                              value={values.quantity}
                            />
                          </div>
                          <div className="xl:col-span-4 lg:col-span-6 sm:col-span-6 col-span-12">
                            <FormLabel>
                              Category<span className="text-site-red">*</span>
                            </FormLabel>
                            <SelectType
                              options={categoryOptions}
                              placeholder="Select category"
                              fullWidth
                              onChange={(selectedOption: {
                                value: string;
                                label: string;
                              }) => {
                                setFieldValue(
                                  "category",
                                  selectedOption?.label
                                );
                                setFieldValue(
                                  "categoryId",
                                  selectedOption?.value
                                );
                                setErrors({
                                  category: "",
                                });
                              }}
                              value={
                                categoryOptions?.find(
                                  (option: any) =>
                                    option?.label === values.category
                                ) || ""
                              }
                            />
                            {errors?.category && (
                              <div className="text-site-red text-sm font-medium mb-2">
                                {errors?.category}
                              </div>
                            )}
                          </div>
                          <div className="xl:col-span-4 lg:col-span-6 sm:col-span-6 col-span-12">
                            <FormLabel>Artag</FormLabel>
                            <SelectType
                              onChange={(selectedOption: {
                                value: string;
                                label: string;
                              }) => {
                                setFieldValue("artTag", {
                                  id: selectedOption?.value,
                                  name: selectedOption?.label,
                                });
                              }}
                              options={artoptions}
                              placeholder="Select artag"
                              fullWidth
                              isSearchable
                              onSearchChange={onSearchChange}
                              value={
                                values?.artTag
                                  ? {
                                      value: values?.artTag?.id,
                                      label: values?.artTag?.name,
                                    }
                                  : null
                              }
                            />
                            {errors?.artTag?.name && (
                              <div className="text-site-red text-sm font-medium mb-2">
                                {errors?.artTag?.name}
                              </div>
                            )}
                          </div>

                          {console.log("errors", errors)}
                          <div className="sm:col-span-6 col-span-12">
                            <FormLabel>Colourimetry</FormLabel>
                            <div className="flex align-items-center gap-2.5">
                              <span
                                onClick={() => setColorPickerModal(true)}
                                className="flex items-center justify-center rounded-full bg-site-black w-10 h-10 cursor-pointer"
                              >
                                <img
                                  src={ColorWheel}
                                  alt="color-wheel"
                                  width={26}
                                  height={26}
                                />
                              </span>
                              {values?.secondaryColor?.hexcode && (
                                <span
                                  className="flex items-center justify-center rounded-full w-10 h-10 cursor-pointer"
                                  style={{
                                    background: `${values?.secondaryColor?.hexcode}`,
                                  }}
                                ></span>
                              )}
                              {values?.mainColor?.hexcode && (
                                <span
                                  className="flex items-center justify-center rounded-full  w-10 h-10 cursor-pointer"
                                  style={{
                                    background: `${values?.mainColor?.hexcode}`,
                                  }}
                                ></span>
                              )}
                            </div>
                            {(errors?.mainColor || errors?.secondaryColor) && (
                              <div className="text-site-red text-sm font-medium mb-2">
                                {"Color is required"}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      <div className="col-span-12 mt-1.5">
                        <div className="flex items-center xl:justify-end justify-start gap-4">
                          <Button
                            borderButton
                            className="lg:w-auto w-full"
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </Button>
                          {id ? (
                            <Button
                              primary
                              type="submit"
                              className="lg:w-auto w-full"
                            >
                              Edit
                            </Button>
                          ) : (
                            <Button
                              primary
                              type="submit"
                              className="lg:w-auto w-full"
                              disabled={
                                values?.type == 0 &&
                                !values?.description &&
                                allImage?.length === 0
                              }
                            >
                              Post
                            </Button>
                          )}
                        </div>
                        {/* <Button
                    onClick={() => setGuideModal(true)}
                    primary
                    className="min-w-[240px] sm:w-auto w-full"
                  >
                    Save
                  </Button> */}
                        <Modal
                          open={guideModal}
                          onClose={handleGuideClose}
                          header
                          width="w-[650px] lg:h-[450px] sm:h-[380px] h-auto"
                        >
                          <GuideModal
                            open={guideModal}
                            onClose={handleGuideClose}
                          />
                        </Modal>
                        <Modal
                          open={colorPickerModal}
                          onClose={handleColorPickerClose}
                          header
                        >
                          <ColorPickerModal
                            open={colorPickerModal}
                            onClose={handleColorPickerClose}
                            colorOptions={colorOptions}
                            selectedColors={values?.selectedColors || []}
                            id={id}
                          />
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {lightboxOpen && (
        <div className={"lightbox-no-navigation"}>
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
    </>
  );
}

export default CreatePost;
