import React, { useEffect, useState } from "react";
import NoDataFound from "../../../assets/images/no_data_found.svg";
import { useDispatch, useSelector } from "react-redux";
import { FaPlayCircle } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { Lightbox } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";
import { useNavigate } from "react-router-dom";
import { profileSelector } from "../../../redux/slices/profileSlice";
import { ArtRoomInterface } from "../../../interface/Artroom";
import { tokenSelector } from "../../../redux/slices/userSlice";
import { useTranslation } from "react-i18next";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { getFeedById } from "../../../services/profileService";
import { pathRoute } from "../../../routes/pathRoute";
import Paragraph from "../../../components/core/typography/Paragraph";
import Button from "../../../components/core/form-components/Button";
import { ProductStatus } from "../../../constant/constant";

function ArtRoom() {
  const profileData = useSelector(profileSelector);
  const [artRoomData, setArtRoomData] = useState<ArtRoomInterface[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [imageLightboxOpen, setImageLightboxOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const token = useSelector(tokenSelector);
  const { t } = useTranslation();

  const fetchArtRoom = async (page: number) => {
    if (profileData?.id) {
      let queryString = `?type=0&filter=0&page=${page}&limit=10&keyword=`;
      dispatch(showLoader());

      try {
        const response = await getFeedById(profileData.id, queryString);
        const count = response?.data?.total || 0;
        if (response?.data?.publications) {
          setArtRoomData((prevData) => [
            ...prevData,
            ...response.data.publications,
          ]);
          setHasMore(page < Math.ceil(count / itemsPerPage));
        }
      } catch (error) {
        console.error("Error fetching feed:", error);
      } finally {
        dispatch(hideLoader());
      }
    } else {
      console.warn("profileData.id is undefined, skipping API call.");
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fetchArtRoom(currentPage);
  }, [currentPage, profileData?.id]);

  const handleBuyNowClick = (item: any) => {
    // const appLink = "artgapi://app"; // Deep link to open your app
    // const playStoreLink =
    //   "https://play.google.com/store/apps/details?id=com.artgapiapp";
    // const appStoreLink = "https://apps.apple.com/in/app/artgapi/id1607828763"; // Replace with your App Store link

    // const userAgent = navigator.userAgent || navigator.vendor;
    // const isIOS = /iPad|iPhone|iPod/.test(userAgent);
    // const isAndroid = /Android/.test(userAgent);

    // if (isIOS || isAndroid) {
    //   // Attempt to open the app using the deep link
    //   window.location.href = appLink;

    //   // Fallback to App Store or Play Store after a delay
    //   setTimeout(() => {
    //     if (isIOS) {
    //       window.location.href = appStoreLink;
    //     } else if (isAndroid) {
    //       window.location.href = playStoreLink;
    //     }
    //   }, 1500);
    //   // Delay of 1.5 seconds to allow time for the app to open if installed
    // } else {
    //   // For other platforms (e.g., desktop), open the Play Store link in a new tab
    //   window.open(playStoreLink, "_blank");
    // }
    const urlWithToken = `${pathRoute.web.artDetail}/${item?.art?.id}?token=${token}`;
    navigate(urlWithToken);
  };

  const openLightbox = (videoUrl: string) => {
    console.log("Opening lightbox with video URL:", videoUrl);
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const openImgLightbox = (imgUrl: string) => {
    console.log("Opening lightbox with image URL:", imgUrl);
    console.log("Opening lightbox with image URL:", imgUrl);
    setImgUrl(imgUrl);
    setImageLightboxOpen(true);
  };

  const closeImgLightbox = () => {
    setImageLightboxOpen(false);
    setImgUrl(undefined);
  };
  return (
    <>
      <InfiniteScroll
        dataLength={artRoomData.length}
        next={() => {
          if (hasMore) {
            setCurrentPage((prevPage) => prevPage + 1);
          }
        }}
        hasMore={hasMore}
        loader={<h4>Loading ... </h4>}
      >
        <div className="sm:columns-2 lg:gap-6 sm:gap-4 gap-3">
          {artRoomData?.length === 0 ? (
            <div className="break-inside-avoid text-center">
              <img
                src={NoDataFound}
                alt="no-data-found"
                className="mb-4 inline-block"
              />
              <Paragraph text24 className="mb-4 font-semibold">
                {t("profile.sorry")}
              </Paragraph>
              <Paragraph text20 className="font-normal">
                {t("profile.no_data")}
              </Paragraph>
            </div>
          ) : (
            artRoomData?.map((item: ArtRoomInterface) => (
              <div className="break-inside-avoid" key={item.id}>
                <div className="relative pb-6 group">
                  <div className="block relative overflow-hidden rounded-[20px] before:bg-black/25 before:absolute before:w-full before:h-full before:left-0 before:top-0 before:transition-all before:duration-700 before:ease-in-out before:opacity-0 group-hover:before:opacity-100 mb-3">
                    {item?.art?.video ? (
                      <div className="relative">
                        {item?.art?.quantity === 0 ? (
                          <div className="before:absolute before:w-full before:h-full before:bg-site-black/60 before:left-0 before:top-0 before:z-[1]">
                            <img
                              src={item?.art.video?.imageUrl}
                              className="max-h-[390px] w-full rounded-[20px] bg-light-grey cursor-default"
                              alt="video-thumbnail"
                            />
                            <Paragraph
                              text24
                              className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[1] font-normal "
                            >
                              {t("profile.out_of_Stock")}
                            </Paragraph>
                          </div>
                        ) : (
                          <>
                            <img
                              src={item?.art.video?.imageUrl}
                              className="max-h-[390px] w-full rounded-[20px] bg-light-grey cursor-default"
                              alt="video-thumbnail"
                            />

                            <span className="absolute inset-0 flex items-center justify-center ">
                              <FaPlayCircle
                                size={40}
                                className="text-base cursor-pointer"
                                onClick={() =>
                                  openLightbox(item?.art?.video?.videoUrl)
                                }
                              />
                            </span>
                          </>
                        )}
                      </div>
                    ) : item?.art?.quantity !== 0 ? (
                      <img
                        src={
                          item?.art?.images && item?.art?.images[0]?.imageUrl
                        }
                        width={340}
                        height={340}
                        className="cursor-pointer relative w-full"
                        alt="feed-imgg"
                        onClick={() => {
                          const imageUrl =
                            item?.art?.images && item?.art?.images[0]?.imageUrl;
                          console.log("Image URL:", imageUrl); // Debugging line
                          if (imageUrl) {
                            openImgLightbox(imageUrl);
                          }
                        }}
                      />
                    ) : (
                      <div className="before:absolute before:w-full before:h-full before:bg-site-black/60 before:left-0 before:top-0 before:z-[1]">
                        <img
                          src={
                            item?.art?.images && item?.art?.images[0]?.imageUrl
                          }
                          className="cursor-pointer relative w-full gfgf"
                          alt="feed-imgg"
                          onClick={() => {
                            const imageUrl =
                              item?.art?.images &&
                              item?.art?.images[0]?.imageUrl;
                            console.log("Image URL:", imageUrl); // Debugging line
                            if (imageUrl) {
                              openImgLightbox(imageUrl);
                            }
                          }}
                          // width={"340px"}
                          // height={"340px"}
                        />
                        <Paragraph
                          text24
                          className="text-white absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[1] font-normal "
                        >
                          {t("profile.out_of_Stock")}
                        </Paragraph>
                      </div>
                    )}

                    <div
                      className={`p-[10px] absolute w-full bottom-0 opacity-0 transition-all duration-700 group-hover:opacity-100 pointer-events-auto ${
                        item?.art?.quantity === 0 ? "z-[2]" : ""
                      }`}
                    >
                      <Button
                        primary
                        className="w-full"
                        onClick={() => handleBuyNowClick(item)}
                      >
                        {t("profile.view_more")}
                      </Button>
                    </div>
                  </div>
                  <Paragraph
                    text14
                    className="mb-[6px] !text-dark-grey !font-normal"
                  >
                    {item?.art?.status === ProductStatus.OnSale
                      ? t("profile.sale")
                      : item?.art?.status === ProductStatus.Exposed
                      ? t("profile.exhibited")
                      : item?.art?.status}
                  </Paragraph>
                  <Paragraph text22 className="mb-[10px]">
                    {item?.art?.title}
                  </Paragraph>
                  {item?.art?.status === ProductStatus.OnSale && (
                    <Paragraph text18 className="font-normal !leading-[17px]">
                      {item?.art?.price}€
                    </Paragraph>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </InfiniteScroll>
      {lightboxOpen && (
        <div
          className={artRoomData?.length === 1 ? "lightbox-no-navigation" : ""}
        >
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
      {imageLightboxOpen && (
        <div
          className={artRoomData?.length === 1 ? "lightbox-no-navigation" : ""}
        >
          <Lightbox
            open={imageLightboxOpen}
            close={closeImgLightbox}
            slides={[
              {
                type: "image",
                src: imgUrl || "",
              },
            ]}
            carousel={{
              finite: true,
              padding: 0,
            }}
          />
        </div>
      )}
    </>
  );
}

export default ArtRoom;
