import React, { useEffect, useState } from "react";
import NoDataFound from "../../../assets/images/empty_repost.svg";
import Paragraph from "../../../components/core/typography/Paragraph";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../redux/slices/siteLoaderSlice";
import { getWebsiteRepostDetails } from "../../../services/website/profileService";
import { tokenSelector, userSelector } from "../../../redux/slices/userSlice";
import { RepostDetails } from "../../../interface/RepostInterface";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import PostSideProfile from "../../../components/web-component/PostSideProfile";
import { HiUserAdd } from "react-icons/hi";
import { MdBlock } from "react-icons/md";
import IconReport from "../../../assets/images/icon-info.svg";
import PostFeedImage from "../../../components/web-component/PostFeedImage";
import PostZoomInOut from "../../../components/web-component/PostZoomInOut";
import CommentFooter from "../../../components/web-component/CommentFooter";
import { pathRoute } from "../../../routes/pathRoute";
import {
  deleteFeedById,
  likeFeedByid,
  removeRepost,
} from "../../../services/website/feedService";
import {
  blockUser,
  followUsers,
  reportUser,
  unfollowUsers,
} from "../../../services/website/peopleService";
import PostDescription from "../../../components/web-component/PostDescription";
import PostArtImage from "../../../components/web-component/PostArtImage";
import CommentSection from "../../../components/web-component/CommentSection";
import Modal from "../../../components/core/Modal";
import ReportProjectContent from "../../../components/core/ReportProjectContent";
import Button from "../../../components/core/form-components/Button";

function Repost() {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const webProfileData = useSelector(userSelector);
  const [hasMore, setHasMore] = useState(true);
  const [repostData, setRepostData] = useState<RepostDetails[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  const maxCharacters = 150; // Max characters for truncated text
  const { id } = useParams();
  const [isUserId, setIsUserId] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const isLoading = useSelector(siteLoaderSelector);
  const [reportPopup, setReportPopup] = useState(false);
  const [reportId, setReportId] = useState<number | null>(null);
  const [selectId, setSelectId] = useState<number | null>(null);
  const [openCommentPostId, setOpenCommentPostId] = useState<number | null>(
    null
  );
  const token = useSelector(tokenSelector);
  const navigate = useNavigate();

  const profileDropDownItems = [
    { Link: "", LinkName: "Report" },
    {
      Link: "",
      LinkName: "Block",
    },
  ];

  const userDropDownItems = (isFollowedByMe: boolean) => [
    // {
    //   icon: <HiUserAdd size={16} />,
    //   Link: "",
    //   LinkName: isFollowedByMe ? "Following" : "Follow", // Conditional text
    //   handleAction: true,
    // },
    {
      icon: (
        <img src={IconReport} alt="icon-user-delete" width={12} height={12} />
      ),
      Link: "",
      LinkName: "Report",
      handleAction: true,
    },
    {
      icon: <MdBlock />,
      Link: "",
      LinkName: "Block",
      handleAction: true,
    },
  ];

  useEffect(() => {
    if (id) {
      setIsUserId(id);
    } else {
      setIsUserId(webProfileData?.id);
    }
  }, [id]);

  const fetchArtroomForWebsite = async (page: number, id: any) => {
    let queryString = `?page=${page}&limit=10`;
    dispatch(showLoader());

    try {
      const response = await getWebsiteRepostDetails(id, queryString);
      if (response?.data?.code === 200) {
        const count = response?.data?.total || 0;
        if (response?.data?.publications) {
          setRepostData((prevData) => [
            ...prevData,
            ...response.data.publications,
          ]);
          setHasMore(page < Math.ceil(count / itemsPerPage));
        }
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };
  useEffect(() => {
    if (isUserId) {
      fetchArtroomForWebsite(currentPage, isUserId);
    }
  }, [isUserId, currentPage]);

  const backToLogin = () => {
    navigate(pathRoute.auth.login);
  };

  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };

  const handleReportId = (id: number) => {
    setReportId(id);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5,
    mobileFirst: true,
    slidesToScroll: 1,
  };

  const handleDeleteItem = async (id: any) => {
    dispatch(showLoader());
    try {
      const response = await deleteFeedById(id);
      if (response?.data?.code === 201) {
        setRepostData((prevData) => prevData?.filter((item) => item.id !== id));
        setTotalItems((prevTotal) => prevTotal - 1);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleBlock = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await blockUser(item?.userId);
      console.log("Dfsfsdfdsf", response);
      if (response?.data?.code === 201) {
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const closeReportPopup = () => {
    setReportPopup(false);
    setSelectId(null);
  };

  const handleReportApi = async () => {
    dispatch(showLoader());
    try {
      let data = {
        flagId: reportId,
      };
      const response = await reportUser(data, selectId);
      if (response?.data?.code === 201) {
        closeReportPopup();
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleCommentSection = (postId: number) => {
    if (token) {
      setOpenCommentPostId((prevId) => (prevId === postId ? null : postId));
    } else {
      backToLogin();
    }
  };

  const handleLikeOutside = async (id: number) => {
    dispatch(showLoader());
    try {
      if (token) {
        const response = await likeFeedByid(id);

        if (response?.data?.code === 200) {
          setRepostData((prevData) =>
            prevData.map((feedItem) =>
              feedItem.id === id
                ? {
                    ...feedItem,
                    likedByMe: !feedItem.likedByMe,
                    countLikes: feedItem.likedByMe
                      ? feedItem.countLikes - 1
                      : feedItem.countLikes + 1,
                  }
                : feedItem
            )
          );
        }
      } else {
        backToLogin();
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const renderCommentsSection = (postId: number) => {
    if (openCommentPostId === postId) {
      return <CommentSection commentId={postId} />;
    }
    return null;
  };

  const handleRemoveRepost = async (id: number) => {
    dispatch(showLoader());
    try {
      if (token) {
        const response = await removeRepost(id);

        setRepostData((prevData) => prevData?.filter((item) => item.id !== id));
        setTotalItems((prevTotal) => prevTotal - 1);
      } else {
        backToLogin();
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleFollowUser = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await followUsers(item?.userId, {});
      setRepostData((prevData) =>
        prevData.map((feedItem) =>
          feedItem.id === item?.id
            ? {
                ...feedItem,
                followedByMe: !item?.followedByMe, // Increment only if showing the input box
              }
            : feedItem
        )
      );
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleUnFollowUser = async (item: any) => {
    dispatch(showLoader());
    try {
      const response = await unfollowUsers(item?.userId);
      if (response?.data?.code === 200) {
        setRepostData((prevData) =>
          prevData.map((feedItem) =>
            feedItem.id === item?.id
              ? {
                  ...feedItem,
                  followedByMe: !item?.followedByMe, // Increment only if showing the input box
                }
              : feedItem
          )
        );
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleActionsInweb = (type: string, item: any) => {
    switch (type) {
      case "Delete":
        handleDeleteItem(item?.id);
        break;
      case "Follow":
        handleFollowUser(item);
        break;

      case "Following":
        handleUnFollowUser(item);
        break;
      case "Block":
        handleBlock(item);
        break;
      case "Report":
        setSelectId(item?.id);
        setReportPopup(!reportPopup);
        break;
      default:
        console.log("Unknown action type:", type);
        break;
    }
  };
  console.log("repostData", repostData);
  return (
    <>
      {repostData?.length === 0 && !isLoading ? (
        <div className="break-inside-avoid text-center">
          <img
            src={NoDataFound}
            alt="no-data-found"
            className="mb-6 inline-block"
            width={"250px"}
            height={"142px"}
          />
          <Paragraph text18 className="!font-medium">
            No result found
          </Paragraph>
        </div>
      ) : (
        <div
          className={"px-4"}
          id="scrollableDiv"
          style={{ overflowY: "auto", maxHeight: "80vh", height: "100%" }}
        >
          <InfiniteScroll
            dataLength={repostData?.length}
            next={() => setCurrentPage((prevPage) => prevPage + 1)}
            hasMore={repostData?.length < totalItems}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            <ul>
              {repostData?.map((item: RepostDetails, index: number) => {
                const toggleShowMore = () => {
                  setShowMore(!showMore);
                };

                const isLastItem = index === repostData.length - 1;

                return (
                  <>
                    <li
                      className={`relative block pb-6 mb-6 border-b border-medium-grey md:ps-[66px] sm:ps-[40px] ps-5 ${
                        isLastItem ? "mb-0 border-b-0" : ""
                      }`}
                    >
                      <PostSideProfile
                        toggleDropdown={(e) => toggleDropdown(e)}
                        item={item}
                        profileDropDownItems={
                          item?.user?.id == webProfileData?.id
                            ? profileDropDownItems
                            : userDropDownItems(true)
                        }
                        handleActionsInweb={(e: any) =>
                          handleActionsInweb(e, item)
                        }
                      />

                      {/* type 0 is for feed and 1 is for art */}
                      {item?.type === "0" ? (
                        <>
                          <div
                            className={`${
                              item?.images && item?.images?.length > 1
                                ? "relative feed-carousel pb-6 max-w-[490px]"
                                : "relative pb-6 max-w-[490px]"
                            }`}
                          >
                            <PostFeedImage
                              itemData={item}
                              feedData={repostData}
                            />
                            {item?.images?.length === 1 && (
                              <PostZoomInOut
                                images={item?.images[0]?.imageUrl}
                                feedData={repostData}
                              />
                            )}

                            <CommentFooter
                              isRepost={true}
                              isLike={true}
                              isComment={true}
                              id={item?.id || 0}
                              handleCommentSection={handleCommentSection}
                              handleLikeOutside={handleLikeOutside}
                              handleRemoveRepost={handleRemoveRepost}
                              countLikes={item?.countLikes}
                              countComments={item?.countComments}
                              likedByMe={item?.likedByMe}
                              isRepostedByMe={item?.isRepostedByMe}
                            />
                          </div>
                          {(item?.video || item?.images) && (
                            // when there is image or video show text at bottom
                            <PostDescription
                              description={item?.text?.contentText}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          <div className="relative pb-6 max-w-[490px]">
                            <PostArtImage
                              itemData={item}
                              feedData={repostData}
                            />
                            {item?.art?.images?.length === 1 && (
                              <PostZoomInOut
                                images={item.art.images[0]?.imageUrl}
                                feedData={repostData}
                              />
                            )}

                            <CommentFooter
                              isRepost={true}
                              isLike={true}
                              isComment={true}
                              id={item?.id || 0}
                              handleCommentSection={handleCommentSection}
                              handleLikeOutside={handleLikeOutside}
                              handleRemoveRepost={handleRemoveRepost}
                              countLikes={item?.countLikes}
                              countComments={item?.countComments}
                              likedByMe={item?.likedByMe}
                              isRepostedByMe={item?.isRepostedByMe}
                            />
                          </div>
                          {(item?.art?.video || item?.art?.images) && (
                            // when there is image or video show text at bottom
                            <PostDescription
                              description={item?.text?.contentText}
                            />
                          )}
                        </>
                      )}
                      {renderCommentsSection(item.id || 0)}
                    </li>
                  </>
                );
              })}
            </ul>
          </InfiniteScroll>
        </div>
      )}
      <Modal
        open={reportPopup}
        header
        onClose={closeReportPopup}
        width="sm:w-[448px] w-[300px]"
        modalPadding="p-6"
      >
        <ReportProjectContent onSelectionChange={handleReportId} />
        <div className="flex items-center justify-center md:gap-4 gap-3">
          <Button
            borderButton
            className="w-full"
            onClick={() => setReportPopup(false)}
          >
            Cancel
          </Button>
          <Button primary onClick={handleReportApi} className="w-full">
            Send
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default Repost;
