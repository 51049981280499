import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Paragraph from "../../../components/core/typography/Paragraph";
import IconUsers from "../../../assets/images/icon_users.svg";
import { FaCircleCheck } from "react-icons/fa6";
import { pathRoute } from "../../../routes/pathRoute";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoader,
  showLoader,
  siteLoaderSelector,
} from "../../../redux/slices/siteLoaderSlice";
import { getOpportunitiesList } from "../../../services/website/socialService";
import { OpportunityInterface } from "../../../interface/Opportunity";
import ImageWithDefault from "../../../components/web-component/DefaultImage";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../../../assets/images/empty_repost.svg";
import SearchInput from "../../../components/core/form-components/SearchInput";

function Opportunities() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [opporunityList, setOpportunityList] = useState<OpportunityInterface[]>(
    []
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0); // Track total available items
  const isLoading = useSelector(siteLoaderSelector); // Select the loading state from Redux
  const [searchItem, setSearchItem] = useState("");

  const fecthOpportunitiesList = async (page: number) => {
    let queryString = `?page=${page}&limit=10`;
    dispatch(showLoader());

    try {
      const response = await getOpportunitiesList(queryString);
      const count = response?.data?.total || 0;
      if (response?.data?.code === 200) {
        setOpportunityList((prevData) => [
          ...prevData,
          ...response.data.opportunities,
        ]);

        setTotalItems(count); // Update the total number of items
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    fecthOpportunitiesList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      // Reset the list and fetch the first page when search changes
      setOpportunityList([]); // Clear existing data
      setCurrentPage(1); // Reset to the first page
      fecthOpportunitiesList(1); // Fetch the first page
    }, 3000);

    return () => {
      clearTimeout(debounce);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItem]);

  const handleDetailPage = (item: OpportunityInterface) => {
    const routes = `${pathRoute.website.opportunityDetail}/${item?.id}`;
    navigate(routes, { state: { item: item } });
  };
  return (
    <>
      {/* <div className="flex items-center gap-3 text-site-black ">
        <SearchInput
          setSearchItem={setSearchItem} // React.Dispatch<React.SetStateAction<string>>
          searchItem={searchItem} // string
          placeholder="Search" // string
          className="sm:w-auto w-full" // string
          iconRight={true} // boolean
          sm={true} // boolean
        />
      </div> */}
      <div
        id="scrollableDiv"
        style={{ overflowY: "auto", maxHeight: "80vh", height: "100%" }}
      >
        {opporunityList?.length === 0 && !isLoading ? (
          <div className="break-inside-avoid text-center">
            <img
              src={NoDataFound}
              alt="no-data-found"
              className="mb-6 inline-block"
              width={"250px"}
              height={"142px"}
            />
            <Paragraph text18 className="!font-medium">
              No result found
            </Paragraph>
          </div>
        ) : (
          <InfiniteScroll
            dataLength={opporunityList?.length ?? 0}
            next={() => setCurrentPage((prevPage) => prevPage + 1)}
            hasMore={opporunityList?.length < totalItems}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            <div className="grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 md:gap-[18px] gap-4 xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3">
              {opporunityList?.map((item: OpportunityInterface) => {
                return (
                  <div className="col-span-1 ">
                    <div>
                      <div
                        onClick={() => handleDetailPage(item || 0)}
                        className="cursor-pointer sm:h-[190px] h-[150px] rounded-[20px] bg-light-yellow overflow-hidden flex items-center justify-center mb-3"
                      >
                        <ImageWithDefault
                          src={item?.image}
                          alt="opportunity-art"
                        />
                      </div>

                      {item?.isApplied ? (
                        <span className="text-sm leading-5 font-medium text-site-green inline-flex items-center gap-1 mb-1">
                          <FaCircleCheck color="#1AB53C" size={16} />
                          Applied
                        </span>
                      ) : (
                        <span className="text-sm leading-4 font-medium text-site-grey inline-flex items-center gap-1 mb-1">
                          Apply
                        </span>
                      )}
                      <Paragraph text20 className="font-medium mb-2">
                        {item?.title}
                      </Paragraph>
                      <Paragraph
                        text16
                        className="!leading-4 !text-dark-grey flex items-center gap-1"
                      >
                        <img
                          src={IconUsers}
                          alt="icon-users"
                          width={20}
                          height={20}
                        />
                        Candidates: {item?.numberOfApplicants}
                      </Paragraph>
                    </div>
                  </div>
                );
              })}
            </div>
          </InfiniteScroll>
        )}
      </div>
    </>
  );
}

export default Opportunities;
