import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "reactstrap";
import {
  hideLoader,
  showLoader,
} from "../../../../redux/slices/siteLoaderSlice";
import { connectStripeAccount } from "../../../../services/website/accountService";

export default function StripeConnect() {
  const dispatch = useDispatch();
  const [, setCode] = useState("");
  const [loop, setLoop] = useState(0);
  const [loading, setLoading] = useState(true);
  const stripeKey = process.env.STRIPE_URL || "";

  const onWebViewStateChange = (event: any) => {
    const url = event.target.location.href;
    if (url.includes("?code=") && loop < 1) {
      onSuccess(url.split("?code=")[1]?.split("&")[0]);
    }
  };

  const onSuccess = (authCode: any) => {
    setCode(authCode);
    setLoop(1);
    createStripeAccount(authCode);
  };

  const createStripeAccount = async (code: any) => {
    dispatch(showLoader());
    let data = {
      authorizationCode: code,
    };
    try {
      const response = await connectStripeAccount(data);
      console.log("Fsresponsedfdsfd", response);
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="safe-area-view">
      <div className="container">
        {loading && <Spinner />}
        <iframe
          src={stripeKey}
          className="webview"
          onLoad={() => setLoading(false)}
          onLoadStart={onWebViewStateChange}
          title="Stripe Connect"
        />
      </div>
    </div>
  );
}
