import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { languageIdSelector } from "../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../components/language-translation/LanguageTranslation";
import Paragraph from "../../../../components/core/typography/Paragraph";
import AmazonPay from "../../../../assets/images/icon_amazon_pay.svg";
import VisaPay from "../../../../assets/images/icon_visa.svg";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import {
  hideLoader,
  showLoader,
} from "../../../../redux/slices/siteLoaderSlice";
import { getCardsList } from "../../../../services/website/accountService";
import { useNavigate } from "react-router-dom";
import { pathRoute } from "../../../../routes/pathRoute";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function PaymentMethods() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stripeKey = process.env.REACT_APP_WEB_STRIPE_PUBLIC_KEY || "";
  const stripePromise = loadStripe(stripeKey); // Add this
  const [cards, setCards] = useState<any[]>([]);

  const getCards = async () => {
    dispatch(showLoader());
    try {
      const response = await getCardsList();
      if (response?.data?.code === 200) {
        setCards(response?.data?.cards);
      }
    } catch (error) {
      console.error("Error fetching Stripe login link:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    getCards();
  }, []);

  const handleAddCard = () => {
    navigate(pathRoute.website.addCard);
  };
  const getIcon = (item: any) => {
    switch (item) {
      case "visa":
        return VisaPay;
      default:
        return AmazonPay;
    }
  };

  return (
    <div>
      <Paragraph text24 className="font-semibold mb-6 ">
        {LanguageTranslation({
          labelName: "payment_methods",
          languageCode: langId || "fr",
        }) || "Payment Methods"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl xl:p-30 lg:p-6 p-3">
        <div className="grid grid-cols-12 gap-3">
          <div className="lg:col-span-8 col-span-12">
            <ul>
              {cards?.length > 0 &&
                cards?.map((item: any) => {
                  return (
                    <li className="mb-3">
                      <label
                        htmlFor="master-card"
                        className="border border-medium-grey rounded-2xl md:p-4 p-3 flex items-center justify-between cursor-pointer"
                      >
                        <div className="inline-flex items-center md:gap-4 gap-3">
                          <div className="bg-light-grey w-[54px] h-[36px] rounded-[4px] inline-flex items-center justify-center">
                            <img src={getIcon(item?.brand)} alt="amazon-pay" />
                          </div>
                          <div>
                            <Paragraph
                              text18
                              className="!font-medium inline-flex items-center gap-3 !leading-4"
                            >
                              Mastercard
                              <span className="text-dark-grey">|</span>
                              <span className="">...{item?.last4}</span>
                            </Paragraph>
                            <Paragraph
                              text14
                              className="!font-normal !text-dark-grey leading-[12px]"
                            >
                              Pay & secure with mastercard
                            </Paragraph>
                          </div>
                        </div>
                      </label>
                    </li>
                  );
                })}
              <li onClick={handleAddCard}>
                <Elements stripe={stripePromise}>
                  <label className="border border-medium-grey rounded-2xl md:p-4 p-3 flex items-center justify-between cursor-pointer">
                    <div className="inline-flex items-center md:gap-4 gap-3">
                      <div className="bg-light-grey w-[54px] h-[36px] rounded-[4px] inline-flex items-center justify-center">
                        <FiPlus color="#1AB53C" size={24} />
                      </div>
                      <div>
                        <Paragraph
                          text18
                          className="!font-medium inline-flex items-center gap-3 !leading-4"
                        >
                          Add new card
                        </Paragraph>
                        <Paragraph
                          text14
                          className="!font-normal !text-dark-grey leading-[12px]"
                        >
                          Save & Pay via Cards
                        </Paragraph>
                      </div>
                    </div>
                  </label>
                </Elements>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentMethods;
