import React, { useState } from "react";
import Paragraph from "../../../components/core/typography/Paragraph";
import RadioButton from "../../../components/core/form-components/RadioButton";
import Button from "../../../components/core/form-components/Button";
import VideoImage from "../../../assets/images/master_class_dummy_1.png";
import { IoMdPlay } from "react-icons/io";
import { BsClockFill } from "react-icons/bs";

function MasterClassPlans() {
  // const [selectedPlan, setSelectedPlan] = useState<string>("");
  return (
    <div className="grid grid-cols-12 lg:gap-5 md:gap-4 gap-3 py-6 xl:px-10 lg:px-6 md:px-4 px-3">
      <div className="xxl:col-start-2 xxl:col-span-8 xl:col-start-2 xl:col-span-10 col-span-12">
        <div className="border border-medium-grey rounded-[20px] xl:p-30 lg:p-6 md:p-5 p-4">
          <Paragraph text24 className="mb-[6px]">
            Our Masterclass Plans
          </Paragraph>
          <Paragraph
            text18
            className="md:mb-6 mb-5 !text-dark-grey !font-normal"
          >
            Pick a plan that is best for you
          </Paragraph>
          <div className="grid grid-cols-12 lg:gap-6 md:gap-4 gap-3">
            <div className="md:col-span-6 col-span-12">
              <div
                className={`p-4 rounded-xl bg-light-yellow cursor-pointer transition-all h-full`}
                // onClick={() => setSelectedPlan("basic")}
              >
                <div className="flex items-start gap-[26px] xl:pb-5 xl:mb-5 lg:pb-4 lg:mb-4 pb-3 mb-3 border-b border-medium-grey">
                  <RadioButton
                    name="plan"
                    id="basic"
                    value="basic"
                    // checked={selectedPlan === "basic"}
                    // onChange={() => setSelectedPlan("basic")}
                    className="!gap-0"
                  />
                  <div>
                    <Paragraph text20 className="font-medium mb-2">
                      Single Video Access
                    </Paragraph>
                    <Paragraph text16 className="font-normal !leading-4">
                      Single Video Access
                    </Paragraph>
                  </div>
                </div>
                <Paragraph className="md:text-[26px] leading-[24px] text-[20px] font-semibold lg:ps-[50px] ps-[42px]">
                  €5{" "}
                  <span className="md:text-[22px] leading-[20px] text-[18px] font-medium">
                    /video
                  </span>
                </Paragraph>
              </div>
            </div>
            <div className="md:col-span-6 col-span-12">
              <div
                className={`p-4 rounded-xl bg-[#EEF4E6] cursor-pointer transition-all h-full`}
                // onClick={() => setSelectedPlan("premium")}
              >
                <div className="flex items-start gap-[26px] xl:pb-5 xl:mb-5 lg:pb-4 lg:mb-4 pb-3 mb-3 border-b border-medium-grey">
                  <RadioButton
                    name="plan"
                    id="premium"
                    value="premium"
                    // checked={selectedPlan === "premium"}
                    // onChange={() => setSelectedPlan("premium")}
                    className="!gap-0"
                  />
                  <div>
                    <Paragraph text20 className="font-medium mb-2">
                      Full Library Access
                    </Paragraph>
                    <Paragraph text16 className="font-normal !leading-4">
                      Unlimited access to all exclusive videos.
                    </Paragraph>
                  </div>
                </div>
                <Paragraph className="md:text-[26px] leading-[24px] text-[20px] font-semibold lg:ps-[50px] ps-[42px]">
                  €26
                </Paragraph>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-12 lg:gap-6 md:gap-4 gap-3 mt-6 lg:mb-30 mb-6">
            <div className="col-span-12">
              <Paragraph text20 className="font-medium">
                Select video you want to access.
              </Paragraph>
            </div>
            <div className="md:col-span-6 col-span-12">
              <div
                className={`p-[11px] border border-medium-grey rounded-xl bg-white flex items-center gap-3 cursor-pointer transition-all`}
                // onClick={() => setSelectedPlan("video1")}
              >
                <RadioButton
                  name="select_video"
                  id="video1"
                  value="video1"
                  // checked={selectedPlan === "video1"}
                  // onChange={() => setSelectedPlan("video1")}
                  className="!gap-0"
                />
                <div className="flex items-center xxl:gap-6 xl:gap-5 lg:gap-4 gap-3">
                  <div className="overflow-hidden rounded-[4px] sm:max-w-[60px] max-w-[54px] relative before:absolute before:w-full before:h-full before:bg-site-black/30 flex-shrink-0">
                    <img
                      src={VideoImage}
                      alt="dashboard_mockup"
                      width="60px"
                      height="90px"
                      className="inline-block"
                    />
                    <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                      <IoMdPlay
                        color="#fff"
                        size={8}
                        // onClick={() => openLightbox(item?.video)}
                      />
                    </span>
                  </div>
                  <div>
                    <Paragraph text20 className="font-medium mb-[6px]">
                      Basics of Portrait Drawing for Beginners
                    </Paragraph>
                    <Paragraph
                      text16
                      className="!leading-4 flex items-center gap-1 text-site-black"
                    >
                      <BsClockFill size={24} className="text-dark-grey" />
                      Class: 35 min 59 sec
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:col-span-6 col-span-12">
              <div
                className={`p-[11px] border border-medium-grey rounded-xl bg-white flex items-center gap-3 cursor-pointer transition-all`}
                // onClick={() => setSelectedPlan("video2")}
              >
                <RadioButton
                  name="select_video"
                  id="video2"
                  value="video2"
                  // checked={selectedPlan === "video2"}
                  // onChange={() => setSelectedPlan("video2")}
                  className="!gap-0"
                />
                <div className="flex items-center xxl:gap-6 xl:gap-5 lg:gap-4 gap-3">
                  <div className="overflow-hidden rounded-[4px] sm:max-w-[60px] max-w-[54px] relative before:absolute before:w-full before:h-full before:bg-site-black/30 flex-shrink-0">
                    <img
                      src={VideoImage}
                      alt="dashboard_mockup"
                      width="60px"
                      height="90px"
                      className="inline-block"
                    />
                    <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                      <IoMdPlay
                        color="#fff"
                        size={8}
                        // onClick={() => openLightbox(item?.video)}
                      />
                    </span>
                  </div>
                  <div>
                    <Paragraph text20 className="font-medium mb-[6px]">
                      Comprendre ses besoins d'artiste
                    </Paragraph>
                    <Paragraph
                      text16
                      className="!leading-4 flex items-center gap-1 text-site-black"
                    >
                      <BsClockFill size={24} className="text-dark-grey" />
                      Class: 35 min 59 sec
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:col-span-6 col-span-12">
              <div
                className={`p-[11px] border border-medium-grey rounded-xl bg-white flex items-center gap-3 cursor-pointer transition-all`}
                // onClick={() => setSelectedPlan("video3")}
              >
                <RadioButton
                  name="select_video"
                  id="video3"
                  value="video3"
                  // checked={selectedPlan === "video3"}
                  // onChange={() => setSelectedPlan("video3")}
                  className="!gap-0"
                />
                <div className="flex items-center xxl:gap-6 xl:gap-5 lg:gap-4 gap-3">
                  <div className="overflow-hidden rounded-[4px] sm:max-w-[60px] max-w-[54px] relative before:absolute before:w-full before:h-full before:bg-site-black/30 flex-shrink-0">
                    <img
                      src={VideoImage}
                      alt="dashboard_mockup"
                      width="60px"
                      height="90px"
                      className="inline-block"
                    />
                    <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                      <IoMdPlay
                        color="#fff"
                        size={8}
                        // onClick={() => openLightbox(item?.video)}
                      />
                    </span>
                  </div>
                  <div>
                    <Paragraph text20 className="font-medium mb-[6px]">
                      Comprendre ses besoins d'artiste
                    </Paragraph>
                    <Paragraph
                      text16
                      className="!leading-4 flex items-center gap-1 text-site-black"
                    >
                      <BsClockFill size={24} className="text-dark-grey" />
                      Class: 35 min 59 sec
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:col-span-6 col-span-12">
              <div
                className={`p-[11px] border border-medium-grey rounded-xl bg-white flex items-center gap-3 cursor-pointer transition-all`}
                // onClick={() => setSelectedPlan("video4")}
              >
                <RadioButton
                  name="select_video"
                  id="video4"
                  value="video4"
                  // checked={selectedPlan === "video4"}
                  // onChange={() => setSelectedPlan("video4")}
                  className="!gap-0"
                />
                <div className="flex items-center xxl:gap-6 xl:gap-5 lg:gap-4 gap-3">
                  <div className="overflow-hidden rounded-[4px] sm:max-w-[60px] max-w-[54px] relative before:absolute before:w-full before:h-full before:bg-site-black/30 flex-shrink-0">
                    <img
                      src={VideoImage}
                      alt="dashboard_mockup"
                      width="60px"
                      height="90px"
                      className="inline-block"
                    />
                    <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                      <IoMdPlay
                        color="#fff"
                        size={8}
                        // onClick={() => openLightbox(item?.video)}
                      />
                    </span>
                  </div>
                  <div>
                    <Paragraph text20 className="font-medium mb-[6px]">
                      Basics of Portrait Drawing for Beginners
                    </Paragraph>
                    <Paragraph
                      text16
                      className="!leading-4 flex items-center gap-1 text-site-black"
                    >
                      <BsClockFill size={24} className="text-dark-grey" />
                      Class: 35 min 59 sec
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:col-span-6 col-span-12">
              <div
                className={`p-[11px] border border-medium-grey rounded-xl bg-white flex items-center gap-3 cursor-pointer transition-all`}
                // onClick={() => setSelectedPlan("video5")}
              >
                <RadioButton
                  name="select_video"
                  id="video5"
                  value="video5"
                  // checked={selectedPlan === "video5"}
                  // onChange={() => setSelectedPlan("video5")}
                  className="!gap-0"
                />
                <div className="flex items-center xxl:gap-6 xl:gap-5 lg:gap-4 gap-3">
                  <div className="overflow-hidden rounded-[4px] sm:max-w-[60px] max-w-[54px] relative before:absolute before:w-full before:h-full before:bg-site-black/30 flex-shrink-0">
                    <img
                      src={VideoImage}
                      alt="dashboard_mockup"
                      width="60px"
                      height="90px"
                      className="inline-block"
                    />
                    <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                      <IoMdPlay
                        color="#fff"
                        size={8}
                        // onClick={() => openLightbox(item?.video)}
                      />
                    </span>
                  </div>
                  <div>
                    <Paragraph text20 className="font-medium mb-[6px]">
                      Basics of Portrait Drawing for Beginners
                    </Paragraph>
                    <Paragraph
                      text16
                      className="!leading-4 flex items-center gap-1 text-site-black"
                    >
                      <BsClockFill size={24} className="text-dark-grey" />
                      Class: 35 min 59 sec
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:col-span-6 col-span-12">
              <div
                className={`p-[11px] border border-medium-grey rounded-xl bg-white flex items-center gap-3 cursor-pointer transition-all`}
                // onClick={() => setSelectedPlan("video6")}
              >
                <RadioButton
                  name="select_video"
                  id="video6"
                  value="video6"
                  // checked={selectedPlan === "video6"}
                  // onChange={() => setSelectedPlan("video6")}
                  className="!gap-0"
                />
                <div className="flex items-center xxl:gap-6 xl:gap-5 lg:gap-4 gap-3">
                  <div className="overflow-hidden rounded-[4px] sm:max-w-[60px] max-w-[54px] relative before:absolute before:w-full before:h-full before:bg-site-black/30 flex-shrink-0">
                    <img
                      src={VideoImage}
                      alt="dashboard_mockup"
                      width="60px"
                      height="90px"
                      className="inline-block"
                    />
                    <span className="w-4 h-4 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] inline-flex items-center justify-center bg-gradient-to-r from-site-black -from-[6.79%] to-site-black/0 to-[155.78%] backdrop-blur-md cursor-pointer">
                      <IoMdPlay
                        color="#fff"
                        size={8}
                        // onClick={() => openLightbox(item?.video)}
                      />
                    </span>
                  </div>
                  <div>
                    <Paragraph text20 className="font-medium mb-[6px]">
                      Comprendre ses besoins d'artiste
                    </Paragraph>
                    <Paragraph
                      text16
                      className="!leading-4 flex items-center gap-1 text-site-black"
                    >
                      <BsClockFill size={24} className="text-dark-grey" />
                      Class: 35 min 59 sec
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-12 flex justify-end">
            <Button
              primary
              // disabled={!selectedPlan}
            >
              Start Your Plan
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterClassPlans;
