import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PostZoomInOut from "./PostZoomInOut";

const PostCarousel = ({ itemImg, feedData }: any) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.5,
    mobileFirst: true,
    slidesToScroll: 1,
  };

  return (
    <Slider {...settings}>
      {itemImg?.map((image: any, idx: any) => (
        <div
          key={idx}
          className="block rounded-[20px] overflow-hidden relative w-auto"
        >
          <img
            src={image.imageUrl}
            alt={`Feed image ${idx + 1}`}
            className="w-full sm:h-auto h-[180px] max-h-[300px] object-cover rounded-[20px] bg-light-grey cursor-pointer"
            loading="lazy"
          />

          {/* Top-right icon */}
          <div className="absolute top-2 right-2 ">
            <PostZoomInOut images={image.imageUrl} feedData={feedData} />
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default PostCarousel;
