import React, { useEffect, useState } from "react";
import IconShare from "../../assets/images/icon_share.svg";
import DummyProfile from "../../assets/images/dummy_logo.png";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { CommentInterface } from "../../interface/Comment";
import { hideLoader, showLoader } from "../../redux/slices/siteLoaderSlice";
import {
  addComments,
  addReplyComments,
  editComments,
  getFeedCommentsById,
  likeFeedInCommentSection,
  removeComment,
} from "../../services/website/feedService";
import Paragraph from "../core/typography/Paragraph";
import TimeDifference from "./TimeDifference";
import CommentReplySection from "./CommentReplySection";
import Dropdown from "../core/form-components/Dropdown";
import { IoEllipsisHorizontal } from "react-icons/io5";
import { HiUserAdd } from "react-icons/hi";
import { MdBlock } from "react-icons/md";
import IconCopy from "../../assets/images/icon-copy.svg";
import IconReport from "../../assets/images/icon-info.svg";
import IconHide from "../../assets/images/icon-hide.svg";
import IconDelete from "../../assets/images/icon-delete.svg";
import { userSelector } from "../../redux/slices/userSlice";
import { BiSolidPencil } from "react-icons/bi";
import { hideUser, reportUser } from "../../services/website/peopleService";
import Modal from "../core/Modal";
import ReportProjectContent from "../core/ReportProjectContent";
import Button from "../core/form-components/Button";

interface commentInterface {
  commentId: number;
  commentUserId?: number;
}
const CommentSection = ({ commentId, commentUserId }: commentInterface) => {
  const [commentData, setCommentData] = useState<CommentInterface[]>([]);
  const [commentCurrentPage, setCommentCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const itemsPerPage = 10;
  const [selectedReplyCommentId, setSelectedReplyCommentId] = useState<
    number | null
  >(null);
  const [totalItems, setTotalItems] = useState(0); // Track total available items
  const [loading, setLoading] = useState(false); // Loading state for API calls
  const [comment, setComment] = useState("");
  const [replyComment, setReplyComment] = useState("");
  const [replyInputId, setReplyInputId] = useState<number | null>(null);
  const webProfileData = useSelector(userSelector);
  const [isEdit, setIsEdit] = useState<number | null>(null);
  const [editText, setEditText] = useState("");
  const [reportPopup, setReportPopup] = useState(false);
  const [reportId, setReportId] = useState<number | null>(null);
  const [selectId, setSelectId] = useState<number | null>(null);

  const getProfileImage = (url: string) => {
    const defaultAvatarUrl =
      "https://api.artgapi.com/uploads/icons/default_avatar.png";
    return url === defaultAvatarUrl ? DummyProfile : url;
  };

  const fetchCommentsById = async (id: number, commentCurrentPage: number) => {
    let queryString = `?articleId=${id}&limit=10&page=${commentCurrentPage}`;
    dispatch(showLoader());
    setLoading(true); // Start loading when fetching data

    try {
      const response = await getFeedCommentsById(id, queryString);
      const count = response?.data?.total || 0;
      if (response?.data?.code === 200) {
        setCommentData((prevData) => [...prevData, ...response.data.comments]);
        setTotalItems(count); // Update the total number of items
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
      setLoading(false); // End loading after fetching data
    }
  };

  useEffect(() => {
    if (commentCurrentPage && commentId) {
      fetchCommentsById(commentId, commentCurrentPage);
    }
  }, [commentId, commentCurrentPage]);

  const handleLikeCommentSection = async (item: any) => {
    try {
      const response = await likeFeedInCommentSection(item?.id);

      if (response?.data?.code === 201) {
        // Toggle the likedByMe status and update the feedData state
        setCommentData((prevData) =>
          prevData.map((feedItem) =>
            feedItem.id === item.id
              ? {
                  ...feedItem,
                  likedByMe: !feedItem.likedByMe, // Toggle likedByMe
                  likerCount: feedItem.likedByMe
                    ? feedItem.likerCount - 1 // Decrease countLikes if already liked
                    : feedItem.likerCount + 1, // Increase countLikes if not liked
                }
              : feedItem
          )
        );
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    }
  };

  const handleReplyToggle = (id: number) => {
    if (selectedReplyCommentId === id) {
      // If the same comment is clicked, reset the selected comment ID and clear response comments
      setSelectedReplyCommentId(null);
    } else {
      // If a different comment is selected, fetch its replies and clear previous responses
      setSelectedReplyCommentId(id);
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      event.currentTarget.scrollHeight ===
      event.currentTarget.scrollTop + event.currentTarget.clientHeight;
    if (bottom && !loading && commentData.length < totalItems) {
      setCommentCurrentPage((prevPage) => prevPage + 1); // Load the next page
    }
  };

  const handleAddComments = async () => {
    const trimmedComment = comment.trim();
    if (!trimmedComment) return;
    dispatch(showLoader());

    try {
      let data = {
        text: trimmedComment,
      };
      const response = await addComments(data, commentId);
      if (response?.data?.code === 200) {
        setCommentData((prevData) => [...prevData, response.data.data]);
        setComment("");
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };
  const handleCancelComments = () => {
    setIsEdit(null);
    setEditText("");
  };

  const handleEditComments = async () => {
    dispatch(showLoader());

    try {
      let data = {
        text: editText,
      };
      const response = await editComments(data, isEdit || 0);
      if (response?.data?.code === 200) {
        setCommentData((prevData) =>
          prevData.map((feedItem) =>
            feedItem.id === isEdit
              ? {
                  ...feedItem,
                  text: editText, // Toggle likedByMe
                }
              : feedItem
          )
        );

        setEditText("");
        setIsEdit(null);
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleCommentReply = async (id: any) => {
    const textWithoutMention = replyComment.replace(/^@\w+\s*/, "");

    const trimmedComment = textWithoutMention.trim();
    if (!trimmedComment) return;
    dispatch(showLoader());

    try {
      let data = {
        text: trimmedComment, // Ensure no trailing whitespace
      };
      const response = await addReplyComments(data, id);
      if (response?.data?.code === 200) {
        setCommentData((prevData) =>
          prevData.map((feedItem) =>
            feedItem.id === id
              ? {
                  ...feedItem,
                  countResponses: feedItem.countResponses + 1, // Increment only if showing the input box
                }
              : feedItem
          )
        );
        setReplyComment("");
        setReplyInputId(null);
      }
    } catch (error) {
      console.error("Error liking feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleReplySection = (id: number, username: string) => {
    if (replyInputId === id) {
      setReplyInputId(null);
      setReplyComment(""); // Clear the reply input when toggled off
    } else {
      setReplyInputId(id);
      setReplyComment(`@${username} `); // Set the username in the reply input
    }
  };

  const commentUserDownItems = (item: any) => {
    if (commentUserId !== webProfileData?.id) {
      return [
        {
          icon: <img src={IconCopy} alt="icon-copy" width={12} height={12} />,
          Link: "",
          LinkName: "Copy",
          handleAction: true,
        },
        {
          icon: (
            <img src={IconReport} alt="icon-report" width={12} height={12} />
          ),
          Link: "",
          LinkName: "Report",
          handleAction: true,
        },
        {
          icon: <img src={IconHide} alt="icon-hide" width={12} height={12} />,
          Link: "",
          LinkName: "Hide",
          handleAction: true,
        },
      ];
    } else {
      return [
        {
          icon: <img src={IconCopy} alt="icon-copy" width={12} height={12} />,
          Link: "",
          LinkName: "Copy",
          handleAction: true,
        },
        {
          icon: (
            <img src={IconReport} alt="icon-report" width={12} height={12} />
          ),
          Link: "",
          LinkName: "Report",
          handleAction: true,
        },
        {
          icon: <img src={IconHide} alt="icon-hide" width={12} height={12} />,
          Link: "",
          LinkName: "Hide",
          handleAction: true,
        },
        {
          icon: <img src={IconDelete} alt="icon-hide" width={12} height={12} />,
          Link: "",
          LinkName: "Remove",
          handleAction: true,
        },
      ];
    }
  };

  const commentProfileDownItems = [
    {
      icon: <BiSolidPencil />,
      Link: "",
      LinkName: "Edit",
      handleAction: true,
    },
    {
      icon: <img src={IconDelete} alt="icon-delete" width={12} height={12} />,
      Link: "",
      LinkName: "Delete",
      handleAction: true,
    },
  ];

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = (dropdown: any) => {
    setIsOpen((prevDropdown) => (prevDropdown === dropdown ? null : dropdown));
  };

  const handleDeleteItem = async (id: any) => {
    dispatch(showLoader());
    try {
      const response = await removeComment(id);
      if (response?.data?.code === 200) {
        setCommentData((prevData) =>
          prevData?.filter((item) => item.id !== id)
        );
        setTotalItems((prevTotal) => prevTotal - 1);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleEditSection = (id: number, text: string) => {
    if (isEdit === id) {
      setIsEdit(null);
      setEditText("");
    } else {
      setIsEdit(id);
      setEditText(text);
    }
  };

  const closeReportPopup = () => {
    setReportPopup(false);
    setSelectId(null);
  };

  const handleReportId = (id: number) => {
    setReportId(id);
  };

  const handleReportApi = async () => {
    dispatch(showLoader());
    try {
      let data = {
        flagId: reportId,
      };
      const response = await reportUser(data, selectId);
      if (response?.data?.code === 201) {
        closeReportPopup();
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleHideSection = async (id: number) => {
    dispatch(showLoader());
    try {
      const response = await hideUser(id);
      if (response?.data?.code === 200) {
        setCommentData((prevData) =>
          prevData?.filter((item) => item.id !== id)
        );
        setTotalItems((prevTotal) => prevTotal - 1);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Text copied to clipboard:", text);
        // Optionally, display a success toast/message here
      },
      (err) => {
        console.error("Failed to copy text:", err);
        // Optionally, display an error toast/message here
      }
    );
  };

  const handleActionsInweb = (type: string, item: any) => {
    switch (type) {
      case "Delete":
        handleDeleteItem(item?.id);
        break;
      case "Edit":
        handleEditSection(item?.id, item?.text);
        break;
      case "Report":
        setSelectId(item?.id);
        setReportPopup(!reportPopup);
        break;
      case "Hide":
        handleHideSection(item?.id);
        break;
      case "Remove":
        handleDeleteItem(item?.id);
        break;
      case "Copy":
        handleCopyToClipboard(item?.text);
        break;
      default:
        console.log("Unknown action type:", type);
        break;
    }
  };

  return (
    <div className="mt-4">
      <div className="relative">
        <input
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Add a comment..."
          className="bg-light-grey rounded-lg px-3 py-2.5 pr-10 w-full focus:outline-none"
        />
        <button
          onClick={handleAddComments}
          className="inline-flex items-center justify-center w-6 h-6 absolute right-3 top-2.5"
        >
          <img src={IconShare} alt="icon-share" />
        </button>
      </div>
      <div
        className="sm:px-6 px-0"
        onScroll={handleScroll}
        style={{ overflowY: "auto", maxHeight: "300px" }}
      >
        {commentData?.length === 0 ? (
          ""
        ) : (
          <ul className="mt-6">
            {commentData?.map((cItem: CommentInterface, cIndex: number) => {
              const isLastCommentItem = cIndex === commentData.length - 1;

              return (
                <li
                  className={`flex items-start sm:gap-3 gap-2 lg:pb-5 pb-4 lg:mb-6 mb-4 border-b border-medium-grey ${
                    isLastCommentItem ? "mb-0 border-b-0" : ""
                  }`}
                >
                  <img
                    src={getProfileImage(cItem?.user?.avatar)}
                    alt="profile"
                    className="sm:w-10 sm:h-10 w-8 h-8 rounded-full flex-shrink-0"
                  />
                  <div className="w-full">
                    <div className="flex items-start gap-3 justify-between w-full">
                      <div className="">
                        <div className={`flex items-center sm:gap-3 gap-2`}>
                          <Paragraph text16 className="!leading-4 !font-medium">
                            @{cItem?.user?.nickname}
                          </Paragraph>
                          <Dropdown
                            onClick={() => toggleDropdown("commentDrop")}
                            dropdownItems={
                              cItem?.user?.id == webProfileData?.id
                                ? commentProfileDownItems
                                : commentUserDownItems(cItem)
                            }
                            dropdownWidth="w-[174px]"
                            handleActionsInweb={(e: any) =>
                              handleActionsInweb(e, cItem)
                            }
                          >
                            <IoEllipsisHorizontal size={14} />
                          </Dropdown>
                        </div>

                        {isEdit == cItem.id ? (
                          <div className="relative">
                            <input
                              name="comment"
                              value={editText}
                              onChange={(e) => setEditText(e.target.value)}
                              placeholder="Add a comment..."
                              className="bg-light-grey rounded-lg px-3 py-2.5 pr-20 w-full focus:outline-none"
                            />
                            <div className="absolute right-3 top-2.5 flex space-x-2">
                              <button
                                onClick={handleCancelComments}
                                className="inline-flex items-center justify-center w-6 h-6"
                              >
                                <img src={IconDelete} alt="icon-cancel" />
                              </button>
                              <button
                                onClick={handleEditComments}
                                className="inline-flex items-center justify-center w-6 h-6"
                              >
                                <img src={IconShare} alt="icon-share" />
                              </button>
                            </div>
                          </div>
                        ) : (
                          <Paragraph
                            text14
                            className="!leading-[17px] !font-normal !text-dark-grey mb-3"
                          >
                            {cItem?.text}
                          </Paragraph>
                        )}

                        <div className="inline-flex items-center">
                          <Paragraph
                            text14
                            className="!leading-[15px] !font-normal !text-dark-grey sm:pr-4 sm:mr-4 pr-3 mr-3 border-r border-medium-grey "
                          >
                            <TimeDifference date={cItem?.createdAt} />
                          </Paragraph>
                          <Paragraph
                            text14
                            className="!leading-[15px] cursor-pointer !font-normal !text-dark-grey sm:pr-4 sm:mr-4 pr-3 mr-3 border-r border-medium-grey "
                            onClick={() =>
                              handleReplySection(
                                cItem.id || 0,
                                cItem?.user?.nickname || ""
                              )
                            }
                          >
                            {replyInputId === cItem.id ? "Cancel" : "Reply"}
                          </Paragraph>
                          <Paragraph
                            text14
                            className="!leading-[15px] !font-normal !text-dark-grey cursor-pointer"
                            onClick={() => handleReplyToggle(cItem?.id || 0)}
                          >
                            {selectedReplyCommentId === cItem.id
                              ? cItem?.countResponses > 1
                                ? "Hide Replies"
                                : "Hide Reply"
                              : cItem?.countResponses > 1
                              ? `${cItem?.countResponses} Replies`
                              : `${cItem?.countResponses} Reply`}
                          </Paragraph>
                        </div>
                      </div>
                      <div className="flex flex-col items-center gap-1">
                        <button>
                          {cItem?.likedByMe ? (
                            <IoMdHeart
                              color="#DF3131"
                              size={18}
                              onClick={() => handleLikeCommentSection(cItem)}
                            />
                          ) : (
                            <IoMdHeartEmpty
                              size={18}
                              color={"#4D4B4B"}
                              onClick={() => handleLikeCommentSection(cItem)}
                            />
                          )}
                        </button>
                        <Paragraph text14 className="!leading-3">
                          {cItem?.likerCount}
                        </Paragraph>
                      </div>
                    </div>
                    {replyInputId === cItem.id && (
                      <div className="relative mt-3.5">
                        <input
                          name="reply"
                          value={replyComment} // Optional: Update reply state for the comment
                          onChange={(e) => setReplyComment(e.target.value)}
                          placeholder="Add a comment..."
                          className="bg-light-grey rounded-lg px-3 py-2.5 pr-10 w-full focus:outline-none"
                        />
                        <button
                          className="inline-flex items-center justify-center w-6 h-6 absolute right-3 top-2.5"
                          onClick={() => handleCommentReply(cItem.id)} // Update with reply-specific logic
                        >
                          <img src={IconShare} alt="icon-share" />
                        </button>
                      </div>
                    )}
                    {cItem?.countResponses > 0 &&
                      selectedReplyCommentId === cItem.id && (
                        <CommentReplySection
                          selectedReplyCommentId={selectedReplyCommentId}
                        />
                      )}
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <Modal
        open={reportPopup}
        header
        onClose={closeReportPopup}
        width="sm:w-[448px] w-[300px]"
        modalPadding="p-6"
      >
        <ReportProjectContent onSelectionChange={handleReportId} />
        <div className="flex items-center justify-center md:gap-4 gap-3">
          <Button
            borderButton
            className="w-full"
            onClick={() => setReportPopup(false)}
          >
            Cancel
          </Button>
          <Button primary onClick={handleReportApi} className="w-full">
            Send
          </Button>
        </div>
      </Modal>
      {loading && <div>Loading...</div>} {/* Loading Spinner */}
    </div>
  );
};

export default CommentSection;
