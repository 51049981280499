import React, { useState } from "react";
import Paragraph from "../../../../../components/core/typography/Paragraph";
import { languageIdSelector } from "../../../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../../../components/language-translation/LanguageTranslation";
import { useDispatch, useSelector } from "react-redux";
import { FaEnvelope } from "react-icons/fa";
import { Form, Formik, Field } from "formik";
import FormLabel from "../../../../../components/core/typography/FormLabel";
import InputType from "../../../../../components/core/form-components/InputType";
import TextArea from "../../../../../components/core/form-components/TextArea";
import { FiPlus } from "react-icons/fi";
import DummyImg from "../../../../../assets/images/art2.png";
import { IoClose } from "react-icons/io5";
import Button from "../../../../../components/core/form-components/Button";
import {
  hideLoader,
  showLoader,
} from "../../../../../redux/slices/siteLoaderSlice";
import { reportABug } from "../../../../../services/website/accountService";
import { tokenSelector } from "../../../../../redux/slices/userSlice";
import { contactusValidationSchema } from "../../../../../validations/website/contactusValidationSchema";

interface FormValues {
  description: string;
  title: string;
  img1?: File | null;
  img2?: File | null;
  img3?: File | null;
}

function ContactUs() {
  const language = useSelector(languageIdSelector);
  const langId = language?.languageId;
  const dispatch = useDispatch();
  const [defaultInitialValues, setDefaultInitialValues] = useState<FormValues>({
    description: "",
    title: "",
    img1: null,
    img2: null,
    img3: null,
  });
  const token = useSelector(tokenSelector);

  const handleImageChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    fieldName: string,
    setError: (field: string, message: string) => void
  ) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];
      const maxSize = 5 * 1024 * 1024; // 5MB
      if (!validTypes.includes(file.type)) {
        setError(fieldName, "Only JPG or PNG images are allowed.");
        return;
      }

      if (file.size > maxSize) {
        setError(fieldName, "Image size should not exceed 5MB.");
        return;
      }

      setError(fieldName, ""); // Clear error if valid
      setFieldValue(fieldName, file);
    }
  };

  const handleRemoveImage = (
    setFieldValue: (field: string, value: any) => void,
    fieldName: string
  ) => {
    setFieldValue(fieldName, null);
  };

  const onSubmit = async (
    values: FormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    const uploadedImages = [values.img1, values.img2, values.img3]?.filter(
      (img) => img !== null
    ) as File[];

    dispatch(showLoader());
    const formData = new FormData();
    formData.append("title", values?.title.toString()); // Convert number to string
    formData.append("description", values?.description || "");
    uploadedImages?.forEach((learningPoint, index) => {
      formData.append("images", learningPoint); // Appending each File object
    });
    try {
      if (token) {
        const response = await reportABug(formData);
        resetForm();
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="lg:p-30 md:p-4 p-3 !pt-0 grid grid-cols-12">
      <div className="xl:col-span-8 col-span-12">
        <Paragraph text20 className="!font-medium mb-4">
          {LanguageTranslation({
            labelName: "contact_us",
            languageCode: langId || "fr",
          }) || "Contact Us"}
        </Paragraph>
        <a
          href="mailto:contact@artgapi.com"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-[#F3F3F3] px-3 py-1.5 rounded-[50px] inline-flex items-center gap-1.5 text-sm leading-[16px] font-medium mb-3"
        >
          <FaEnvelope />
          {LanguageTranslation({
            labelName: "email",
            languageCode: langId || "fr",
          }) || "Email"}
        </a>
        <Paragraph text16 className="!text-dark-grey mb-6">
          {LanguageTranslation({
            labelName: "contact_us_via_email",
            languageCode: langId || "fr",
          }) || "You can contact us via email"}
        </Paragraph>
        <Paragraph text20 className="!font-medium mb-4">
          {LanguageTranslation({
            labelName: "report_bug",
            languageCode: langId || "fr",
          }) || "Report a bug"}
        </Paragraph>
        <Formik
          initialValues={defaultInitialValues}
          onSubmit={onSubmit}
          validationSchema={contactusValidationSchema}
          enableReinitialize
        >
          {({ isSubmitting, values, setFieldValue, setFieldError, errors }) => (
            <Form>
              <div className="form-group lg:mb-6 mb-4">
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "title",
                    languageCode: langId || "fr",
                  }) || "Title"}
                  <span className="text-site-red">*</span>
                </FormLabel>
                <InputType
                  name="title"
                  type="text"
                  placeholder="Enter name"
                  value={values?.title}
                />
              </div>
              <div className="form-group lg:mb-6 mb-4">
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "description",
                    languageCode: langId || "fr",
                  }) || "Description"}
                  <span className="text-site-red">*</span>
                </FormLabel>
                <TextArea
                  name="description"
                  rows={3}
                  placeholder="What do you want to share"
                />
              </div>
              <div className="form-group lg:mb-6 mb-30">
                <FormLabel>
                  {LanguageTranslation({
                    labelName: "upload_file",
                    languageCode: langId || "fr",
                  }) || "Upload File"}
                </FormLabel>
                <div className="flex gap-4 mb-2">
                  {[1, 2, 3].map((index) => {
                    const fieldName = `img${index}`;
                    const image = values[fieldName as keyof FormValues];
                    console.log("dsfdsfdsfdsf", errors);
                    return (
                      <label
                        key={index}
                        htmlFor={fieldName}
                        className="relative cursor-pointer border border-dashed border-medium-grey rounded-[4px] lg:w-[100px] lg:h-[100px] w-[60px] h-[60px] flex items-center justify-center"
                      >
                        {image ? (
                          <>
                            <img
                              src={URL.createObjectURL(image as File)}
                              alt=""
                              className="w-full h-full object-cover rounded-[4px]"
                            />
                            <span
                              className="absolute -top-1.5 -right-1.5 w-4 h-4 rounded-full bg-site-red text-white cursor-pointer flex items-center justify-center"
                              onClick={(e) => {
                                e.preventDefault();
                                handleRemoveImage(setFieldValue, fieldName);
                              }}
                            >
                              <IoClose />
                            </span>
                          </>
                        ) : (
                          <>
                            <input
                              type="file"
                              id={fieldName}
                              className="sr-only"
                              onChange={(e) =>
                                handleImageChange(
                                  e,
                                  setFieldValue,
                                  fieldName,
                                  setFieldError
                                )
                              }
                            />
                            <FiPlus color="#4D4B4B" size={24} />
                          </>
                        )}
                      </label>
                    );
                  })}
                </div>
                {(errors?.img1 || errors?.img2 || errors?.img3) && (
                  <div className="text-site-red text-sm font-medium mb-2">
                    {errors?.img1 || errors?.img2 || errors?.img3}
                  </div>
                )}

                <Paragraph text16 className="!text-dark-grey">
                  {LanguageTranslation({
                    labelName: "upload_upto_3",
                    languageCode: langId || "fr",
                  }) || "You can upload up to 3 files only"}
                </Paragraph>
              </div>
              <Button disabled={isSubmitting} primary type="submit">
                Send
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default ContactUs;
