import * as Yup from "yup";

export const profileValidationSchema = Yup.object({
  mail: Yup.string()
    .email("Email is invalid")
    .trim()
    .max(255, "Maximum 255 Characters allowed")
    .required("Email is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i, "Email is invalid"),
  name: Yup.string()
    .trim()
    .max(50, "Maximum 50 characters allowed")
    .min(1, "Minimum 1 character required")
    .required("First name is required"),
  surname: Yup.string()
    .trim()
    .max(50, "Maximum 50 characters allowed")
    .min(1, "Minimum 1 character required")
    .required("Last name is required"),
  nickname: Yup.string()
    .trim()
    .max(50, "Maximum 50 characters allowed")
    .min(1, "Minimum 1 character required")
    .required("please enter pseudonym name"),
  address: Yup.string().trim().required("Block number is required"),
  city: Yup.string().trim().required("City is required"),
  zip: Yup.string()
    .matches(/^\d+$/, "Post code must be a number")
    .required("Post code is required"),
});
