import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import FormLabel from "../../../components/core/typography/FormLabel";
import TextArea from "../../../components/core/form-components/TextArea";
import RadioButton from "../../../components/core/form-components/RadioButton";
import UploadFile from "../../../assets/images/icon_image_upload.svg";
import Button from "../../../components/core/form-components/Button";
import Paragraph from "../../../components/core/typography/Paragraph";
import SwitchToggle from "../../../components/core/form-components/SwitchToggle";
import InputType from "../../../components/core/form-components/InputType";
import SelectType from "../../../components/core/form-components/SelectType";
import Modal from "../../../components/core/Modal";
import ColorWheel from "../../../assets/images/icon_color_wheel.svg";
import { useDispatch, useSelector } from "react-redux";
import { languageIdSelector } from "../../../redux/slices/website/languageSlice";
import { LanguageTranslation } from "../../../components/language-translation/LanguageTranslation";
import IconLock from "../../../assets/images/icon_lock.svg";
import IconUnLock from "../../../assets/images/icon_unlock.svg";
import {
  addEvent,
  getLocation,
} from "../../../services/website/profileService";
import { google, outlook, office365, yahoo, ics } from "calendar-link";
import { eventValidationSchema } from "../../../validations/website/createEventValidationSchema";
import { hideLoader, showLoader } from "../../../redux/slices/siteLoaderSlice";
import { useNavigate } from "react-router-dom";

interface FormValues {
  eventName: string;
  location: string;
  startDate: string;
  endDate: string;
  isPrivate?: string;
  description: string;
  entranceFee?: boolean;
  trackingUrl?: string;
  price: string;
  coverImage?: any;
  imageUrl: string;
  google_url?: string;
  iCal_url?: string;
  yahoo_url?: string;
  outlook_url?: string;
}

function CreateEvent() {
  const language = useSelector(languageIdSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const langId = language?.languageId;

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [artoptions, setArtOptions] = useState([]);
  const [defaultInitialValues, setDefaultInitialValues] = useState({
    eventName: "",
    location: "",
    startDate: "",
    endDate: "",
    isPrivate: "private",
    description: "",
    entranceFee: false,
    trackingUrl: "",
    price: "",
    coverImage: "",
    imageUrl: "",
    google_url: "",
    iCal_url: "",
    yahoo_url: "",
    outlook_url: "",
  });

  const getLocationFunc = async () => {
    let queryString = `?criteria=${search}&page=${page}&limit=10`;
    const response = await getLocation(queryString);
    const formattedData = response?.data?.data?.map(
      (category: any, index: number) => ({
        label: category.name,
        value: category.name,
        id: index + 1,
      })
    );
    setArtOptions(formattedData || []);
  };

  useEffect(() => {
    if (search) {
      getLocationFunc();
    }
  }, [search]);

  const onSearchChange = (data: any) => {
    setSearch(data);
  };

  const generateCalendarUrl = (
    provider: "google" | "outlook" | "office365" | "yahoo" | "ics",
    values: any
  ) => {
    const event = {
      title: values.eventName,
      description: values.description,
      start: values.startDate,
      end: values.endDate,
      location: values.location,
    };

    switch (provider) {
      case "google":
        return google(event);
      case "outlook":
        return outlook(event);
      case "yahoo":
        return yahoo(event);
      case "ics":
        return ics(event);
      default:
        return "";
    }
  };

  const handleProfileImage = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    setErrors: (errors: { [key: string]: string }) => void
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const validTypes = ["image/jpeg", "image/png"];

      if (validTypes.includes(file.type)) {
        const imageUrl = URL.createObjectURL(file);
        setFieldValue("coverImage", file);
        setFieldValue("imageUrl", imageUrl);
      } else {
        setErrors({ imageUrl: "Only JPG and PNG files are allowed." });
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const onSubmit = async (values: FormValues) => {
    console.log("Form Data: ", values);
    dispatch(showLoader());
    const formData = new FormData();
    formData.append("type", String(1)); // Convert number to string
    formData.append("name", values?.eventName ?? ""); // Ensure string
    formData.append("startDateTime", values?.startDate ?? "");
    formData.append("endDateTime", values?.endDate ?? "");
    formData.append("description", values?.description ?? "");
    formData.append("public", values?.isPrivate ?? "");
    formData.append("ticketingLink", values?.trackingUrl ?? "");
    formData.append("location", values?.location ?? "");
    formData.append("googleCalUrl", generateCalendarUrl("google", values));
    formData.append("iCalUrl", generateCalendarUrl("ics", values));
    formData.append("yahooCalUrl", generateCalendarUrl("yahoo", values));
    formData.append("outlookCalUrl", generateCalendarUrl("outlook", values));
    formData.append("price", String(values?.price));
    formData.append("coverImage", values?.coverImage);
    try {
      const response = await addEvent(formData);
      if (response?.data?.code === 201) {
        navigate(-1);
      }
    } catch (error) {
      console.error("Error fetching feed:", error);
    } finally {
      dispatch(hideLoader());
    }
  };

  return (
    <div className="xl:py-6 py-4 xl:px-10 lg:px-6 md:px-4 px-3 ">
      <Paragraph text24 className="md:mb-6 sm:mb-5 mb-4">
        {LanguageTranslation({
          labelName: "create_event",
          languageCode: langId || "fr",
        }) || "Create Event"}
      </Paragraph>
      <div className="border border-medium-grey rounded-2xl overflow-hidden">
        <Formik
          initialValues={defaultInitialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={eventValidationSchema}
        >
          {({ isSubmitting, values, setFieldValue, errors, setErrors }) => (
            <Form>
              <div className="grid grid-cols-12">
                <div className="xl:col-span-3 sm:col-span-4 col-span-12 bg-light-grey xl:p-30 md:p-5 p-3">
                  <FormLabel>
                    {LanguageTranslation({
                      labelName: "event_image",
                      languageCode: langId || "fr",
                    }) || "Event Image"}
                  </FormLabel>
                  <label
                    htmlFor="upload-file"
                    className="px-4 py-3.5 md:h-[180px] h-[120px] bg-white rounded-lg border border-dashed border-medium-grey flex flex-col justify-center items-center gap-3 cursor-pointer"
                  >
                    <input
                      type="file"
                      name="upload-file"
                      id="upload-file"
                      className="sr-only"
                      onChange={(e) =>
                        handleProfileImage(e, setFieldValue, setErrors)
                      }
                    />
                    {values?.imageUrl ? (
                      <img
                        src={values?.imageUrl}
                        alt="image-file"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <>
                        <img
                          src={UploadFile}
                          alt="upload-file"
                          width={30}
                          height={30}
                        />
                        <span className="text-sm leading-3 text-dark-grey">
                          Upload file
                        </span>
                      </>
                    )}
                  </label>
                  {errors?.imageUrl && (
                    <div className="text-site-red text-sm font-medium">
                      {errors?.imageUrl}
                    </div>
                  )}
                </div>
                <div className="xl:col-span-9 sm:col-span-8 col-span-12 xl:p-30 md:p-5 p-3">
                  <div className="grid grid-cols-12 lg:gap-6 md:gap-5 gap-3">
                    <div className="md:col-span-6 col-span-12">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "event_name",
                          languageCode: langId || "fr",
                        }) || "Event Name"}
                      </FormLabel>
                      <InputType
                        name="eventName"
                        placeholder="Enter event name"
                        value={values?.eventName}
                      />
                    </div>
                    <div className="md:col-span-6 col-span-12">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "location",
                          languageCode: langId || "fr",
                        }) || "Location"}
                      </FormLabel>
                      <SelectType
                        options={artoptions}
                        fullWidth
                        placeholder="Select"
                        isSearchable
                        onSearchChange={onSearchChange}
                        onChange={(option) => {
                          setFieldValue("location", option?.value);
                        }}
                        value={
                          artoptions?.find(
                            (option: any) => option?.value === values?.location
                          ) || ""
                        }
                      />
                      {errors?.location && (
                        <div className="text-site-red text-sm font-medium">
                          {errors?.location}
                        </div>
                      )}
                    </div>

                    <div className="md:col-span-6 col-span-12">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "start_date_time",
                          languageCode: langId || "fr",
                        }) || "Start Date & time"}
                      </FormLabel>
                      <InputType
                        name="startDate"
                        placeholder="Enter"
                        type="date"
                        min={new Date().toISOString().split("T")[0]}
                        value={values.startDate}
                        onChange={(e) =>
                          setFieldValue("startDate", e.target.value)
                        }
                        onKeyDown={(e: any) => e.preventDefault()} // Prevent manual typing
                        onPaste={(e: any) => e.preventDefault()} // Prevent pasting into the field
                      />
                    </div>
                    <div className="md:col-span-6 col-span-12">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "end_date_time",
                          languageCode: langId || "fr",
                        }) || "End Date & time"}
                      </FormLabel>
                      <InputType
                        name="endDate"
                        placeholder="Enter"
                        type="date"
                        onChange={(e) =>
                          setFieldValue("endDate", e.target.value)
                        }
                        value={values.endDate}
                        min={
                          values?.startDate ||
                          new Date().toISOString().split("T")[0]
                        } // Ensures endDate is after startDate
                        onKeyDown={(e: any) => e.preventDefault()} // Prevent manual typing
                        onPaste={(e: any) => e.preventDefault()} // Prevent pasting into the field
                      />
                    </div>
                    <div className="col-span-12">
                      <div className="flex items-center gap-4 mb-4">
                        <label
                          htmlFor="private"
                          className="border border-medium-grey bg-light-grey rounded-lg px-3 py-1.5 flex items-center gap-2 has-[:checked]:bg-site-yellow cursor-pointer"
                        >
                          {/* <input
                            type="radio"
                            name="event-privacy"
                            id="private"
                            className="sr-only"
                          /> */}
                          <RadioButton
                            id="private"
                            name="isPrivate"
                            labelClass="!text-base"
                            onChange={() => {
                              setFieldValue("isPrivate", "private");
                            }}
                            checked={
                              values?.isPrivate == "private" ? true : false
                            }
                            className="sr-only"
                          >
                            ""
                          </RadioButton>
                          <img
                            src={IconLock}
                            alt="lock"
                            width={16}
                            height={16}
                          />
                          <Paragraph
                            text18
                            className="!font-semibold !leading-[12px]"
                          >
                            Private
                          </Paragraph>
                        </label>
                        <label
                          htmlFor="public"
                          className="border border-medium-grey bg-light-grey rounded-lg px-3 py-1.5 flex items-center gap-2 has-[:checked]:bg-site-yellow cursor-pointer"
                        >
                          {/* <input
                            type="radio"
                            name="event-privacy"
                            id="public"
                            className="sr-only"
                          /> */}
                          <RadioButton
                            id="public"
                            name="isPrivate"
                            labelClass="!text-base"
                            onChange={() => {
                              setFieldValue("isPrivate", "public");
                            }}
                            checked={
                              values?.isPrivate == "public" ? true : false
                            }
                            className="sr-only"
                          >
                            ""
                          </RadioButton>
                          <img
                            src={IconUnLock}
                            alt="lock"
                            width={16}
                            height={16}
                          />
                          <Paragraph
                            text18
                            className="!font-semibold !leading-[12px]"
                          >
                            Public
                          </Paragraph>
                        </label>
                      </div>
                      <Paragraph text16 className="!text-dark-grey">
                        {LanguageTranslation({
                          labelName: "subscriber_will_see_event",
                          languageCode: langId || "fr",
                        }) || "Only your subscribers will see your event"}
                      </Paragraph>
                    </div>
                    <div className="col-span-12">
                      <FormLabel>
                        {LanguageTranslation({
                          labelName: "description",
                          languageCode: langId || "fr",
                        }) || "Description"}
                      </FormLabel>
                      <TextArea
                        name="description"
                        rows={3}
                        placeholder="What do you want to share?"
                      />
                    </div>

                    <div className="xl:col-span-3 sm:col-span-6 col-span-12">
                      <FormLabel>Google url</FormLabel>
                      <InputType
                        name="google_url"
                        type="url"
                        placeholder="Enter"
                        value={generateCalendarUrl("google", values)}
                      />
                    </div>
                    <div className="xl:col-span-3 sm:col-span-6 col-span-12">
                      <FormLabel>iCal url</FormLabel>
                      <InputType
                        name="iCal_url"
                        type="url"
                        placeholder="Enter"
                        value={generateCalendarUrl("ics", values)}
                      />
                    </div>
                    <div className="xl:col-span-3 sm:col-span-6 col-span-12">
                      <FormLabel>Yahoo url</FormLabel>
                      <InputType
                        name="yahoo_url"
                        type="url"
                        placeholder="Enter"
                        value={generateCalendarUrl("yahoo", values)}
                      />
                    </div>
                    <div className="xl:col-span-3 sm:col-span-6 col-span-12">
                      <FormLabel>Outlook url</FormLabel>
                      <InputType
                        name="outlook_url"
                        type="url"
                        placeholder="Enter"
                        value={generateCalendarUrl("outlook", values)}
                      />
                    </div>
                    <div className="col-span-12">
                      <div className="flex items-center gap-[30px]">
                        <Paragraph text16>Entrance fee</Paragraph>
                        <SwitchToggle
                          onChange={() =>
                            setFieldValue("entranceFee", !values.entranceFee)
                          }
                          isChecked={values?.entranceFee}
                        />
                      </div>
                    </div>
                    {values?.entranceFee && (
                      <>
                        <div className="lg:col-span-6  sm:col-span-6 col-span-12">
                          <FormLabel>Tracking url</FormLabel>
                          <InputType
                            name="trackingUrl"
                            type="text"
                            placeholder="Enter"
                            value={values?.trackingUrl}
                          />
                        </div>
                        <div className="lg:col-span-6  sm:col-span-6 col-span-12">
                          <FormLabel>Price</FormLabel>
                          <InputType
                            name="price"
                            type="number"
                            placeholder="EUROS"
                            value={values?.price}
                            // onChange={(e) => {
                            //   const value = e.target.value;
                            //   if (value < String(0)) {
                            //     console.log("fdsfdfsdfs", value);

                            //     setErrors({
                            //       price: "Price cannot be negative",
                            //     });
                            //   } else {
                            //     setErrors({ price: "" }); // Clear error if valid
                            //     setFieldValue("price", value);
                            //   }
                            // }}
                            showError={false}
                          />
                          {errors?.price && (
                            <div className="text-site-red text-sm font-medium">
                              {errors?.price}
                            </div>
                          )}
                        </div>
                      </>
                    )}

                    <div className="col-span-12 mt-1.5">
                      <div className="flex items-center xl:justify-end justify-start gap-4">
                        <Button borderButton className="lg:w-auto w-full">
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          className="lg:w-auto w-full"
                          primary
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default CreateEvent;
