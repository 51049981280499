import React, { useState } from "react";
import { SocialInterface } from "../../interface/social";
import { IoMdPlay } from "react-icons/io";
import PostCarousel from "./PostCarousel";
import PostOnlyText from "./PostOnlyText";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Video from "yet-another-react-lightbox/plugins/video";

interface PostImageProps {
  itemData: SocialInterface; // Adjust type as per your data
  feedData: any;
}

const PostFeedImage: React.FC<PostImageProps> = ({ itemData, feedData }) => {
  const [videoUrl, setVideoUrl] = useState<string | undefined>(undefined);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const openLightbox = (videoUrl: string) => {
    setVideoUrl(videoUrl);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
    setVideoUrl(undefined);
  };

  const renderMedia = (item: SocialInterface): React.ReactElement | null => {
    if (item?.video || (item?.images && item?.images?.length > 0)) {
      if (item.video) {
        return (
          <div className="block rounded-[20px] overflow-hidden relative w-auto">
            <video
              id={`image-${item.id}`}
              className="h-auto rounded-[20px] bg-light-grey w-full"
              src={item.video.videoUrl}
            ></video>

            <button
              aria-label="Play video"
              className="w-10 h-10 rounded-full absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] flex items-center justify-center bg-gradient-to-r from-site-black to-site-black/0 backdrop-blur-md"
            >
              <IoMdPlay
                color="#fff"
                size={22}
                onClick={() => openLightbox(item?.video?.videoUrl)}
              />
            </button>
          </div>
        );
      }

      if (item.images && item.images.length > 0) {
        return item.images.length > 1 ? (
          <PostCarousel itemImg={item?.images} feedData={feedData} />
        ) : (
          <div className="block rounded-[20px] overflow-hidden relative w-auto">
            <img
              src={item.images[0]?.imageUrl}
              alt="Single feed image"
              className="w-full h-auto rounded-[20px] bg-light-grey cursor-pointer"
              loading="lazy"
            />
          </div>
        );
      }
    } else {
      return <PostOnlyText description={item?.text?.contentText} />;
    }

    // Default return if no video or images
    return null;
  };

  return (
    <>
      {renderMedia(itemData)}
      {lightboxOpen && (
        <div className={feedData?.length === 1 ? "lightbox-no-navigation" : ""}>
          <Lightbox
            open={lightboxOpen}
            close={closeLightbox}
            slides={[
              {
                type: "video",
                sources: [{ src: videoUrl || "", type: "video/mp4" }],
              },
            ]}
            plugins={[Video]}
            carousel={{
              finite: true,
              padding: 0,
            }}
            video={{
              autoPlay: true,
              controls: true,
              playsInline: true,
              loop: false,
            }}
          />
        </div>
      )}
    </>
  );
};

export default PostFeedImage;
