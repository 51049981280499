import * as Yup from "yup";

export const contactusValidationSchema = Yup.object({
  title: Yup.string()
    .trim()
    .max(50, "Maximum 50 characters allowed")
    .min(1, "Minimum 1 character required")
    .required("Title is required"),
  description: Yup.string()
    .trim()
    .min(1, "Minimum 1 character required")
    .required("Description is required"),
});
